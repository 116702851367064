import { DASHBOARD_FETCH, DASHBOARD_FETCHING } from "./actions"

const initialState = {
  data: [],
  loading: false
}

export function dashboard(state = initialState, action = {}) {
  switch (action.type) {
    case DASHBOARD_FETCH:
      return Object.assign({}, state, {
        data: action.data,
        loading: false
      })
    case DASHBOARD_FETCHING:
      return Object.assign({}, state, {
        data: [],
        loading: true
      })
    default:
      return state
  }
}
