import {
    VIDEOS_REAL_ESTAT_FETCH,
    VIDEOS_REAL_ESTAT_CLEAR,
    NEW_VIDEOS_REAL_ESTAT,
    VIDEOS_REAL_ESTATE_FETCH,
    VIDEOS_REAL_ESTATE_FETCHING,
    VIDEOS_REAL_ESTAT_SUBMITTING,
    VIDEOS_REAL_ESTAT_SUBMIT,
    VIDEOS_REAL_ESTAT_SUBMIT_ERROR
  } from "./actions"

  const initialState = {
    data: [],
    loading: false
  }
  export function Videos_real_estat(state = initialState, action = {}) {
    switch (action.type) {
      case VIDEOS_REAL_ESTAT_FETCH:
        return Object.assign({}, state, {
          data: action.data
        })
      case NEW_VIDEOS_REAL_ESTAT:
        return Object.assign({}, state, {
          data: {
            id: null
          }
        })
      case VIDEOS_REAL_ESTAT_CLEAR:
        return Object.assign({}, state, initialState)
      default:
        return state
    }
  }

  export function Videos_real_estate(state = initialState, action = {}) {
    switch (action.type) {
      case VIDEOS_REAL_ESTATE_FETCH:
        return Object.assign({}, state, {
          data: action.data,
          loading: false
        })
      case VIDEOS_REAL_ESTATE_FETCHING:
        return Object.assign({}, state, {
          data: [],
          loading: true
        })
      default:
        return state
    }
  }

  const initialStateReg = {
    data: [],
    error: []
  }
  export function Videos_real_estateReg(state = initialStateReg, action = {}) {
    switch (action.type) {
      case VIDEOS_REAL_ESTAT_SUBMITTING:
        return Object.assign({}, state, initialStateReg)
      case VIDEOS_REAL_ESTAT_SUBMIT:
        return Object.assign({}, state, {
          data: action.data,
          error: []
        })
      case VIDEOS_REAL_ESTAT_SUBMIT_ERROR:
        return Object.assign({}, state, {
          data: [],
          error: action.error
        })
      default:
        return state
    }
  }
