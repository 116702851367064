import {
  DISTRICT_FETCH,
  DISTRICT_CLEAR,
  NEW_DISTRICT,
  DISTRICTS_FETCH,
  DISTRICTS_FETCHING,
  DISTRICT_SUBMITTING,
  DISTRICT_SUBMIT,
  DISTRICT_SUBMIT_ERROR
} from "./actions"

const initialState = {
  data: [],
  loading: false
}
export function District(state = initialState, action = {}) {
  switch (action.type) {
    case DISTRICT_FETCH:
      return Object.assign({}, state, {
        data: action.data
      })
    case NEW_DISTRICT:
      return Object.assign({}, state, {
        data: {
          id: null
        }
      })
    case DISTRICT_CLEAR:
      return Object.assign({}, state, initialState)
    default:
      return state
  }
}

export function Districts(state = initialState, action = {}) {
  switch (action.type) {
    case DISTRICTS_FETCH:
      return Object.assign({}, state, {
        data: action.data,
        loading: false
      })
    case DISTRICTS_FETCHING:
      return Object.assign({}, state, {
        data: [],
        loading: true
      })
    default:
      return state
  }
}

const initialStateReg = {
  data: [],
  error: []
}
export function DistrictsReg(state = initialStateReg, action = {}) {
  switch (action.type) {
    case DISTRICT_SUBMITTING:
      return Object.assign({}, state, initialStateReg)
    case DISTRICT_SUBMIT:
      return Object.assign({}, state, {
        data: action.data,
        error: []
      })
    case DISTRICT_SUBMIT_ERROR:
      return Object.assign({}, state, {
        data: [],
        error: action.error
      })
    default:
      return state
  }
}
