import React from "react"
import PropTypes from "prop-types"
import NumberFormat from "react-number-format"

const NumberFormatInput = props => {
  const { inputRef, onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      thousandSeparator="."
      decimalScale={2}
      fixedDecimalScale={true}
      decimalSeparator=","
      isNumericString
      prefix="R$ "
      onValueChange={values => {
        onChange({
          target: {
            value: values.value
          }
        })
      }}
    />
  )
}

NumberFormatInput.propTypes = {
  inputRef: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired
}

export default NumberFormatInput
