import axios from "axios"
import { API_URL, AUTH_TOKEN, LOGIN } from "../../../setup/store"
import { history } from "../../../index"

// select
// import { s } from "../..//api/actions"

export const OPERACAO_FETCH = "OPERACAO_FETCH"
export const OPERACAO_FETCHING = "OPERACAO_FETCHING"

export const NEW_OPERACA = "NEW_OPERACA"
export const OPERACA_FETCH = "OPERACA_FETCH"
export const OPERACA_CLEAR = "OPERACA_CLEAR"

export const OPERACA_SUBMITTING = "OPERACA_SUBMITTING"
export const OPERACA_SUBMIT = "OPERACA_SUBMIT"
export const OPERACA_SUBMIT_ERROR = "OPERACA_SUBMIT_ERROR"

const URL_REDIRECT = "/admin/operacao"
const API_TYPE = "operacao"

export const Operacao = () => {
  return async (dispatch) => {
    dispatch({
      type: OPERACAO_FETCHING,
    })
    try {
      const res = await axios.get(`${API_URL}${API_TYPE}`, {
        headers: {
          Authorization: AUTH_TOKEN,
        },
      })

      dispatch({
        type: OPERACAO_FETCH,
        data: res.data,
      })
    } catch (error) {
      window.location.href = LOGIN
      console.error(error)
    }
  }
}

export const Operaca = (id) => {
  return async (dispatch) => {
    // select
    //dispatch(())

    if (id) {
      try {
        const res = await axios.get(`${API_URL}${API_TYPE}/${id}`, {
          headers: {
            Authorization: AUTH_TOKEN,
          },
        })
        dispatch({
          type: OPERACA_FETCH,
          data: res.data,
        })
      } catch (error) {
        window.location.href = LOGIN
        console.log(error.message)
      }
    } else {
      dispatch({
        type: NEW_OPERACA,
      })
    }
  }
}

export const OperacaoCleanSubmit = () => {
  return async (dispatch) => {
    dispatch({
      type: OPERACA_SUBMITTING,
    })
  }
}

export const OperacaoCleanForm = () => {
  return async (dispatch) => {
    dispatch({
      type: OPERACA_CLEAR,
    })
  }
}

export const OperacaoDelete = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: OPERACA_SUBMITTING,
    })
    const ID = getState().Operaca.data.id
    try {
      const res = await axios.delete(`${API_URL}${API_TYPE}/${ID}`, {
        headers: {
          Authorization: AUTH_TOKEN,
        },
      })
      dispatch({
        type: OPERACA_SUBMIT,
        data: res.data,
      })
      history.push(URL_REDIRECT)
    } catch (error) {
      console.log(error.message)
    }
  }
}

export const OperacaoSubmit = (operacao) => {
  return async (dispatch) => {
    dispatch({
      type: OPERACA_SUBMITTING,
    })
    try {
      let res

      if (operacao.id) {
        res = await axios.put(
          `${API_URL}${API_TYPE}/${operacao.id}`,
          operacao,
          {
            headers: {
              Authorization: AUTH_TOKEN,
            },
          }
        )
      } else {
        res = await axios.post(`${API_URL}${API_TYPE}/`, operacao, {
          headers: {
            Authorization: AUTH_TOKEN,
          },
        })
      }

      dispatch({
        type: OPERACA_SUBMIT,
        data: res.data,
      })
      history.push(URL_REDIRECT)
    } catch (error) {
      dispatch({
        type: OPERACA_SUBMIT_ERROR,
        error: "Este nome já foi cadastrado, Por favor escolha um diferente...",
      })
    }
  }
}
