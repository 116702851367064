import axios from "axios"
import { API_URL, AUTH_TOKEN, LOGIN } from "../../../setup/store"
import { history } from "../../../index"

// select
import { Citys } from "../../Citys/api/actions"

export const DISTRICTS_FETCH = "DISTRICTS_FETCH"
export const DISTRICTS_FETCHING = "DISTRICTS_FETCHING"

export const NEW_DISTRICT = "NEW_DISTRICT"
export const DISTRICT_FETCH = "DISTRICT_FETCH"
export const DISTRICT_CLEAR = "DISTRICT_CLEAR"

export const DISTRICT_SUBMITTING = "DISTRICT_SUBMITTING"
export const DISTRICT_SUBMIT = "DISTRICT_SUBMIT"
export const DISTRICT_SUBMIT_ERROR = "DISTRICT_SUBMIT_ERROR"

const URL_REDIRECT = "/admin/districts"
const API_TYPE = "districts"

export const Districts = () => {
  return async dispatch => {
    dispatch({
      type: DISTRICTS_FETCHING
    })
    try {
      const res = await axios.get(`${API_URL}${API_TYPE}`, {
        headers: {
          Authorization: AUTH_TOKEN
        }
      })

      dispatch({
        type: DISTRICTS_FETCH,
        data: res.data
      })
    } catch (error) {
      window.location.href = LOGIN
      console.error(error)
    }
  }
}

export const District = id => {
  return async dispatch => {
    // select
    dispatch(Citys())

    if (id) {
      try {
        const res = await axios.get(`${API_URL}${API_TYPE}/${id}`, {
          headers: {
            Authorization: AUTH_TOKEN
          }
        })
        dispatch({
          type: DISTRICT_FETCH,
          data: res.data
        })
      } catch (error) {
        window.location.href = LOGIN
        console.log(error.message)
      }
    } else {
      dispatch({
        type: NEW_DISTRICT
      })
    }
  }
}

export const DistrictsCleanSubmit = () => {
  return async dispatch => {
    dispatch({
      type: DISTRICT_SUBMITTING
    })
  }
}

export const DistrictsCleanForm = () => {
  return async dispatch => {
    dispatch({
      type: DISTRICT_CLEAR
    })
  }
}

export const DistrictsDelete = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: DISTRICT_SUBMITTING
    })
    const ID = getState().District.data.id
    try {
      const res = await axios.delete(`${API_URL}${API_TYPE}/${ID}`, {
        headers: {
          Authorization: AUTH_TOKEN
        }
      })
      dispatch({
        type: DISTRICT_SUBMIT,
        data: res.data
      })
      history.push(URL_REDIRECT)
    } catch (error) {
      console.log(error.message)
    }
  }
}

export const DistrictsSubmit = districts => {
  return async dispatch => {
    dispatch({
      type: DISTRICT_SUBMITTING
    })
    try {
      let res

      if (districts.id) {
        res = await axios.put(
          `${API_URL}${API_TYPE}/${districts.id}`,
          districts,
          {
            headers: {
              Authorization: AUTH_TOKEN
            }
          }
        )
      } else {
        res = await axios.post(`${API_URL}${API_TYPE}/`, districts, {
          headers: {
            Authorization: AUTH_TOKEN
          }
        })
      }

      dispatch({
        type: DISTRICT_SUBMIT,
        data: res.data
      })
      history.push(URL_REDIRECT)
    } catch (error) {
      dispatch({
        type: DISTRICT_SUBMIT_ERROR,
        error: "Este nome já foi cadastrado, Por favor escolha um diferente..."
      })
    }
  }
}
