import axios from "axios"
import { API_URL, AUTH_TOKEN, LOGIN } from "../../../setup/store"
import { history } from "../../../index"

export const VIDEOS_REAL_ESTATE_FETCH = "VIDEOS_REAL_ESTATE_FETCH"
export const VIDEOS_REAL_ESTATE_FETCHING = "VIDEOS_REAL_ESTATE_FETCHING"

export const NEW_VIDEOS_REAL_ESTAT = "NEW_VIDEOS_REAL_ESTAT"
export const VIDEOS_REAL_ESTAT_FETCH = "VIDEOS_REAL_ESTAT_FETCH"
export const VIDEOS_REAL_ESTAT_CLEAR = "VIDEOS_REAL_ESTAT_CLEAR"

export const VIDEOS_REAL_ESTAT_SUBMITTING = "VIDEOS_REAL_ESTAT_SUBMITTING"
export const VIDEOS_REAL_ESTAT_SUBMIT = "VIDEOS_REAL_ESTAT_SUBMIT"
export const VIDEOS_REAL_ESTAT_SUBMIT_ERROR = "VIDEOS_REAL_ESTAT_SUBMIT_ERROR"

const URL_REDIRECT = "/admin/real_estates-register/"
const API_TYPE = "videos_real_estate"

export const Videos_real_estate = id => {
  return async dispatch => {
    dispatch({
      type: VIDEOS_REAL_ESTATE_FETCHING
    })
    try {
      const res = await axios.get(`${API_URL}${API_TYPE}_sub/${id}`, {
        headers: {
          Authorization: AUTH_TOKEN
        }
      })

      dispatch({
        type: VIDEOS_REAL_ESTATE_FETCH,
        data: res.data
      })
    } catch (error) {
      console.error(error)
      window.location.href = LOGIN
    }
  }
}

export const Videos_real_estat = id => {
  return async dispatch => {
    if (id) {
      try {
        const res = await axios.get(`${API_URL}${API_TYPE}/${id}`, {
          headers: {
            Authorization: AUTH_TOKEN
          }
        })
        dispatch({
          type: VIDEOS_REAL_ESTAT_FETCH,
          data: res.data
        })
      } catch (error) {
        window.location.href = LOGIN
        console.log(error.message)
      }
    } else {
      dispatch({
        type: NEW_VIDEOS_REAL_ESTAT
      })
    }
  }
}

export const Videos_real_estateCleanSubmit = () => {
  return async dispatch => {
    dispatch({
      type: VIDEOS_REAL_ESTAT_SUBMITTING
    })
  }
}

export const Videos_real_estateCleanForm = () => {
  return async dispatch => {
    dispatch({
      type: VIDEOS_REAL_ESTAT_CLEAR
    })
  }
}

export const Videos_real_estateDelete = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: VIDEOS_REAL_ESTAT_SUBMITTING
    })
    const reg = getState().Videos_real_estat.data
    try {
      const res = await axios.delete(`${API_URL}${API_TYPE}/${reg.id}`, {
        headers: {
          Authorization: AUTH_TOKEN
        }
      })
      dispatch({
        type: VIDEOS_REAL_ESTAT_SUBMIT,
        data: res.data
      })
      history.push(URL_REDIRECT + reg.real_estate_id)
    } catch (error) {
      window.location.href = LOGIN
      console.log(error.message)
    }
  }
}

export const Videos_real_estateSubmit = videos_real_estate => {
  return async dispatch => {
    dispatch({
      type: VIDEOS_REAL_ESTAT_SUBMITTING
    })
    try {
      let res

      if (videos_real_estate.id) {
        res = await axios.put(
          `${API_URL}${API_TYPE}/${videos_real_estate.id}`,
          videos_real_estate,
          {
            headers: {
              Authorization: AUTH_TOKEN
            }
          }
        )
      } else {
        res = await axios.post(`${API_URL}${API_TYPE}/`, videos_real_estate, {
          headers: {
            Authorization: AUTH_TOKEN
          }
        })
      }

      dispatch({
        type: VIDEOS_REAL_ESTAT_SUBMIT,
        data: res.data
      })
      history.push(URL_REDIRECT + videos_real_estate.real_estate_id)
    } catch (error) {
      dispatch({
        type: VIDEOS_REAL_ESTAT_SUBMIT_ERROR,
        error: "Este nome já foi cadastrado, Por favor escolha um diferente..."
      })
    }
  }
}
