import axios from "axios"
import { API_URL, AUTH_TOKEN, LOGIN } from "../../../setup/store"
import { history } from "../../../index"
import { Real_estates } from "../../Real_estates/api/actions"

export const RELATED_REAL_ESTATE_FETCH = "RELATED_REAL_ESTATE_FETCH"
export const RELATED_REAL_ESTATE_FETCHING = "RELATED_REAL_ESTATE_FETCHING"

export const NEW_RELATED_REAL_ESTAT = "NEW_RELATED_REAL_ESTAT"
export const RELATED_REAL_ESTAT_FETCH = "RELATED_REAL_ESTAT_FETCH"
export const RELATED_REAL_ESTAT_CLEAR = "RELATED_REAL_ESTAT_CLEAR"

export const RELATED_REAL_ESTAT_SUBMITTING = "RELATED_REAL_ESTAT_SUBMITTING"
export const RELATED_REAL_ESTAT_SUBMIT = "RELATED_REAL_ESTAT_SUBMIT"
export const RELATED_REAL_ESTAT_SUBMIT_ERROR = "RELATED_REAL_ESTAT_SUBMIT_ERROR"

const URL_REDIRECT = "/admin/real_estates-register/"
const API_TYPE = "related_real_estate"

export const Related_real_estate = id => {
  return async dispatch => {
    dispatch({
      type: RELATED_REAL_ESTATE_FETCHING
    })
    try {
      const res = await axios.get(`${API_URL}${API_TYPE}_sub/${id}`, {
        headers: {
          Authorization: AUTH_TOKEN
        }
      })

      dispatch({
        type: RELATED_REAL_ESTATE_FETCH,
        data: res.data
      })
    } catch (error) {
      console.error(error)
     // window.location.href = LOGIN
    }
  }
}

export const Related_real_estat = id => {
  return async dispatch => {
    dispatch(Real_estates())
    if (id) {
      try {
        const res = await axios.get(`${API_URL}${API_TYPE}/${id}`, {
          headers: {
            Authorization: AUTH_TOKEN
          }
        })
        dispatch({
          type: RELATED_REAL_ESTAT_FETCH,
          data: res.data
        })
      } catch (error) {
        window.location.href = LOGIN
        console.log(error.message)
      }
    } else {
      dispatch({
        type: NEW_RELATED_REAL_ESTAT
      })
    }
  }
}

export const Related_real_estateCleanSubmit = () => {
  return async dispatch => {
    dispatch({
      type: RELATED_REAL_ESTAT_SUBMITTING
    })
  }
}

export const Related_real_estateCleanForm = () => {
  return async dispatch => {
    dispatch({
      type: RELATED_REAL_ESTAT_CLEAR
    })
  }
}

export const Related_real_estateDelete = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: RELATED_REAL_ESTAT_SUBMITTING
    })
    const reg = getState().Related_real_estat.data
    try {
      const res = await axios.delete(`${API_URL}${API_TYPE}/${reg.id}`, {
        headers: {
          Authorization: AUTH_TOKEN
        }
      })
      dispatch({
        type: RELATED_REAL_ESTAT_SUBMIT,
        data: res.data
      })
      history.push(URL_REDIRECT + reg.real_estate_id)
    } catch (error) {
      window.location.href = LOGIN
      console.log(error.message)
    }
  }
}

export const Related_real_estateSubmit = related_real_estate => {
  return async dispatch => {
    dispatch({
      type: RELATED_REAL_ESTAT_SUBMITTING
    })
    try {
      let res

      if (related_real_estate.id) {
        res = await axios.put(
          `${API_URL}${API_TYPE}/${related_real_estate.id}`,
          related_real_estate,
          {
            headers: {
              Authorization: AUTH_TOKEN
            }
          }
        )
      } else {
        res = await axios.post(`${API_URL}${API_TYPE}/`, related_real_estate, {
          headers: {
            Authorization: AUTH_TOKEN
          }
        })
      }

      dispatch({
        type: RELATED_REAL_ESTAT_SUBMIT,
        data: res.data
      })
      history.push(URL_REDIRECT + related_real_estate.real_estate_id)
    } catch (error) {
      dispatch({
        type: RELATED_REAL_ESTAT_SUBMIT_ERROR,
        error: "Este nome já foi cadastrado, Por favor escolha um diferente..."
      })
    }
  }
}
