import axios from "axios"
import { API_URL, AUTH_TOKEN, LOGIN } from "../../../setup/store"
import { history } from "../../../index"

// select
// import { s } from "../..//api/actions"

export const IMAGE_TYPES_FETCH = "IMAGE_TYPES_FETCH"
export const IMAGE_TYPES_FETCHING = "IMAGE_TYPES_FETCHING"

export const NEW_IMAGE_TYPE = "NEW_IMAGE_TYPE"
export const IMAGE_TYPE_FETCH = "IMAGE_TYPE_FETCH"
export const IMAGE_TYPE_CLEAR = "IMAGE_TYPE_CLEAR"

export const IMAGE_TYPE_SUBMITTING = "IMAGE_TYPE_SUBMITTING"
export const IMAGE_TYPE_SUBMIT = "IMAGE_TYPE_SUBMIT"
export const IMAGE_TYPE_SUBMIT_ERROR = "IMAGE_TYPE_SUBMIT_ERROR"

const URL_REDIRECT = "/admin/image_types"
const API_TYPE = "image_types"

export const Image_types = () => {
  return async dispatch => {
    dispatch({
      type: IMAGE_TYPES_FETCHING
    })
    try {
      const res = await axios.get(`${API_URL}${API_TYPE}`, {
        headers: {
          Authorization: AUTH_TOKEN
        }
      })

      dispatch({
        type: IMAGE_TYPES_FETCH,
        data: res.data
      })
    } catch (error) {
      window.location.href = LOGIN
      console.error(error)
    }
  }
}

export const Image_type = id => {
  return async dispatch => {
    // select
    //dispatch(())

    if (id) {
      try {
        const res = await axios.get(`${API_URL}${API_TYPE}/${id}`, {
          headers: {
            Authorization: AUTH_TOKEN
          }
        })
        dispatch({
          type: IMAGE_TYPE_FETCH,
          data: res.data
        })
      } catch (error) {
        window.location.href = LOGIN
        console.log(error.message)
      }
    } else {
      dispatch({
        type: NEW_IMAGE_TYPE
      })
    }
  }
}

export const Image_typesCleanSubmit = () => {
  return async dispatch => {
    dispatch({
      type: IMAGE_TYPE_SUBMITTING
    })
  }
}

export const Image_typesCleanForm = () => {
  return async dispatch => {
    dispatch({
      type: IMAGE_TYPE_CLEAR
    })
  }
}

export const Image_typesDelete = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: IMAGE_TYPE_SUBMITTING
    })
    const ID = getState().Image_type.data.id
    try {
      const res = await axios.delete(`${API_URL}${API_TYPE}/${ID}`, {
        headers: {
          Authorization: AUTH_TOKEN
        }
      })
      dispatch({
        type: IMAGE_TYPE_SUBMIT,
        data: res.data
      })
      history.push(URL_REDIRECT)
    } catch (error) {
      console.log(error.message)
    }
  }
}

export const Image_typesSubmit = image_types => {
  return async dispatch => {
    dispatch({
      type: IMAGE_TYPE_SUBMITTING
    })
    try {
      let res

      if (image_types.id) {
        res = await axios.put(
          `${API_URL}${API_TYPE}/${image_types.id}`,
          image_types,
          {
            headers: {
              Authorization: AUTH_TOKEN
            }
          }
        )
      } else {
        res = await axios.post(`${API_URL}${API_TYPE}/`, image_types, {
          headers: {
            Authorization: AUTH_TOKEN
          }
        })
      }

      dispatch({
        type: IMAGE_TYPE_SUBMIT,
        data: res.data
      })
      history.push(URL_REDIRECT)
    } catch (error) {
      dispatch({
        type: IMAGE_TYPE_SUBMIT_ERROR,
        error: "Este nome já foi cadastrado, Por favor escolha um diferente..."
      })
    }
  }
}
