import axios from "axios"
import { API_URL, AUTH_TOKEN, LOGIN } from "../../../setup/store"
import { history } from "../../../index"

// select
// import { s } from "../..//api/actions"

export const TYPE_USER_FETCH = "TYPE_USER_FETCH"
export const TYPE_USER_FETCHING = "TYPE_USER_FETCHING"

export const NEW_TYPE_USE = "NEW_TYPE_USE"
export const TYPE_USE_FETCH = "TYPE_USE_FETCH"
export const TYPE_USE_CLEAR = "TYPE_USE_CLEAR"

export const TYPE_USE_SUBMITTING = "TYPE_USE_SUBMITTING"
export const TYPE_USE_SUBMIT = "TYPE_USE_SUBMIT"
export const TYPE_USE_SUBMIT_ERROR = "TYPE_USE_SUBMIT_ERROR"

const URL_REDIRECT = "/admin/type_user"
const API_TYPE = "type_user"

export const Type_user = () => {
  return async dispatch => {
    dispatch({
      type: TYPE_USER_FETCHING
    })
    try {
      const res = await axios.get(`${API_URL}${API_TYPE}`, {
        headers: {
          Authorization: AUTH_TOKEN
        }
      })

      dispatch({
        type: TYPE_USER_FETCH,
        data: res.data
      })
    } catch (error) {
      window.location.href = LOGIN
      console.error(error)
    }
  }
}

export const Type_use = id => {
  return async dispatch => {
    // select
    //dispatch(())

    if (id) {
      try {
        const res = await axios.get(`${API_URL}${API_TYPE}/${id}`, {
          headers: {
            Authorization: AUTH_TOKEN
          }
        })
        dispatch({
          type: TYPE_USE_FETCH,
          data: res.data
        })
      } catch (error) {
        window.location.href = LOGIN
        console.log(error.message)
      }
    } else {
      dispatch({
        type: NEW_TYPE_USE
      })
    }
  }
}

export const Type_userCleanSubmit = () => {
  return async dispatch => {
    dispatch({
      type: TYPE_USE_SUBMITTING
    })
  }
}

export const Type_userCleanForm = () => {
  return async dispatch => {
    dispatch({
      type: TYPE_USE_CLEAR
    })
  }
}

export const Type_userDelete = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: TYPE_USE_SUBMITTING
    })
    const ID = getState().Type_use.data.id
    try {
      const res = await axios.delete(`${API_URL}${API_TYPE}/${ID}`, {
        headers: {
          Authorization: AUTH_TOKEN
        }
      })
      dispatch({
        type: TYPE_USE_SUBMIT,
        data: res.data
      })
      history.push(URL_REDIRECT)
    } catch (error) {
      console.log(error.message)
    }
  }
}

export const Type_userSubmit = type_user => {
  return async dispatch => {
    dispatch({
      type: TYPE_USE_SUBMITTING
    })
    try {
      let res

      if (type_user.id) {
        res = await axios.put(
          `${API_URL}${API_TYPE}/${type_user.id}`,
          type_user,
          {
            headers: {
              Authorization: AUTH_TOKEN
            }
          }
        )
      } else {
        res = await axios.post(`${API_URL}${API_TYPE}/`, type_user, {
          headers: {
            Authorization: AUTH_TOKEN
          }
        })
      }

      dispatch({
        type: TYPE_USE_SUBMIT,
        data: res.data
      })
      history.push(URL_REDIRECT)
    } catch (error) {
      dispatch({
        type: TYPE_USE_SUBMIT_ERROR,
        error: "Este nome já foi cadastrado, Por favor escolha um diferente..."
      })
    }
  }
}
