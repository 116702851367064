import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"
import {
  Status_LeadsCleanSubmit,
  Status_LeadsCleanForm,
  Status_Lead
} from "./api/actions"

import Form from "./Form"

const Status_LeadsForm = props => {
  const dispatch = useDispatch()
  const Status_LeadsSt = useSelector(state => state.Status_Lead)

  useEffect(() => {
    dispatch(Status_LeadsCleanSubmit())
    dispatch(Status_Lead(props.match.params.id))

    return () => dispatch(Status_LeadsCleanForm())
  }, [dispatch, props.match.params.id])

  return (
    <React.Fragment>
      {Status_LeadsSt.data.length === 0 ? (
        ""
      ) : (
        <Form data={Status_LeadsSt.data} />
      )}
    </React.Fragment>
  )
}

Status_LeadsForm.propTypes = {
  match: PropTypes.object
}

export default Status_LeadsForm
