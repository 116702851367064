import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"
import {
  DistrictsCleanSubmit,
  DistrictsCleanForm,
  District
} from "./api/actions"

import Form from "./Form"

const DistrictsForm = props => {
  const dispatch = useDispatch()
  const DistrictsSt = useSelector(state => state.District)

  useEffect(() => {
    dispatch(DistrictsCleanSubmit())
    dispatch(District(props.match.params.id))

    return () => dispatch(DistrictsCleanForm())
  }, [dispatch, props.match.params.id])

  return (
    <React.Fragment>
      {DistrictsSt.data.length === 0 ? "" : <Form data={DistrictsSt.data} />}
    </React.Fragment>
  )
}

DistrictsForm.propTypes = {
  match: PropTypes.object
}

export default DistrictsForm
