import ClientesForm from "views/Clientes/ClientesForm"
import ClientesList from "views/Clientes/ClientesList"

import DashboardPage from "views/Dashboard/Dashboard.js"

const TypeRoutes = [
  {
    path: "/dashboard",
    name: "Início",
    icon: "dashboard",
    component: DashboardPage,
    layout: "/admin",
    children: []
  },
  {
    path: "/clientes",
    name: "Clientes",
    icon: "person",
    component: ClientesList,
    layout: "/admin",
    children: [
      {
        path: "/clientes",
        name: "Clientes",
        icon: "person",
        component: ClientesList,
        layout: "/admin"
      },
      {
        register: true,
        path: "/clientes-register/:id?",
        name: "Clientes",
        icon: "person",
        component: ClientesForm,
        layout: "/admin"
      }
    ]
  }
]

export default TypeRoutes
