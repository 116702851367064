import {
  TYPE_FETCH,
  TYPE_CLEAR,
  NEW_TYPE,
  TYPES_FETCH,
  TYPES_FETCHING,
  TYPE_SUBMITTING,
  TYPE_SUBMIT,
  TYPE_SUBMIT_ERROR
} from "./actions"

const initialState = {
  data: [],
  loading: false
}
export function Type(state = initialState, action = {}) {
  switch (action.type) {
    case TYPE_FETCH:
      return Object.assign({}, state, {
        data: action.data
      })
    case NEW_TYPE:
      return Object.assign({}, state, {
        data: {
          id: null
        }
      })
    case TYPE_CLEAR:
      return Object.assign({}, state, initialState)
    default:
      return state
  }
}

export function Types(state = initialState, action = {}) {
  switch (action.type) {
    case TYPES_FETCH:
      return Object.assign({}, state, {
        data: action.data,
        loading: false
      })
    case TYPES_FETCHING:
      return Object.assign({}, state, {
        data: [],
        loading: true
      })
    default:
      return state
  }
}

const initialStateReg = {
  data: [],
  error: []
}
export function TypesReg(state = initialStateReg, action = {}) {
  switch (action.type) {
    case TYPE_SUBMITTING:
      return Object.assign({}, state, initialStateReg)
    case TYPE_SUBMIT:
      return Object.assign({}, state, {
        data: action.data,
        error: []
      })
    case TYPE_SUBMIT_ERROR:
      return Object.assign({}, state, {
        data: [],
        error: action.error
      })
    default:
      return state
  }
}
