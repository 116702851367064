import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"
import { CitysCleanSubmit, CitysCleanForm, City } from "./api/actions"

import Form from "./Form"

const CitysForm = props => {
  const dispatch = useDispatch()
  const CitysSt = useSelector(state => state.City)

  useEffect(() => {
    dispatch(CitysCleanSubmit())
    dispatch(City(props.match.params.id))

    return () => dispatch(CitysCleanForm())
  }, [dispatch, props.match.params.id])

  return (
    <React.Fragment>
      {CitysSt.data.length === 0 ? "" : <Form data={CitysSt.data} />}
    </React.Fragment>
  )
}

CitysForm.propTypes = {
  match: PropTypes.object
}

export default CitysForm
