import OperacaoForm from "views/Operacao/OperacaoForm"
import OperacaoList from "views/Operacao/OperacaoList"

import LeadsForm from "views/Leads/LeadsForm"
import LeadsList from "views/Leads/LeadsList"

import Status_LeadsForm from "views/Status_Leads/Status_LeadsForm"
import Status_LeadsList from "views/Status_Leads/Status_LeadsList"

import Type_userForm from "views/Type_user/Type_userForm"
import Type_userList from "views/Type_user/Type_userList"

import ClientesForm from "views/Clientes/ClientesForm"
import ClientesImp from "views/Clientes/ClientesImp"
import ClientesList from "views/Clientes/ClientesList"

import Videos_real_estateForm from "views/Videos_real_estate/Videos_real_estateForm"
import Videos_real_estateList from "views/Videos_real_estate/Videos_real_estateList"

import Related_real_estateForm from "views/Related_real_estate/Related_real_estateForm"
import Related_real_estateList from "views/Related_real_estate/Related_real_estateList"

import Img_real_estateForm from "views/Img_real_estate/Img_real_estateForm"
import Img_real_estateList from "views/Img_real_estate/Img_real_estateList"

import Real_estatesForm from "views/Real_estates/Real_estatesForm"
import Real_estatesList from "views/Real_estates/Real_estatesList"

import BrokersForm from "views/Brokers/BrokersForm"
import BrokersList from "views/Brokers/BrokersList"

import Image_typesForm from "views/Image_types/Image_typesForm"
import Image_typesList from "views/Image_types/Image_typesList"

import ServicesForm from "views/Services/ServicesForm"
import ServicesList from "views/Services/ServicesList"

import AttributesForm from "views/Attributes/AttributesForm"
import AttributesList from "views/Attributes/AttributesList"

import StatusForm from "views/Status/StatusForm"
import StatusList from "views/Status/StatusList"

import CitysForm from "views/Citys/CitysForm"
import CitysList from "views/Citys/CitysList"

import DistrictsForm from "views/Districts/DistrictsForm"
import DistrictsList from "views/Districts/DistrictsList"

import TypesForm from "views/Types/TypesForm"
import TypesList from "views/Types/TypesList"

import DashboardPage from "views/Dashboard/Dashboard.js"
import LoginPage from "views/Login/LoginPage.js"

import User from "views/User/User.js"
import UserList from "views/User/UserList.js"
import UserListType from "views/User/UserListType.js"

const dashboardRoutes = [
  {
    path: "/login",
    name: "Login Page",
    icon: "dashboard",
    component: LoginPage,
    layout: "/auth",
    children: [],
  },
  {
    path: "/dashboard",
    name: "Início",
    icon: "dashboard",
    component: DashboardPage,
    layout: "/admin",
    children: [],
  },
  {
    path: "/real_estates",
    name: "Imóveis",
    icon: "dashboard",
    component: Real_estatesList,
    layout: "/admin",
    children: [
      {
        path: "/real_estates",
        name: "Imóveis",
        icon: "person",
        component: Real_estatesList,
        layout: "/admin",
      },
      {
        register: true,
        path: "/real_estates-register/:id?",
        name: "Imóveis",
        icon: "person",
        component: Real_estatesForm,
        layout: "/admin",
      },
      {
        path: "/types",
        name: "Categorias",
        icon: "person",
        component: TypesList,
        layout: "/admin",
      },
      {
        register: true,
        path: "/types-register/:id?",
        name: "Categorias",
        icon: "person",
        component: TypesForm,
        layout: "/admin",
      },
      {
        path: "/brokers",
        name: "Corretores",
        icon: "person",
        component: BrokersList,
        layout: "/admin",
      },
      {
        register: true,
        path: "/brokers-register/:id?",
        name: "Corretores",
        icon: "person",
        component: BrokersForm,
        layout: "/admin",
      },
      {
        path: "/districts",
        name: "Bairros",
        icon: "person",
        component: DistrictsList,
        layout: "/admin",
      },
      {
        register: true,
        path: "/districts-register/:id?",
        name: "Bairros",
        icon: "person",
        component: DistrictsForm,
        layout: "/admin",
      },
      {
        path: "/citys",
        name: "Cidades",
        icon: "person",
        component: CitysList,
        layout: "/admin",
      },
      {
        register: true,
        path: "/citys-register/:id?",
        name: "Cidades",
        icon: "person",
        component: CitysForm,
        layout: "/admin",
      },

      {
        path: "/status",
        name: "Status",
        icon: "person",
        component: StatusList,
        layout: "/admin",
      },
      {
        register: true,
        path: "/status-register/:id?",
        name: "Status",
        icon: "person",
        component: StatusForm,
        layout: "/admin",
      },
      {
        path: "/operacao",
        name: "Finalidade",
        icon: "person",
        component: OperacaoList,
        layout: "/admin",
      },
      {
        register: true,
        path: "/operacao-register/:id?",
        name: "Finalidade",
        icon: "person",
        component: OperacaoForm,
        layout: "/admin",
      },
      {
        path: "/attributes",
        name: "Atributos",
        icon: "person",
        component: AttributesList,
        layout: "/admin",
      },
      {
        register: true,
        path: "/attributes-register/:id?",
        name: "Atributos",
        icon: "person",
        component: AttributesForm,
        layout: "/admin",
      },

      {
        path: "/services",
        name: "Serviços",
        icon: "person",
        component: ServicesList,
        layout: "/admin",
      },
      {
        register: true,
        path: "/services-register/:id?",
        name: "Serviços",
        icon: "person",
        component: ServicesForm,
        layout: "/admin",
      },

      {
        path: "/image_types",
        name: "Categ. Imagem",
        icon: "person",
        component: Image_typesList,
        layout: "/admin",
      },
      {
        register: true,
        path: "/image_types-register/:id?",
        name: "Categ. Imagem",
        icon: "person",
        component: Image_typesForm,
        layout: "/admin",
      },
      {
        register: true,
        path: "/img_real_estate",
        name: "Fotos",
        icon: "person",
        component: Img_real_estateList,
        layout: "/admin",
      },
      {
        register: true,
        path: "/img_real_estate-register/:top/:id?",
        name: "Fotos",
        icon: "person",
        component: Img_real_estateForm,
        layout: "/admin",
      },

      {
        register: true,
        path: "/related_real_estate",
        name: "Imóveis relacionados",
        icon: "person",
        component: Related_real_estateList,
        layout: "/admin",
      },
      {
        register: true,
        path: "/related_real_estate-register/:top/:id?",
        name: "Imóveis relacionados",
        icon: "person",
        component: Related_real_estateForm,
        layout: "/admin",
      },

      {
        register: true,
        path: "/videos_real_estate",
        name: "Vídeos",
        icon: "person",
        component: Videos_real_estateList,
        layout: "/admin",
      },
      {
        register: true,
        path: "/videos_real_estate-register/:top/:id?",
        name: "Vídeos",
        icon: "person",
        component: Videos_real_estateForm,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/leads",
    name: "Contatos",
    icon: "dashboard",
    component: LeadsList,
    layout: "/admin",
    children: [
      {
        path: "/status_leads",
        name: "Status Contatos",
        icon: "dashboard",
        component: Status_LeadsList,
        layout: "/admin",
      },
      {
        register: true,
        path: "/status_leads-register/:id?",
        name: "Status Contatos",
        icon: "dashboard",
        component: Status_LeadsForm,
        layout: "/admin",
      },
      {
        path: "/leads",
        name: "Contatos",
        icon: "person",
        component: LeadsList,
        layout: "/admin",
      },
      {
        register: true,
        path: "/leads-register/:id?",
        name: "Contatos",
        icon: "person",
        component: LeadsForm,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/clientes",
    name: "Clientes",
    icon: "person",
    component: ClientesList,
    layout: "/admin",
    children: [
      {
        path: "/clientes",
        name: "Clientes",
        icon: "person",
        component: ClientesList,
        layout: "/admin",
      },
      {
        register: true,
        path: "/clientes-register/:id?",
        name: "Clientes",
        icon: "person",
        component: ClientesForm,
        layout: "/admin",
      },
      {
        register: true,
        path: "/clientes-imp/:id?",
        name: "Clientes",
        icon: "person",
        component: ClientesImp,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/user-list",
    name: "Usuários",
    icon: "person",
    component: UserList,
    layout: "/admin",
    children: [
      {
        path: "/user-list-type/",
        name: "Consultores",
        icon: "dashboard",
        component: UserListType,
        layout: "/admin",
      },
      {
        path: "/user-list/",
        name: "Usuários",
        icon: "dashboard",
        component: UserList,
        layout: "/admin",
      },
      {
        name: "Usuários",
        path: "/user-register/:id?",
        register: true,
        component: User,
        layout: "/admin",
      },
      {
        path: "/type_user",
        name: "Tipos de usuários",
        icon: "person",
        component: Type_userList,
        layout: "/admin",
      },
      {
        register: true,
        path: "/type_user-register/:id?",
        name: "Tipos de usuários",
        icon: "person",
        component: Type_userForm,
        layout: "/admin",
      }
    ],
  },
]

export default dashboardRoutes
