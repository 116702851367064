import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import TextField from "@material-ui/core/TextField"
import PropTypes from "prop-types"

import FormControl from "@material-ui/core/FormControl"
import { API_BASE_URL } from "../../setup/store"

import { useSelector, useDispatch } from "react-redux"
// eslint-disable-next-line no-unused-vars
import { ServicesSubmit, ServicesDelete } from "./api/actions"

import CardHeader from "components/Card/CardHeader"
import CardBody from "components/Card/CardBody"
import CardFooter from "components/Card/CardFooter"
import SnackbarContent from "components/Snackbar/SnackbarContent"
import Button from "components/CustomButtons/Button"
import GridItem from "components/Grid/GridItem"
import GridContainer from "components/Grid/GridContainer"
import Card from "components/Card/Card"

import { makeStyles } from "@material-ui/core/styles"
const styles = {
  formControl: {
    marginTop: "15px",
    minWidth: "100%"
  }
}
const useStyles = makeStyles(styles)

const Form = props => {
  const classes = useStyles()
  const ServicesReg = useSelector(state => state.ServicesReg)
  const { register, handleSubmit, setValue } = useForm({
    defaultValues: props.data
  })
  const dispatch = useDispatch()

  const onSubmit = async values => {
    dispatch(ServicesSubmit(values))
  }

  const onDelete = () => {
    if (window.confirm("Confirma a exclusão do registro?"))
      dispatch(ServicesDelete())
  }

  const handleChangeImg = e => {
    setValue("imgFile", e.target.files[0])
  }

  useEffect(() => {
    register({ name: "imgFile" })
  }, [register])

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <form onSubmit={handleSubmit(onSubmit)}>
            <CardHeader color="primary">
              <h3>{props.data.id ? "Editando Registro" : "Novo registro"}</h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {ServicesReg.error.length !== 0 ? (
                  <GridItem xs={12} sm={12} md={12}>
                    <SnackbarContent
                      message={ServicesReg.error}
                      color="danger"
                    />
                  </GridItem>
                ) : (
                  ""
                )}
                {props.data.id ? (
                  <input
                    type="hidden"
                    name="id"
                    ref={register}
                    value={props.data.id}
                  />
                ) : (
                  ""
                )}
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    fullWidth={true}
                    label="Nome"
                    name="name"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <GridItem xs={12} sm={12} md={12}>
                    <h3>Img.:</h3>
                    <FormControl className={classes.formControl}>
                      <label className="img">Enviar Img.</label>
                      <input
                        accept="image/*"
                        onChange={handleChangeImg}
                        type="file"
                      />
                      <br />
                      {props.data.img ? (
                        <img
                          src={`${API_BASE_URL}uploads/services/${props.data.img}`}
                          style={{ maxWidth: "60px" }}
                        />
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </GridItem>
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button type="submit" color="primary">
                Salvar
              </Button>

              {props.data.id ? (
                <Button color="danger" onClick={() => onDelete()}>
                  Deletar
                </Button>
              ) : (
                ""
              )}
            </CardFooter>
          </form>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

Form.propTypes = {
  data: PropTypes.object.isRequired
}

export default Form
