import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"
import { ClientesCleanSubmit, ClientesCleanForm, Cliente } from "./api/actions"

import Form from "./Form"

const ClientesForm = props => {
  const dispatch = useDispatch()
  const ClientesSt = useSelector(state => state.Cliente)

  useEffect(() => {
    dispatch(ClientesCleanSubmit())
    dispatch(Cliente(props.match.params.id))

    return () => dispatch(ClientesCleanForm())
  }, [dispatch, props.match.params.id])

  return (
    <React.Fragment>
      {ClientesSt.data.length === 0 ? (
        ""
      ) : (
        <Form
          data={ClientesSt.data}
          sua_residencia_items={
            ClientesSt.data.sua_residencia
              ? ClientesSt.data.sua_residencia.slice(0, -1).split(",")
              : ""
          }
        />
      )}
    </React.Fragment>
  )
}

ClientesForm.propTypes = {
  match: PropTypes.object
}

export default ClientesForm
