import axios from "axios"
import { API_URL, AUTH_TOKEN, LOGIN } from "../../../setup/store"
import { history } from "../../../index"

// select
import { Types } from "../../Types/api/actions"
import { Status } from "../../Status/api/actions"
import { Brokers } from "../../Brokers/api/actions"
import { Citys } from "../../Citys/api/actions"
import { Districts } from "../../Districts/api/actions"
import { Attributes } from "../../Attributes/api/actions"
import { Services } from "../../Services/api/actions"
import { Operacao } from "../../Operacao/api/actions"

export const REAL_ESTATES_FETCH = "REAL_ESTATES_FETCH"
export const REAL_ESTATES_FETCHING = "REAL_ESTATES_FETCHING"

export const NEW_REAL_ESTATE = "NEW_REAL_ESTATE"
export const REAL_ESTATE_FETCH = "REAL_ESTATE_FETCH"
export const REAL_ESTATE_CLEAR = "REAL_ESTATE_CLEAR"

export const REAL_ESTATE_SUBMITTING = "REAL_ESTATE_SUBMITTING"
export const REAL_ESTATE_SUBMIT = "REAL_ESTATE_SUBMIT"
export const REAL_ESTATE_SUBMIT_ERROR = "REAL_ESTATE_SUBMIT_ERROR"

export const ATT_REAL_ESTATE_FETCH = "ATT_REAL_ESTATE_FETCH"
export const SERVICE_REAL_ESTATE_FETCH = "SERVICE_REAL_ESTATE_FETCH"

const URL_REDIRECT = "/admin/real_estates"
const API_TYPE = "real_estates"

export const Real_estates = () => {
  return async dispatch => {
    dispatch({
      type: REAL_ESTATES_FETCHING
    })
    try {
      const res = await axios.get(`${API_URL}${API_TYPE}`, {
        headers: {
          Authorization: AUTH_TOKEN
        }
      })

      dispatch({
        type: REAL_ESTATES_FETCH,
        data: res.data
      })
    } catch (error) {
      //  window.location.href = LOGIN
      console.error(error)
    }
  }
}

export const Real_estate = id => {
  return async dispatch => {
    // select
    dispatch(Types())
    dispatch(Status())
    dispatch(Brokers())
    dispatch(Citys())
    dispatch(Districts())
    dispatch(Attributes())
    dispatch(Services())
    dispatch(Operacao())

    if (id) {
      try {
        const res = await axios.get(`${API_URL}${API_TYPE}/${id}`, {
          headers: {
            Authorization: AUTH_TOKEN
          }
        })
        dispatch(Attributes_real_estate(id))
        dispatch(Services_real_estate(id))
        dispatch({
          type: REAL_ESTATE_FETCH,
          data: res.data
        })
      } catch (error) {
        //window.location.href = LOGIN
        console.log(error.message)
      }
    } else {
      dispatch({
        type: NEW_REAL_ESTATE
      })
    }
  }
}

export const Real_estatesCleanSubmit = () => {
  return async dispatch => {
    dispatch({
      type: REAL_ESTATE_SUBMITTING
    })
  }
}

export const Real_estatesCleanForm = () => {
  return async dispatch => {
    dispatch({
      type: REAL_ESTATE_CLEAR
    })
  }
}

export const Real_estatesDelete = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: REAL_ESTATE_SUBMITTING
    })
    const ID = getState().Real_estate.data.id
    try {
      const res = await axios.delete(`${API_URL}${API_TYPE}/${ID}`, {
        headers: {
          Authorization: AUTH_TOKEN
        }
      })
      dispatch({
        type: REAL_ESTATE_SUBMIT,
        data: res.data
      })
      history.push(URL_REDIRECT)
    } catch (error) {
      console.log(error.message)
    }
  }
}

export const Real_estatesSubmit = real_estates => {
  return async dispatch => {
    dispatch({
      type: REAL_ESTATE_SUBMITTING
    })
    try {
      let res

      if (real_estates.id) {
        res = await axios.put(
          `${API_URL}${API_TYPE}/${real_estates.id}`,
          real_estates,
          {
            headers: {
              Authorization: AUTH_TOKEN
            }
          }
        )
      } else {
        res = await axios.post(`${API_URL}${API_TYPE}/`, real_estates, {
          headers: {
            Authorization: AUTH_TOKEN
          }
        })
      }

      if (real_estates.img || real_estates.img2) {
        const form_data = new FormData()
        const API_TIME = Date.now()
        form_data.append("time", API_TIME)
        if (real_estates.img) {
          form_data.append("imgFile", real_estates.img)
        }
        if (real_estates.img2) {
          form_data.append("imgFile2", real_estates.img2)
        }
        if (real_estates.img_capa) {
          form_data.append("imgCapa", real_estates.img_capa)
        }
        form_data.append(
          "id",
          real_estates.id ? real_estates.id : res.data.reg.id
        )
        await axios.post(`${API_URL}${API_TYPE}/files/${API_TIME}`, form_data, {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: AUTH_TOKEN
          }
        })
      }

      dispatch({
        type: REAL_ESTATE_SUBMIT,
        data: res.data
      })

      if (real_estates.id) {
        history.push(URL_REDIRECT)
      } else {
        history.push("/admin/real_estates-register/" + res.data.reg.id)
      }
    } catch (error) {
      // window.location.href = LOGIN
      dispatch({
        type: REAL_ESTATE_SUBMIT_ERROR,
        error: "Error: " + error
      })
    }
  }
}

export const Attributes_real_estateSubmit = real_estates => {
  return async dispatch => {
    dispatch({
      type: REAL_ESTATE_SUBMITTING
    })
    try {
      let res
      res = await axios.post(`${API_URL}attribute_real_estate/`, real_estates, {
        headers: {
          Authorization: AUTH_TOKEN
        }
      })
      dispatch({
        type: REAL_ESTATE_SUBMIT,
        data: res.data
      })
    } catch (error) {
      console.log(error)
    }
  }
}

export const Attributes_real_estate = id => {
  return async dispatch => {
    if (id) {
      try {
        const res = await axios.get(
          `${API_URL}attribute_real_estate_sub/${id}`,
          {
            headers: {
              Authorization: AUTH_TOKEN
            }
          }
        )
        dispatch({
          type: ATT_REAL_ESTATE_FETCH,
          data: res.data
        })
      } catch (error) {
        //window.location.href = LOGIN
        console.log(error.message)
      }
    }
  }
}

export const Services_real_estateSubmit = real_estates => {
  return async dispatch => {
    dispatch({
      type: REAL_ESTATE_SUBMITTING
    })
    try {
      let res
      res = await axios.post(`${API_URL}service_real_estate/`, real_estates, {
        headers: {
          Authorization: AUTH_TOKEN
        }
      })
      dispatch({
        type: REAL_ESTATE_SUBMIT,
        data: res.data
      })
    } catch (error) {
      console.log(error)
    }
  }
}

export const Services_real_estate = id => {
  return async dispatch => {
    if (id) {
      try {
        const res = await axios.get(`${API_URL}service_real_estate_sub/${id}`, {
          headers: {
            Authorization: AUTH_TOKEN
          }
        })
        dispatch({
          type: SERVICE_REAL_ESTATE_FETCH,
          data: res.data
        })
      } catch (error) {
        //window.location.href = LOGIN
        console.log(error.message)
      }
    }
  }
}
