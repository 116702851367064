import {
  SERVICE_FETCH,
  SERVICE_CLEAR,
  NEW_SERVICE,
  SERVICES_FETCH,
  SERVICES_FETCHING,
  SERVICE_SUBMITTING,
  SERVICE_SUBMIT,
  SERVICE_SUBMIT_ERROR
} from "./actions"

const initialState = {
  data: [],
  loading: false
}
export function Service(state = initialState, action = {}) {
  switch (action.type) {
    case SERVICE_FETCH:
      return Object.assign({}, state, {
        data: action.data
      })
    case NEW_SERVICE:
      return Object.assign({}, state, {
        data: {
          id: null
        }
      })
    case SERVICE_CLEAR:
      return Object.assign({}, state, initialState)
    default:
      return state
  }
}

export function Services(state = initialState, action = {}) {
  switch (action.type) {
    case SERVICES_FETCH:
      return Object.assign({}, state, {
        data: action.data,
        loading: false
      })
    case SERVICES_FETCHING:
      return Object.assign({}, state, {
        data: [],
        loading: true
      })
    default:
      return state
  }
}

const initialStateReg = {
  data: [],
  error: []
}
export function ServicesReg(state = initialStateReg, action = {}) {
  switch (action.type) {
    case SERVICE_SUBMITTING:
      return Object.assign({}, state, initialStateReg)
    case SERVICE_SUBMIT:
      return Object.assign({}, state, {
        data: action.data,
        error: []
      })
    case SERVICE_SUBMIT_ERROR:
      return Object.assign({}, state, {
        data: [],
        error: action.error
      })
    default:
      return state
  }
}
