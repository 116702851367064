import React from "react"
import { useForm } from "react-hook-form"
import TextField from "@material-ui/core/TextField"
import InputLabel from "@material-ui/core/InputLabel"
import PropTypes from "prop-types"
import FormControl from "@material-ui/core/FormControl"

import { useSelector, useDispatch } from "react-redux"
// eslint-disable-next-line no-unused-vars
import { ClientesSubmit, ClientesDelete } from "./api/actions"

import CardHeader from "components/Card/CardHeader"
import CardBody from "components/Card/CardBody"
import CardFooter from "components/Card/CardFooter"
import SnackbarContent from "components/Snackbar/SnackbarContent"
import Button from "components/CustomButtons/Button"
import GridItem from "components/Grid/GridItem"
import GridContainer from "components/Grid/GridContainer"
import Card from "components/Card/Card"
import { makeStyles } from "@material-ui/core/styles"

import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"

const styles = {
  formControl: {
    marginTop: "15px",
    minWidth: "100%",
    "& label": {
      display: "block",
      position: "relative",
      marginBottom: "40px",
      marginTop: "-25px"
    },
    "& label.line": {
      display: "inline"
    },
    "& input": {
      float: "left"
    }
  }
}
const useStyles = makeStyles(styles)

const Form = props => {
  const classes = useStyles()
  const ClientesReg = useSelector(state => state.ClientesReg)
  const { register, handleSubmit } = useForm({
    defaultValues: props.data
  })
  const dispatch = useDispatch()

  const onSubmit = async values => {
    let sua_residencia = ""
    values.sua_residencia_op.op.map(item => {
      sua_residencia += item + ","
    })
    values.sua_residencia = sua_residencia
    dispatch(ClientesSubmit(values))
  }

  const onDelete = () => {
    if (window.confirm("Confirma a exclusão do registro?"))
      dispatch(ClientesDelete())
  }

  const [state, setState] = React.useState({
    propria_quitada: props.sua_residencia_items.includes("propria_quitada"),
    propria_financiada: props.sua_residencia_items.includes(
      "propria_financiada"
    ),
    alugada: props.sua_residencia_items.includes("alugada"),
    outros: props.sua_residencia_items.includes("outros"),
    mora_com_parentes_pai: props.sua_residencia_items.includes(
      "mora_com_parentes_pai"
    )
  })

  const handleChange = evt => {
    setState({
      ...state,
      [evt.target.value]: evt.target.checked
    })
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <form onSubmit={handleSubmit(onSubmit)}>
            <CardHeader color="primary">
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h3>
                    {props.data.id ? "Cadastro Cliente" : "Novo registro"}
                  </h3>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {ClientesReg.error.length !== 0 ? (
                  <GridItem xs={12} sm={12} md={12}>
                    <SnackbarContent
                      message={ClientesReg.error}
                      color="danger"
                    />
                  </GridItem>
                ) : (
                  ""
                )}
                {props.data.id ? (
                  <React.Fragment>
                    <input
                      type="hidden"
                      name="id"
                      ref={register}
                      value={props.data.id}
                    />
                    <input
                      type="hidden"
                      name="cliente_id"
                      ref={register}
                      value={props.data.id}
                    />
                  </React.Fragment>
                ) : (
                  ""
                )}
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    fullWidth={true}
                    label="Nome"
                    name="nome"
                    required
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <FormControl className={classes.formControl}>
                    <InputLabel>Sexo</InputLabel>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <input
                          name="sexo"
                          type="radio"
                          value="M"
                          id="op1"
                          ref={register}
                        />
                        <label className="line" htmlFor="op1">
                          M
                        </label>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <input
                          name="sexo"
                          type="radio"
                          value="F"
                          id="op2"
                          ref={register}
                        />
                        <label className="line" htmlFor="op2">
                          F
                        </label>
                      </GridItem>
                    </GridContainer>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <TextField
                    fullWidth={true}
                    label="Data nasc."
                    name="data_nasc"
                    required
                    placeholder="dt"
                    InputLabelProps={{
                      shrink: true
                    }}
                    type="date"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Estado civil"
                    name="estado_civil"
                    required
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Data casamento"
                    name="data_casamento"
                    type="date"
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Regime casamento"
                    name="regime_casamento"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Rg"
                    required
                    name="rg"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Data expedição"
                    name="data_expedicao"
                    required
                    type="date"
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Orgão expeditor"
                    name="orgao_expeditor"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="CPF"
                    required
                    name="cpf"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Nacionalidade"
                    name="nascionalidade"
                    required
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Naturalidade"
                    required
                    name="naturalidade"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={8}>
                  <TextField
                    fullWidth={true}
                    label="Endereço"
                    name="endereco"
                    required
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="CEP"
                    required
                    name="cep"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={5}>
                  <TextField
                    fullWidth={true}
                    label="Bairro"
                    required
                    name="bairro"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={5}>
                  <TextField
                    fullWidth={true}
                    label="Cidade"
                    required
                    name="cidade"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <TextField
                    fullWidth={true}
                    label="Estado"
                    name="estado"
                    required
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    fullWidth={true}
                    label="Tempo de domicílio (anos)"
                    name="tempo_de_domicilio"
                    required
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    fullWidth={true}
                    label="Email"
                    required
                    name="email"
                    tyle="email"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Fone residencial"
                    name="fone_residencial"
                    required
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Celular"
                    name="celular"
                    required
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Outros"
                    name="outros"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    fullWidth={true}
                    label="Nome da mãe"
                    name="nome_da_mae"
                    required
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    fullWidth={true}
                    label="Nome do pai"
                    required
                    name="nome_do_pai"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <h3>Dados profissionais / comercial</h3>
                </GridItem>
                <GridItem xs={12} sm={12} md={8}>
                  <TextField
                    fullWidth={true}
                    label="Empresa"
                    name="empresa"
                    required
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="CNPJ"
                    name="cnpj"
                    required
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={8}>
                  <TextField
                    fullWidth={true}
                    label="Endereço"
                    name="endereco_emp"
                    required
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="CEP"
                    required
                    name="cep_emp"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={5}>
                  <TextField
                    fullWidth={true}
                    label="Bairro"
                    required
                    name="bairro_emp"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={5}>
                  <TextField
                    fullWidth={true}
                    label="Cidade"
                    required
                    name="cidade_emp"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <TextField
                    fullWidth={true}
                    label="Estado"
                    required
                    name="estado_emp"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Fone comercial"
                    name="fone_comercial"
                    required
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Cargo atual"
                    name="cargo_atual"
                    required
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Profissão"
                    name="profissao"
                    required
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    fullWidth={true}
                    label="Tempo de empresa"
                    name="tempo_de_empresa"
                    required
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    fullWidth={true}
                    label="E_mail emp."
                    name="email_emp"
                    tyle="email"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Salário bruto"
                    name="salario_bruto"
                    required
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Salário liquido"
                    name="salario_liquido"
                    required
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="FGTS"
                    name="fgts"
                    required
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <h3>Outros Rendimentos</h3>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Renda informal"
                    name="renda_informal"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Aluguel"
                    name="aluguel"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Aposentadoria"
                    name="aposentadoria"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Decore"
                    name="decore"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Faturamento"
                    name="faturamento"
                    required
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Quantidade sócios"
                    name="quantidade_socios"
                    required
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                    fullWidth={true}
                    label="Outras rendas"
                    name="outras_rendas"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <FormControl className={classes.formControl}>
                    <InputLabel>Sua residência é</InputLabel>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={3}>
                        <input
                          name="sua_residencia_op[op]"
                          type="checkbox"
                          value="propria_quitada"
                          onClick={handleChange}
                          checked={state.propria_quitada}
                          ref={register}
                          id="op3"
                        />
                        <label className="line" htmlFor="op3">
                          Própria quitada
                        </label>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={3}>
                        <input
                          name="sua_residencia_op[op]"
                          type="checkbox"
                          value="propria_financiada"
                          onClick={handleChange}
                          checked={state.propria_financiada}
                          ref={register}
                          id="op4"
                        />
                        <label className="line" htmlFor="op4">
                          Própria financiada
                        </label>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={2}>
                        <input
                          name="sua_residencia_op[op]"
                          type="checkbox"
                          value="alugada"
                          onClick={handleChange}
                          checked={state.alugada}
                          ref={register}
                          id="op5"
                        />
                        <label className="line" htmlFor="op5">
                          Alugada
                        </label>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={2}>
                        <input
                          name="sua_residencia_op[op]"
                          type="checkbox"
                          value="outros"
                          onClick={handleChange}
                          checked={state.outros}
                          ref={register}
                          id="op6"
                        />
                        <label className="line" htmlFor="op6">
                          Outros
                        </label>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={3}>
                        <input
                          name="sua_residencia_op[op]"
                          type="checkbox"
                          value="mora_com_parentes_pai"
                          onClick={handleChange}
                          checked={state.mora_com_parentes_pai}
                          ref={register}
                          id="op7"
                        />
                        <label className="line" htmlFor="op7">
                          Mora com parentes _ pai
                        </label>
                      </GridItem>
                    </GridContainer>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                    fullWidth={true}
                    label="Se paga aluguel, qual valor?"
                    name="se_paga_aluguel"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                {/* <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    fullWidth={true}
                    label="qual_valor_aluguel"
                    name="qual_valor_aluguel"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem> */}
                <div className="html2pdf__page_break"></div>
                <GridItem xs={12} sm={12} md={4}>
                  <FormControl className={classes.formControl}>
                    <InputLabel>
                      Algum dos proponents possui imovel residencial?
                    </InputLabel>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <input
                          name="algum_dos_proponents_possui_imovel_residencial"
                          type="radio"
                          value="S"
                          id="op8"
                          ref={register}
                        />
                        <label className="line" htmlFor="op8">
                          Sim
                        </label>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <input
                          name="algum_dos_proponents_possui_imovel_residencial"
                          type="radio"
                          value="N"
                          id="op9"
                          ref={register}
                        />
                        <label className="line" htmlFor="op9">
                          Não
                        </label>
                      </GridItem>
                    </GridContainer>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={5}>
                  <TextField
                    fullWidth={true}
                    label="Se sim onde?"
                    name="se_sim_onde"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <TextField
                    fullWidth={true}
                    label="Valor do imovel?"
                    name="valor_do_imovel"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl className={classes.formControl}>
                    <InputLabel>
                      Possui ou possuiu financiamento habitacional ou de
                      materiais de construção na Caixa?
                    </InputLabel>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <input
                          name="possui_ou_possuiu_financiamento_caixa"
                          type="radio"
                          value="S"
                          id="op10"
                          ref={register}
                        />
                        <label className="line" htmlFor="op10">
                          Sim
                        </label>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <input
                          name="possui_ou_possuiu_financiamento_caixa"
                          type="radio"
                          value="N"
                          id="op11"
                          ref={register}
                        />
                        <label className="line" htmlFor="op11">
                          Não
                        </label>
                      </GridItem>
                    </GridContainer>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    fullWidth={true}
                    label="Se sim quando foi feito o contrato?"
                    name="se_sim_quando_foi_feito_o_contrato"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl className={classes.formControl}>
                    <InputLabel>
                      Possui ou possuiu financiamento estudantil?
                    </InputLabel>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <input
                          name="possui_ou_possuiu_financiamento_estudantil"
                          type="radio"
                          value="S"
                          id="op12"
                          ref={register}
                        />
                        <label className="line" htmlFor="op12">
                          Sim
                        </label>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <input
                          name="possui_ou_possuiu_financiamento_estudantil"
                          type="radio"
                          value="N"
                          id="op13"
                          ref={register}
                        />
                        <label className="line" htmlFor="op13">
                          Não
                        </label>
                      </GridItem>
                    </GridContainer>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl className={classes.formControl}>
                    <InputLabel>
                      Se possui, existe alguma parcela em atraso?
                    </InputLabel>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <input
                          name="se_possui,_existe_alguma_parcela_em_atraso"
                          type="radio"
                          value="S"
                          id="op14"
                          ref={register}
                        />
                        <label className="line" htmlFor="op14">
                          Sim
                        </label>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <input
                          name="se_possui,_existe_alguma_parcela_em_atraso"
                          type="radio"
                          value="N"
                          id="op15"
                          ref={register}
                        />
                        <label className="line" htmlFor="op15">
                          Não
                        </label>
                      </GridItem>
                    </GridContainer>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <FormControl className={classes.formControl}>
                    <InputLabel>Possui veiculo ou moto?</InputLabel>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <input
                          name="possui_veiculo_ou_moto"
                          type="radio"
                          value="S"
                          id="op16"
                          ref={register}
                        />
                        <label className="line" htmlFor="op16">
                          Sim
                        </label>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <input
                          name="possui_veiculo_ou_moto"
                          type="radio"
                          value="N"
                          id="op17"
                          ref={register}
                        />
                        <label className="line" htmlFor="op17">
                          Não
                        </label>
                      </GridItem>
                    </GridContainer>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <FormControl className={classes.formControl}>
                    <InputLabel>Se sim, está fianciado?</InputLabel>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                        <input
                          name="se_sim_esta_fianciado"
                          type="radio"
                          value="S"
                          id="op18"
                          ref={register}
                        />
                        <label className="line" htmlFor="op18">
                          Sim
                        </label>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <input
                          name="se_sim_esta_fianciado"
                          type="radio"
                          value="N"
                          id="op19"
                          ref={register}
                        />
                        <label className="line" htmlFor="op19">
                          Não
                        </label>
                      </GridItem>
                    </GridContainer>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Valor do veiculo ou moto"
                    name="valor_do_veiculo_ou_moto"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <FormControl className={classes.formControl}>
                    <InputLabel>Possui conta corrente?</InputLabel>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <input
                          name="possui_conta_corrente"
                          type="radio"
                          value="S"
                          id="op20"
                          ref={register}
                        />
                        <label className="line" htmlFor="op20">
                          Sim
                        </label>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <input
                          name="possui_conta_corrente"
                          type="radio"
                          value="N"
                          id="op21"
                          ref={register}
                        />
                        <label className="line" htmlFor="op21">
                          Não
                        </label>
                      </GridItem>
                    </GridContainer>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <FormControl className={classes.formControl}>
                    <InputLabel>Possui cheque especial?</InputLabel>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <input
                          name="possui_cheque_especial"
                          type="radio"
                          value="S"
                          id="op22"
                          ref={register}
                        />
                        <label className="line" htmlFor="op22">
                          Sim
                        </label>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <input
                          name="possui_cheque_especial"
                          type="radio"
                          value="N"
                          id="op23"
                          ref={register}
                        />
                        <label className="line" htmlFor="op23">
                          Não
                        </label>
                      </GridItem>
                    </GridContainer>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <TextField
                    fullWidth={true}
                    label="Valor cheque especial"
                    name="valor_cheque_especial"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <TextField
                    fullWidth={true}
                    label="Banco"
                    name="banco"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <FormControl className={classes.formControl}>
                    <InputLabel>Possui cartão de crédito?</InputLabel>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <input
                          name="possui_cartao_de_credito"
                          type="radio"
                          value="S"
                          id="op24"
                          ref={register}
                        />
                        <label className="line" htmlFor="op24">
                          Sim
                        </label>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <input
                          name="possui_cartao_de_credito"
                          type="radio"
                          value="N"
                          id="op25"
                          ref={register}
                        />
                        <label className="line" htmlFor="op25">
                          Não
                        </label>
                      </GridItem>
                    </GridContainer>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Quantos cartões de créditos?"
                    name="quantos_cartoes_de_creditos"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Valor limite cartão de crédito"
                    name="valor_limite_cartao_de_credito"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={8}>
                  <FormControl className={classes.formControl}>
                    <InputLabel>
                      Possui poupança ou outra aplicação financeira?
                    </InputLabel>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <input
                          name="possui_poupanca_ou_outra_aplicacao_financeira"
                          type="radio"
                          value="S"
                          id="op26"
                          ref={register}
                        />
                        <label className="line" htmlFor="op26">
                          Sim
                        </label>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <input
                          name="possui_poupanca_ou_outra_aplicacao_financeira"
                          type="radio"
                          value="N"
                          id="op27"
                          ref={register}
                        />
                        <label className="line" htmlFor="op27">
                          Não
                        </label>
                      </GridItem>
                    </GridContainer>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Valor aplicação financeira"
                    name="valor_aplicacao_financeira"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <FormControl className={classes.formControl}>
                    <InputLabel>Declara imposto de renda?</InputLabel>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <input
                          name="declara_imposto_de_renda"
                          type="radio"
                          value="S"
                          id="op28"
                          ref={register}
                        />
                        <label className="line" htmlFor="op28">
                          Sim
                        </label>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <input
                          name="declara_imposto_de_renda"
                          type="radio"
                          value="N"
                          id="op29"
                          ref={register}
                        />
                        <label className="line" htmlFor="op29">
                          Não
                        </label>
                      </GridItem>
                    </GridContainer>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <FormControl className={classes.formControl}>
                    <InputLabel>Tem retificadora?</InputLabel>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <input
                          name="tem_retificadora"
                          type="radio"
                          value="S"
                          id="op30"
                          ref={register}
                        />
                        <label className="line" htmlFor="op30">
                          Sim
                        </label>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <input
                          name="tem_retificadora"
                          type="radio"
                          value="N"
                          id="op31"
                          ref={register}
                        />
                        <label className="line" htmlFor="op31">
                          Não
                        </label>
                      </GridItem>
                    </GridContainer>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <FormControl className={classes.formControl}>
                    <InputLabel>
                      2 proponente: Declara imposto de renda ?
                    </InputLabel>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <input
                          name="declara_imposto_de_renda_2_proponente"
                          type="radio"
                          value="S"
                          id="op32"
                          ref={register}
                        />
                        <label className="line" htmlFor="op32">
                          Sim
                        </label>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <input
                          name="declara_imposto_de_renda_2_proponente"
                          type="radio"
                          value="N"
                          id="op33"
                          ref={register}
                        />
                        <label className="line" htmlFor="op33">
                          Não
                        </label>
                      </GridItem>
                    </GridContainer>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <FormControl className={classes.formControl}>
                    <InputLabel>2 proponente: Tem retificadora?</InputLabel>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <input
                          name="tem_retificadora_2_proponente"
                          type="radio"
                          value="S"
                          id="op34"
                          ref={register}
                        />
                        <label className="line" htmlFor="op34">
                          Sim
                        </label>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <input
                          name="tem_retificadora_2_proponente"
                          type="radio"
                          value="N"
                          id="op35"
                          ref={register}
                        />
                        <label className="line" htmlFor="op35">
                          Não
                        </label>
                      </GridItem>
                    </GridContainer>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl className={classes.formControl}>
                    <InputLabel>
                      Pelo menos um dos proponentes comprova 36 meses de FGTS
                      com contrato ativou ou saldo em conta?
                    </InputLabel>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <input
                          name="pelo_menos_um_dos_proponentes_comprova_36_meses"
                          type="radio"
                          value="S"
                          id="op36"
                          ref={register}
                        />
                        <label className="line" htmlFor="op36">
                          Sim
                        </label>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <input
                          name="pelo_menos_um_dos_proponentes_comprova_36_meses"
                          type="radio"
                          value="N"
                          id="op37"
                          ref={register}
                        />
                        <label className="line" htmlFor="op37">
                          Não
                        </label>
                      </GridItem>
                    </GridContainer>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <FormControl className={classes.formControl}>
                    <InputLabel>
                      1 proponente utilizará o FGTS como recurso proprio?
                    </InputLabel>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <input
                          name="1_proponente_utilizara_o_fgts_como_recurso_proprio"
                          type="radio"
                          value="S"
                          id="op38"
                          ref={register}
                        />
                        <label className="line" htmlFor="op38">
                          Sim
                        </label>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <input
                          name="1_proponente_utilizara_o_fgts_como_recurso_proprio"
                          type="radio"
                          value="N"
                          id="op39"
                          ref={register}
                        />
                        <label className="line" htmlFor="op39">
                          Não
                        </label>
                      </GridItem>
                    </GridContainer>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <FormControl className={classes.formControl}>
                    <InputLabel>
                      2 proponente utilizará o FGTS como recurso proprio?
                    </InputLabel>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <input
                          name="2_proponente_utilizara_o_fgts_como_recurso_proprio"
                          type="radio"
                          value="S"
                          id="op40"
                          ref={register}
                        />
                        <label className="line" htmlFor="op40">
                          Sim
                        </label>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <input
                          name="2_proponente_utilizara_o_fgts_como_recurso_proprio"
                          type="radio"
                          value="N"
                          id="op41"
                          ref={register}
                        />
                        <label className="line" htmlFor="op41">
                          Não
                        </label>
                      </GridItem>
                    </GridContainer>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <h3>Compromissos financeiros do cliente</h3>
                  <h4>1 proponente</h4>
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria_label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Tipo</TableCell>
                          <TableCell align="left">Valor Parcelas</TableCell>
                          <TableCell align="left">Quantidades Vencer</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell align="left">Comércio</TableCell>
                          <TableCell align="left">
                            <TextField
                              fullWidth={true}
                              name="1_proponente_comercio_valor_parcela"
                              inputRef={register}
                            />
                          </TableCell>
                          <TableCell align="left">
                            <TextField
                              fullWidth={true}
                              name="1_proponente_comercio_quantidade_a_vencer"
                              inputRef={register}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left">Empréstimo Banco</TableCell>
                          <TableCell align="left">
                            <TextField
                              fullWidth={true}
                              name="1_proponente_emprestimo_banco_valor_parcela"
                              inputRef={register}
                            />
                          </TableCell>
                          <TableCell align="left">
                            <TextField
                              fullWidth={true}
                              name="1_proponente_emprestimo_banco_quantidade_a_vencer"
                              inputRef={register}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left">
                            Empréstimo Contra Cheque
                          </TableCell>
                          <TableCell align="left">
                            <TextField
                              fullWidth={true}
                              name="1_proponente_emprestimo_conta_corrente_valor_parcela"
                              inputRef={register}
                            />
                          </TableCell>
                          <TableCell align="left">
                            <TextField
                              fullWidth={true}
                              name="1_proponente_emprestimo_conta_corrente_quantidade_a_vencer"
                              inputRef={register}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left">
                            Financiamento estudantil
                          </TableCell>
                          <TableCell align="left">
                            <TextField
                              fullWidth={true}
                              name="1_proponente_financiamento_estudantil_valor_parcela"
                              inputRef={register}
                            />
                          </TableCell>
                          <TableCell align="left">
                            <TextField
                              fullWidth={true}
                              name="1_proponente_financiamento_estudantil_quantidade_a_vencer"
                              inputRef={register}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left">
                            Financiamento habitacional
                          </TableCell>
                          <TableCell align="left">
                            <TextField
                              fullWidth={true}
                              name="1_proponente_financiamento_habitacional_valor_parcela"
                              inputRef={register}
                            />
                          </TableCell>
                          <TableCell align="left">
                            <TextField
                              fullWidth={true}
                              name="1_proponente_financiamento_habitacional_quantidade_a_vencer"
                              inputRef={register}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left">
                            Financiamento veículo
                          </TableCell>
                          <TableCell align="left">
                            <TextField
                              fullWidth={true}
                              name="1_proponente_financiamento_veiculo_valor_parcela"
                              inputRef={register}
                            />
                          </TableCell>
                          <TableCell align="left">
                            <TextField
                              fullWidth={true}
                              name="1_proponente_financiamento_veiculo_quantidade_a_vencer"
                              inputRef={register}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left">Pensão alimentícia</TableCell>
                          <TableCell align="left">
                            <TextField
                              fullWidth={true}
                              name="1_proponente_pensao_alimenticia_valor_parcela"
                              inputRef={register}
                            />
                          </TableCell>
                          <TableCell align="left">
                            <TextField
                              fullWidth={true}
                              name="1_proponente_pensao_alimenticia_quantidade_a_vencer"
                              inputRef={register}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <h4>2 proponente</h4>
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria_label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Tipo</TableCell>
                          <TableCell align="left">Valor Parcelas</TableCell>
                          <TableCell align="left">Quantidades Vencer</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell align="left">Comércio</TableCell>
                          <TableCell align="left">
                            <TextField
                              fullWidth={true}
                              name="2_proponente_comercio_valor_parcela"
                              inputRef={register}
                            />
                          </TableCell>
                          <TableCell align="left">
                            <TextField
                              fullWidth={true}
                              name="2_proponente_comercio_quantidade_a_vencer"
                              inputRef={register}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left">Empréstimo Banco</TableCell>
                          <TableCell align="left">
                            <TextField
                              fullWidth={true}
                              name="2_proponente_emprestimo_banco_valor_parcela"
                              inputRef={register}
                            />
                          </TableCell>
                          <TableCell align="left">
                            <TextField
                              fullWidth={true}
                              name="2_proponente_emprestimo_banco_quantidade_a_vencer"
                              inputRef={register}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left">
                            Empréstimo Contra Cheque
                          </TableCell>
                          <TableCell align="left">
                            <TextField
                              fullWidth={true}
                              name="2_proponente_emprestimo_conta_corrente_valor_parcela"
                              inputRef={register}
                            />
                          </TableCell>
                          <TableCell align="left">
                            <TextField
                              fullWidth={true}
                              name="2_proponente_emprestimo_conta_corrente_quantidade_a_vencer"
                              inputRef={register}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left">
                            Financiamento estudantil
                          </TableCell>
                          <TableCell align="left">
                            <TextField
                              fullWidth={true}
                              name="2_proponente_financiamento_estudantil_valor_parcela"
                              inputRef={register}
                            />
                          </TableCell>
                          <TableCell align="left">
                            <TextField
                              fullWidth={true}
                              name="2_proponente_financiamento_estudantil_quantidade_a_vencer"
                              inputRef={register}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left">
                            Financiamento habitacional
                          </TableCell>
                          <TableCell align="left">
                            <TextField
                              fullWidth={true}
                              name="2_proponente_financiamento_habitacional_valor_parcela"
                              inputRef={register}
                            />
                          </TableCell>
                          <TableCell align="left">
                            <TextField
                              fullWidth={true}
                              name="2_proponente_financiamento_habitacional_quantidade_a_vencer"
                              inputRef={register}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left">
                            Financiamento veículo
                          </TableCell>
                          <TableCell align="left">
                            <TextField
                              fullWidth={true}
                              name="2_proponente_financiamento_veiculo_valor_parcela"
                              inputRef={register}
                            />
                          </TableCell>
                          <TableCell align="left">
                            <TextField
                              fullWidth={true}
                              name="2_proponente_financiamento_veiculo_quantidade_a_vencer"
                              inputRef={register}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left">Pensão alimentícia</TableCell>
                          <TableCell align="left">
                            <TextField
                              fullWidth={true}
                              name="2_proponente_pensao_alimenticia_valor_parcela"
                              inputRef={register}
                            />
                          </TableCell>
                          <TableCell align="left">
                            <TextField
                              fullWidth={true}
                              name="2_proponente_pensao_alimenticia_quantidade_a_vencer"
                              inputRef={register}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </GridItem>

                <GridItem xs={12} sm={12} md={12}>
                  <h2>2 Proponente _ Dados Pessoais</h2>
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    fullWidth={true}
                    label="Nome"
                    name="sec_nome"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <FormControl className={classes.formControl}>
                    <InputLabel>Sexo</InputLabel>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <input
                          name="sec_sexo"
                          type="radio"
                          value="M"
                          id="op42"
                          ref={register}
                        />
                        <label className="line" htmlFor="op42">
                          M
                        </label>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <input
                          name="sec_sexo"
                          type="radio"
                          value="F"
                          id="op43"
                          ref={register}
                        />
                        <label className="line" htmlFor="op43">
                          F
                        </label>
                      </GridItem>
                    </GridContainer>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <TextField
                    fullWidth={true}
                    label="Data nasc."
                    name="sec_data_nasc"
                    placeholder="dt"
                    InputLabelProps={{
                      shrink: true
                    }}
                    type="date"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Estado civil"
                    name="sec_estado_civil"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Data casamento"
                    name="sec_data_casamento"
                    type="date"
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Regime casamento"
                    name="sec_regime_casamento"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Rg"
                    name="sec_rg"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Data expedição"
                    name="sec_data_expedicao"
                    type="date"
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Orgão expeditor"
                    name="sec_orgao_expeditor"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="CPF"
                    name="sec_cpf"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Nacionalidade"
                    name="sec_nascionalidade"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Naturalidade"
                    name="sec_naturalidade"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={8}>
                  <TextField
                    fullWidth={true}
                    label="Endereço"
                    name="sec_endereco"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="CEP"
                    name="sec_cep"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={5}>
                  <TextField
                    fullWidth={true}
                    label="Bairro"
                    name="sec_bairro"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={5}>
                  <TextField
                    fullWidth={true}
                    label="Cidade"
                    name="sec_cidade"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <TextField
                    fullWidth={true}
                    label="Estado"
                    name="sec_estado"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    fullWidth={true}
                    label="Tempo de domicílio (anos)"
                    name="sec_tempo_de_domicilio"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    fullWidth={true}
                    label="Email"
                    name="sec_email"
                    tyle="email"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Fone residencial"
                    name="sec_fone_residencial"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Celular"
                    name="sec_celular"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Outros"
                    name="sec_outros"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    fullWidth={true}
                    label="Nome da mãe"
                    name="sec_nome_da_mae"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    fullWidth={true}
                    label="Nome do pai"
                    name="sec_nome_do_pai"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <h3>Dados profissionais / comercial</h3>
                </GridItem>
                <GridItem xs={12} sm={12} md={8}>
                  <TextField
                    fullWidth={true}
                    label="Empresa"
                    name="sec_empresa"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="CNPJ"
                    name="sec_cnpj"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={8}>
                  <TextField
                    fullWidth={true}
                    label="Endereço"
                    name="sec_endereco_emp"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="CEP"
                    name="sec_cep_emp"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={5}>
                  <TextField
                    fullWidth={true}
                    label="Bairro"
                    name="sec_bairro_emp"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={5}>
                  <TextField
                    fullWidth={true}
                    label="Cidade"
                    name="sec_cidade_emp"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <TextField
                    fullWidth={true}
                    label="Estado"
                    name="sec_estado_emp"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Fone comercial"
                    name="sec_fone_comercial"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Cargo atual"
                    name="sec_cargo_atual"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Profissão"
                    name="sec_profissao"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    fullWidth={true}
                    label="Tempo de empresa"
                    name="sec_tempo_de_empresa"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    fullWidth={true}
                    label="E_mail emp."
                    name="sec_email_emp"
                    tyle="email"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Salário bruto"
                    name="sec_salario_bruto"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Salário liquido"
                    name="sec_salario_liquido"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="FGTS"
                    name="sec_fgts"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <h3>Outros Rendimentos</h3>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Renda informal"
                    name="sec_renda_informal"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Aluguel"
                    name="sec_aluguel"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Aposentadoria"
                    name="sec_aposentadoria"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Decore"
                    name="sec_decore"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Faturamento"
                    name="sec_faturamento"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Quantidade sócios"
                    name="sec_quantidade_socios"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                    fullWidth={true}
                    label="Outras rendas"
                    name="sec_outras_rendas"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button type="submit" color="primary">
                Salvar
              </Button>

              {props.data.id ? (
                <Button color="danger" onClick={() => onDelete()}>
                  Deletar
                </Button>
              ) : (
                ""
              )}
            </CardFooter>
          </form>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

Form.propTypes = {
  data: PropTypes.object.isRequired,
  sua_residencia_items: PropTypes.object.isRequired
}

export default Form
