import React from "react"
import { useForm } from "react-hook-form"
import TextField from "@material-ui/core/TextField"
import InputLabel from "@material-ui/core/InputLabel"
import PropTypes from "prop-types"
import FormControl from "@material-ui/core/FormControl"

import { useSelector } from "react-redux"
import { FormatDateBR } from "../../setup/util"

import CardHeader from "components/Card/CardHeader"
import CardBody from "components/Card/CardBody"
import SnackbarContent from "components/Snackbar/SnackbarContent"
import GridItem from "components/Grid/GridItem"
import GridContainer from "components/Grid/GridContainer"
import Card from "components/Card/Card"
import { makeStyles } from "@material-ui/core/styles"

const styles = {
  formControl: {
    marginTop: "0",
    minWidth: "100%",
    "& label": {
      fontSize: "13px",
      marginTop: "10px",
      position: "relative",
      transform: "none"
    },
    "& label.radio": {
      display: "block",
      position: "relative",
      fontWeight: "bold",
      margin: "4px 0"
    },
    "& label.line": {
      display: "inline"
    },
    "& input": {
      float: "left",
      height: "0.9875em"
    }
  }
}
const useStyles = makeStyles(styles)

const FormImp = props => {
  const classes = useStyles()
  const ClientesReg = useSelector(state => state.ClientesReg)
  const { register } = useForm({
    defaultValues: props.data
  })

  const [state] = React.useState({
    propria_quitada: props.sua_residencia_items.includes("propria_quitada"),
    propria_financiada: props.sua_residencia_items.includes(
      "propria_financiada"
    ),
    alugada: props.sua_residencia_items.includes("alugada"),
    outros: props.sua_residencia_items.includes("outros"),
    mora_com_parentes_pai: props.sua_residencia_items.includes(
      "mora_com_parentes_pai"
    )
  })

  const generatePDF = name => {
    const element = document.querySelector(".cliente_form")
    //eslint-disable-next-line no-undef
    html2pdf(element, {
      margin: 0,
      filename: "Cliente " + name
    })
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <form>
            <CardHeader color="primary">
              <GridContainer>
                <GridItem xs={12} sm={12} md={8}>
                  <h3>
                    {props.data.id ? "Relatório Cliente" : "Novo registro"}
                  </h3>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <h4
                    style={{
                      cursor: "pointer",
                      lineHeight: "1em",
                      marginTop: "13px"
                    }}
                    onClick={() => generatePDF(props.data.nome)}
                  >
                    Gerar PDF
                  </h4>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody className="cliente_form">
              <GridContainer className={classes.formControl}>
                {ClientesReg.error.length !== 0 ? (
                  <GridItem xs={12} sm={12} md={12}>
                    <SnackbarContent
                      message={ClientesReg.error}
                      color="danger"
                    />
                  </GridItem>
                ) : (
                  ""
                )}
                {props.data.id ? (
                  <React.Fragment>
                    <input
                      type="hidden"
                      name="id"
                      ref={register}
                      value={props.data.id}
                    />
                    <input
                      type="hidden"
                      name="cliente_id"
                      ref={register}
                      value={props.data.id}
                    />
                  </React.Fragment>
                ) : (
                  ""
                )}
                <GridItem xs={12} sm={12} md={12}>
                  <h3>Relatório Cliente</h3>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <table>
                    <tr>
                      <td>
                        <b>Nome:</b> {props.data.nome}
                      </td>
                      <td>
                        <FormControl className={classes.formControl}>
                          <InputLabel className="radio">Sexo</InputLabel>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                              <input
                                name="sexo"
                                type="radio"
                                value="M"
                                id="op1"
                                ref={register}
                              />
                              <label className="line" htmlFor="op1">
                                M
                              </label>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                              <input
                                name="sexo"
                                type="radio"
                                value="F"
                                id="op2"
                                ref={register}
                              />
                              <label className="line" htmlFor="op2">
                                F
                              </label>
                            </GridItem>
                          </GridContainer>
                        </FormControl>
                      </td>
                      <td>
                        <b>Data nasc.:</b> {FormatDateBR(props.data.data_nasc)}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Estado civil:</b> {props.data.estado_civil}
                      </td>
                      <td>
                        <b>Data casamento:</b>{" "}
                        {FormatDateBR(props.data.data_casamento)}
                      </td>
                      <td>
                        <b>Regime casamento:</b> {props.data.regime_casamento}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Rg:</b> {props.data.rg}
                      </td>
                      <td>
                        <b>Data expedição:</b>{" "}
                        {FormatDateBR(props.data.data_expedicao)}
                      </td>
                      <td>
                        <b>Orgão expeditor:</b> {props.data.orgao_expeditor}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>CPF:</b> {props.data.cpf}
                      </td>
                      <td>
                        <b>Nacionalidade:</b> {props.data.nascionalidade}
                      </td>
                      <td>
                        <b>Naturalidade:</b> {props.data.naturalidade}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <b>Endereço:</b> {props.data.endereco}
                      </td>
                      <td>
                        <b>CEP:</b> {props.data.cep}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Bairro:</b> {props.data.bairro}
                      </td>
                      <td>
                        <b>Cidade:</b> {props.data.cidade}
                      </td>
                      <td>
                        <b>Estado:</b> {props.data.estado}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <b>Tempo de domicílio (anos):</b>{" "}
                        {props.data.tempo_de_domicilio}
                      </td>
                      <td>
                        <b>Email:</b> {props.data.email}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Fone residencial:</b> {props.data.fone_residencial}
                      </td>
                      <td>
                        <b>Celular:</b> {props.data.celular}
                      </td>
                      <td>
                        <b>Outros:</b> {props.data.outros}
                      </td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td>
                        <b>Nome da mãe:</b> {props.data.nome_da_mae}
                      </td>
                      <td>
                        <b>Nome do pai:</b> {props.data.nome_do_pai}
                      </td>
                    </tr>
                  </table>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <h3>Dados profissionais / comercial</h3>
                  <table>
                    <tr>
                      <td colSpan="2">
                        <b>Empresa:</b> {props.data.empresa}
                      </td>
                      <td>
                        <b>CNPJ:</b> {props.data.cnpj}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <b>Endereço:</b> {props.data.endereco_emp}
                      </td>
                      <td>
                        <b>CEP:</b> {props.data.cep_emp}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Bairro:</b> {props.data.bairro_emp}
                      </td>
                      <td>
                        <b>Cidade:</b> {props.data.cidade_emp}
                      </td>
                      <td>
                        <b>Estado:</b> {props.data.estado_emp}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Fone comercial:</b> {props.data.fone_comercial}
                      </td>
                      <td>
                        <b>Cargo atual:</b> {props.data.cargo_atual}
                      </td>
                      <td>
                        <b>Profissão:</b> {props.data.profissao}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <b>Tempo de empresa:</b> {props.data.tempo_de_empresa}
                      </td>
                      <td>
                        <b>E-mail emp.:</b> {props.data.email_emp}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Salário bruto:</b> {props.data.salario_bruto}
                      </td>
                      <td>
                        <b>Salário liquido:</b> {props.data.salario_liquido}
                      </td>
                      <td>
                        <b>FGTS:</b> {props.data.fgts}
                      </td>
                    </tr>
                  </table>
                  <GridItem xs={12} sm={12} md={12}>
                    <h3>Outros Rendimentos</h3>
                  </GridItem>
                  <table>
                    <tr>
                      <td>
                        <b>Renda informal:</b> {props.data.renda_informal}
                      </td>
                      <td>
                        <b>Aluguel:</b> {props.data.aluguel}
                      </td>
                      <td>
                        <b>Aposentadoria:</b> {props.data.aposentadoria}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Decore:</b> {props.data.decore}
                      </td>
                      <td>
                        <b>Faturamento:</b> {props.data.faturamento}
                      </td>
                      <td>
                        <b>Quantidade sócios:</b> {props.data.quantidade_socios}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3">
                        <b>Outras rendas:</b> {props.data.outras_rendas}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3">
                        <FormControl className={classes.formControl}>
                          <InputLabel className="radio">
                            Sua residência é
                          </InputLabel>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={3}>
                              <input
                                name="sua_residencia_op[op]"
                                type="checkbox"
                                value="propria_quitada"
                                checked={state.propria_quitada}
                                ref={register}
                                id="op3"
                              />
                              <label className="line" htmlFor="op3">
                                Própria quitada
                              </label>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                              <input
                                name="sua_residencia_op[op]"
                                type="checkbox"
                                value="propria_financiada"
                                checked={state.propria_financiada}
                                ref={register}
                                id="op4"
                              />
                              <label className="line" htmlFor="op4">
                                Própria financiada
                              </label>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={2}>
                              <input
                                name="sua_residencia_op[op]"
                                type="checkbox"
                                value="alugada"
                                checked={state.alugada}
                                ref={register}
                                id="op5"
                              />
                              <label className="line" htmlFor="op5">
                                Alugada
                              </label>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={2}>
                              <input
                                name="sua_residencia_op[op]"
                                type="checkbox"
                                value="outros"
                                checked={state.outros}
                                ref={register}
                                id="op6"
                              />
                              <label className="line" htmlFor="op6">
                                Outros
                              </label>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                              <input
                                name="sua_residencia_op[op]"
                                type="checkbox"
                                value="mora_com_parentes_pai"
                                checked={state.mora_com_parentes_pai}
                                ref={register}
                                id="op7"
                              />
                              <label className="line" htmlFor="op7">
                                Mora com parentes - pai
                              </label>
                            </GridItem>
                          </GridContainer>
                        </FormControl>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3">
                        <b>Se paga aluguel, qual valor?:</b>{" "}
                        {props.data.se_paga_aluguel}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <FormControl className={classes.formControl}>
                          <InputLabel className="radio">
                            Algum dos proponents possui imovel residencial?
                          </InputLabel>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                              <input
                                name="algum_dos_proponents_possui_imovel_residencial"
                                type="radio"
                                value="S"
                                id="op8"
                                ref={register}
                              />
                              <label className="line" htmlFor="op8">
                                Sim
                              </label>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                              <input
                                name="algum_dos_proponents_possui_imovel_residencial"
                                type="radio"
                                value="N"
                                id="op9"
                                ref={register}
                              />
                              <label className="line" htmlFor="op9">
                                Não
                              </label>
                            </GridItem>
                          </GridContainer>
                        </FormControl>
                      </td>
                      <td>
                        <b>Se sim onde?:</b> {props.data.se_sim_onde}
                      </td>
                      <td>
                        <b>Valor do imovel?:</b> {props.data.valor_do_imovel}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <FormControl className={classes.formControl}>
                          <InputLabel className="radio">
                            Possui ou possuiu financiamento habitacional ou de
                            materiais de construção na Caixa?
                          </InputLabel>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                              <input
                                name="possui_ou_possuiu_financiamento_caixa"
                                type="radio"
                                value="S"
                                id="op10"
                                ref={register}
                              />
                              <label className="line" htmlFor="op10">
                                Sim
                              </label>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                              <input
                                name="possui_ou_possuiu_financiamento_caixa"
                                type="radio"
                                value="N"
                                id="op11"
                                ref={register}
                              />
                              <label className="line" htmlFor="op11">
                                Não
                              </label>
                            </GridItem>
                          </GridContainer>
                        </FormControl>
                      </td>
                      <td>
                        <b>Se sim quando foi feito o contrato?:</b>{" "}
                        {props.data.se_sim_quando_foi_feito_o_contrato}
                      </td>
                    </tr>
                  </table>
                  <table style={{ marginTop: "100px" }}>
                    <tr>
                      <td colSpan="2">
                        <FormControl className={classes.formControl}>
                          <InputLabel className="radio">
                            Possui ou possuiu financiamento estudantil?
                          </InputLabel>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                              <input
                                name="possui_ou_possuiu_financiamento_estudantil"
                                type="radio"
                                value="S"
                                id="op12"
                                ref={register}
                              />
                              <label className="line" htmlFor="op12">
                                Sim
                              </label>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                              <input
                                name="possui_ou_possuiu_financiamento_estudantil"
                                type="radio"
                                value="N"
                                id="op13"
                                ref={register}
                              />
                              <label className="line" htmlFor="op13">
                                Não
                              </label>
                            </GridItem>
                          </GridContainer>
                        </FormControl>
                      </td>
                      <td>
                        <FormControl className={classes.formControl}>
                          <InputLabel className="radio">
                            Se possui, existe alguma parcela em atraso?
                          </InputLabel>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                              <input
                                name="se_possui,_existe_alguma_parcela_em_atraso"
                                type="radio"
                                value="S"
                                id="op14"
                                ref={register}
                              />
                              <label className="line" htmlFor="op14">
                                Sim
                              </label>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                              <input
                                name="se_possui,_existe_alguma_parcela_em_atraso"
                                type="radio"
                                value="N"
                                id="op15"
                                ref={register}
                              />
                              <label className="line" htmlFor="op15">
                                Não
                              </label>
                            </GridItem>
                          </GridContainer>
                        </FormControl>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <FormControl className={classes.formControl}>
                          <InputLabel className="radio">
                            Possui veiculo ou moto?
                          </InputLabel>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                              <input
                                name="possui_veiculo_ou_moto"
                                type="radio"
                                value="S"
                                id="op16"
                                ref={register}
                              />
                              <label className="line" htmlFor="op16">
                                Sim
                              </label>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                              <input
                                name="possui_veiculo_ou_moto"
                                type="radio"
                                value="N"
                                id="op17"
                                ref={register}
                              />
                              <label className="line" htmlFor="op17">
                                Não
                              </label>
                            </GridItem>
                          </GridContainer>
                        </FormControl>
                      </td>
                      <td>
                        <FormControl className={classes.formControl}>
                          <InputLabel className="radio">
                            Se sim, está fianciado?
                          </InputLabel>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={4}>
                              <input
                                name="se_sim_esta_fianciado"
                                type="radio"
                                value="S"
                                id="op18"
                                ref={register}
                              />
                              <label className="line" htmlFor="op18">
                                Sim
                              </label>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                              <input
                                name="se_sim_esta_fianciado"
                                type="radio"
                                value="N"
                                id="op19"
                                ref={register}
                              />
                              <label className="line" htmlFor="op19">
                                Não
                              </label>
                            </GridItem>
                          </GridContainer>
                        </FormControl>
                      </td>
                      <td>
                        <b>
                          Valor do veiculo ou moto:{" "}
                          {props.data.valor_do_veiculo_ou_moto}
                        </b>
                      </td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td>
                        <FormControl className={classes.formControl}>
                          <InputLabel className="radio">
                            Possui conta corrente?
                          </InputLabel>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                              <input
                                name="possui_conta_corrente"
                                type="radio"
                                value="S"
                                id="op20"
                                ref={register}
                              />
                              <label className="line" htmlFor="op20">
                                Sim
                              </label>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                              <input
                                name="possui_conta_corrente"
                                type="radio"
                                value="N"
                                id="op21"
                                ref={register}
                              />
                              <label className="line" htmlFor="op21">
                                Não
                              </label>
                            </GridItem>
                          </GridContainer>
                        </FormControl>
                      </td>
                      <td>
                        <FormControl className={classes.formControl}>
                          <InputLabel className="radio">
                            Possui cheque especial?
                          </InputLabel>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                              <input
                                name="possui_cheque_especial"
                                type="radio"
                                value="S"
                                id="op22"
                                ref={register}
                              />
                              <label className="line" htmlFor="op22">
                                Sim
                              </label>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                              <input
                                name="possui_cheque_especial"
                                type="radio"
                                value="N"
                                id="op23"
                                ref={register}
                              />
                              <label className="line" htmlFor="op23">
                                Não
                              </label>
                            </GridItem>
                          </GridContainer>
                        </FormControl>
                      </td>
                      <td>
                        <b>Valor cheque especial:</b>{" "}
                        {props.data.valor_cheque_especial}
                      </td>
                      <td>
                        <b>Banco:</b> {props.data.banco}
                      </td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td>
                        <FormControl className={classes.formControl}>
                          <InputLabel className="radio">
                            Possui cartão de crédito?
                          </InputLabel>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                              <input
                                name="possui_cartao_de_credito"
                                type="radio"
                                value="S"
                                id="op24"
                                ref={register}
                              />
                              <label className="line" htmlFor="op24">
                                Sim
                              </label>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                              <input
                                name="possui_cartao_de_credito"
                                type="radio"
                                value="N"
                                id="op25"
                                ref={register}
                              />
                              <label className="line" htmlFor="op25">
                                Não
                              </label>
                            </GridItem>
                          </GridContainer>
                        </FormControl>
                      </td>
                      <td>
                        <b>Quantos cartões de créditos?:</b>{" "}
                        {props.data.quantos_cartoes_de_creditos}
                      </td>
                      <td>
                        <b>Valor limite cartão de crédito:</b>{" "}
                        {props.data.valor_limite_cartao_de_credito}
                      </td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td>
                        <FormControl className={classes.formControl}>
                          <InputLabel className="radio">
                            Possui poupança ou outra aplicação financeira?
                          </InputLabel>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                              <input
                                name="possui_poupanca_ou_outra_aplicacao_financeira"
                                type="radio"
                                value="S"
                                id="op26"
                                ref={register}
                              />
                              <label className="line" htmlFor="op26">
                                Sim
                              </label>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                              <input
                                name="possui_poupanca_ou_outra_aplicacao_financeira"
                                type="radio"
                                value="N"
                                id="op27"
                                ref={register}
                              />
                              <label className="line" htmlFor="op27">
                                Não
                              </label>
                            </GridItem>
                          </GridContainer>
                        </FormControl>
                      </td>
                      <td>
                        <b>Valor aplicação financeira:</b>{" "}
                        {props.data.valor_aplicacao_financeira}
                      </td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td>
                        <FormControl className={classes.formControl}>
                          <InputLabel className="radio">
                            Declara imposto de renda?
                          </InputLabel>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                              <input
                                name="declara_imposto_de_renda"
                                type="radio"
                                value="S"
                                id="op28"
                                ref={register}
                              />
                              <label className="line" htmlFor="op28">
                                Sim
                              </label>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                              <input
                                name="declara_imposto_de_renda"
                                type="radio"
                                value="N"
                                id="op29"
                                ref={register}
                              />
                              <label className="line" htmlFor="op29">
                                Não
                              </label>
                            </GridItem>
                          </GridContainer>
                        </FormControl>
                      </td>
                      <td>
                        <FormControl className={classes.formControl}>
                          <InputLabel className="radio">
                            Tem retificadora?
                          </InputLabel>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                              <input
                                name="tem_retificadora"
                                type="radio"
                                value="S"
                                id="op30"
                                ref={register}
                              />
                              <label className="line" htmlFor="op30">
                                Sim
                              </label>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                              <input
                                name="tem_retificadora"
                                type="radio"
                                value="N"
                                id="op31"
                                ref={register}
                              />
                              <label className="line" htmlFor="op31">
                                Não
                              </label>
                            </GridItem>
                          </GridContainer>
                        </FormControl>
                      </td>
                      <td>
                        <FormControl className={classes.formControl}>
                          <InputLabel className="radio">
                            2 proponente: Declara imposto de renda ?
                          </InputLabel>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                              <input
                                name="declara_imposto_de_renda_2_proponente"
                                type="radio"
                                value="S"
                                id="op32"
                                ref={register}
                              />
                              <label className="line" htmlFor="op32">
                                Sim
                              </label>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                              <input
                                name="declara_imposto_de_renda_2_proponente"
                                type="radio"
                                value="N"
                                id="op33"
                                ref={register}
                              />
                              <label className="line" htmlFor="op33">
                                Não
                              </label>
                            </GridItem>
                          </GridContainer>
                        </FormControl>
                      </td>
                      <td>
                        <FormControl className={classes.formControl}>
                          <InputLabel className="radio">
                            2 proponente: Tem retificadora?
                          </InputLabel>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                              <input
                                name="tem_retificadora_2_proponente"
                                type="radio"
                                value="S"
                                id="op34"
                                ref={register}
                              />
                              <label className="line" htmlFor="op34">
                                Sim
                              </label>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                              <input
                                name="tem_retificadora_2_proponente"
                                type="radio"
                                value="N"
                                id="op35"
                                ref={register}
                              />
                              <label className="line" htmlFor="op35">
                                Não
                              </label>
                            </GridItem>
                          </GridContainer>
                        </FormControl>
                      </td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td>
                        <FormControl className={classes.formControl}>
                          <InputLabel className="radio">
                            Pelo menos um dos proponentes comprova 36 meses de
                            FGTS com contrato ativou ou saldo em conta?
                          </InputLabel>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                              <input
                                name="pelo_menos_um_dos_proponentes_comprova_36_meses"
                                type="radio"
                                value="S"
                                id="op36"
                                ref={register}
                              />
                              <label className="line" htmlFor="op36">
                                Sim
                              </label>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                              <input
                                name="pelo_menos_um_dos_proponentes_comprova_36_meses"
                                type="radio"
                                value="N"
                                id="op37"
                                ref={register}
                              />
                              <label className="line" htmlFor="op37">
                                Não
                              </label>
                            </GridItem>
                          </GridContainer>
                        </FormControl>
                      </td>
                      <td>
                        <FormControl className={classes.formControl}>
                          <InputLabel className="radio">
                            1 proponente utilizará o FGTS como recurso proprio?
                          </InputLabel>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                              <input
                                name="1_proponente_utilizara_o_fgts_como_recurso_proprio"
                                type="radio"
                                value="S"
                                id="op38"
                                ref={register}
                              />
                              <label className="line" htmlFor="op38">
                                Sim
                              </label>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                              <input
                                name="1_proponente_utilizara_o_fgts_como_recurso_proprio"
                                type="radio"
                                value="N"
                                id="op39"
                                ref={register}
                              />
                              <label className="line" htmlFor="op39">
                                Não
                              </label>
                            </GridItem>
                          </GridContainer>
                        </FormControl>
                      </td>
                      <td>
                        <FormControl className={classes.formControl}>
                          <InputLabel className="radio">
                            2 proponente utilizará o FGTS como recurso proprio?
                          </InputLabel>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                              <input
                                name="2_proponente_utilizara_o_fgts_como_recurso_proprio"
                                type="radio"
                                value="S"
                                id="op40"
                                ref={register}
                              />
                              <label className="line" htmlFor="op40">
                                Sim
                              </label>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                              <input
                                name="2_proponente_utilizara_o_fgts_como_recurso_proprio"
                                type="radio"
                                value="N"
                                id="op41"
                                ref={register}
                              />
                              <label className="line" htmlFor="op41">
                                Não
                              </label>
                            </GridItem>
                          </GridContainer>
                        </FormControl>
                      </td>
                    </tr>
                  </table>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <h3>Compromissos financeiros do cliente</h3>
                  <h4>1 proponente</h4>
                  <table>
                    <tr>
                      <td>Tipo</td>
                      <td align="left">Valor Parcelas</td>
                      <td align="left">Quantidades Vencer</td>
                    </tr>
                    <tr>
                      <td align="left">Comércio</td>
                      <td align="left">
                        <TextField
                          fullWidth={true}
                          name="1_proponente_comercio_valor_parcela"
                          inputRef={register}
                        />
                      </td>
                      <td align="left">
                        <TextField
                          fullWidth={true}
                          name="1_proponente_comercio_quantidade_a_vencer"
                          inputRef={register}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td align="left">Empréstimo Banco</td>
                      <td align="left">
                        <TextField
                          fullWidth={true}
                          name="1_proponente_emprestimo_banco_valor_parcela"
                          inputRef={register}
                        />
                      </td>
                      <td align="left">
                        <TextField
                          fullWidth={true}
                          name="1_proponente_emprestimo_banco_quantidade_a_vencer"
                          inputRef={register}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td align="left">Empréstimo Contra Cheque</td>
                      <td align="left">
                        <TextField
                          fullWidth={true}
                          name="1_proponente_emprestimo_conta_corrente_valor_parcela"
                          inputRef={register}
                        />
                      </td>
                      <td align="left">
                        <TextField
                          fullWidth={true}
                          name="1_proponente_emprestimo_conta_corrente_quantidade_a_vencer"
                          inputRef={register}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td align="left">Financiamento estudantil</td>
                      <td align="left">
                        <TextField
                          fullWidth={true}
                          name="1_proponente_financiamento_estudantil_valor_parcela"
                          inputRef={register}
                        />
                      </td>
                      <td align="left">
                        <TextField
                          fullWidth={true}
                          name="1_proponente_financiamento_estudantil_quantidade_a_vencer"
                          inputRef={register}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td align="left">Financiamento habitacional</td>
                      <td align="left">
                        <TextField
                          fullWidth={true}
                          name="1_proponente_financiamento_habitacional_valor_parcela"
                          inputRef={register}
                        />
                      </td>
                      <td align="left">
                        <TextField
                          fullWidth={true}
                          name="1_proponente_financiamento_habitacional_quantidade_a_vencer"
                          inputRef={register}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td align="left">Financiamento veículo</td>
                      <td align="left">
                        <TextField
                          fullWidth={true}
                          name="1_proponente_financiamento_veiculo_valor_parcela"
                          inputRef={register}
                        />
                      </td>
                      <td align="left">
                        <TextField
                          fullWidth={true}
                          name="1_proponente_financiamento_veiculo_quantidade_a_vencer"
                          inputRef={register}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td align="left">Pensão alimentícia</td>
                      <td align="left">
                        <TextField
                          fullWidth={true}
                          name="1_proponente_pensao_alimenticia_valor_parcela"
                          inputRef={register}
                        />
                      </td>
                      <td align="left">
                        <TextField
                          fullWidth={true}
                          name="1_proponente_pensao_alimenticia_quantidade_a_vencer"
                          inputRef={register}
                        />
                      </td>
                    </tr>
                  </table>
                  <h4>2 proponente</h4>
                  <table>
                    <tr>
                      <td>Tipo</td>
                      <td align="left">Valor Parcelas</td>
                      <td align="left">Quantidades Vencer</td>
                    </tr>
                    <tr>
                      <td align="left">Comércio</td>
                      <td align="left">
                        <TextField
                          fullWidth={true}
                          name="2_proponente_comercio_valor_parcela"
                          inputRef={register}
                        />
                      </td>
                      <td align="left">
                        <TextField
                          fullWidth={true}
                          name="2_proponente_comercio_quantidade_a_vencer"
                          inputRef={register}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td align="left">Empréstimo Banco</td>
                      <td align="left">
                        <TextField
                          fullWidth={true}
                          name="2_proponente_emprestimo_banco_valor_parcela"
                          inputRef={register}
                        />
                      </td>
                      <td align="left">
                        <TextField
                          fullWidth={true}
                          name="2_proponente_emprestimo_banco_quantidade_a_vencer"
                          inputRef={register}
                        />
                      </td>
                    </tr>
                  </table>
                  <table style={{ marginTop: "80px" }}>
                    <tr>
                      <td align="left">Empréstimo Contra Cheque</td>
                      <td align="left">
                        <TextField
                          fullWidth={true}
                          name="2_proponente_emprestimo_conta_corrente_valor_parcela"
                          inputRef={register}
                        />
                      </td>
                      <td align="left">
                        <TextField
                          fullWidth={true}
                          name="2_proponente_emprestimo_conta_corrente_quantidade_a_vencer"
                          inputRef={register}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td align="left">Financiamento estudantil</td>
                      <td align="left">
                        <TextField
                          fullWidth={true}
                          name="2_proponente_financiamento_estudantil_valor_parcela"
                          inputRef={register}
                        />
                      </td>
                      <td align="left">
                        <TextField
                          fullWidth={true}
                          name="2_proponente_financiamento_estudantil_quantidade_a_vencer"
                          inputRef={register}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td align="left">Financiamento habitacional</td>
                      <td align="left">
                        <TextField
                          fullWidth={true}
                          name="2_proponente_financiamento_habitacional_valor_parcela"
                          inputRef={register}
                        />
                      </td>
                      <td align="left">
                        <TextField
                          fullWidth={true}
                          name="2_proponente_financiamento_habitacional_quantidade_a_vencer"
                          inputRef={register}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td align="left">Financiamento veículo</td>
                      <td align="left">
                        <TextField
                          fullWidth={true}
                          name="2_proponente_financiamento_veiculo_valor_parcela"
                          inputRef={register}
                        />
                      </td>
                      <td align="left">
                        <TextField
                          fullWidth={true}
                          name="2_proponente_financiamento_veiculo_quantidade_a_vencer"
                          inputRef={register}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td align="left">Pensão alimentícia</td>
                      <td align="left">
                        <TextField
                          fullWidth={true}
                          name="2_proponente_pensao_alimenticia_valor_parcela"
                          inputRef={register}
                        />
                      </td>
                      <td align="left">
                        <TextField
                          fullWidth={true}
                          name="2_proponente_pensao_alimenticia_quantidade_a_vencer"
                          inputRef={register}
                        />
                      </td>
                    </tr>
                  </table>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <h3>2 Proponente - Dados Pessoais</h3>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <table>
                    <tr>
                      <td>
                        <b>Nome:</b> {props.data.sec_nome}
                      </td>
                      <td>
                        <FormControl className={classes.formControl}>
                          <InputLabel className="radio">Sexo</InputLabel>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                              <input
                                name="sec_sexo"
                                type="radio"
                                value="M"
                                id="op1"
                                ref={register}
                              />
                              <label className="line" htmlFor="op1">
                                M
                              </label>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                              <input
                                name="sec_sexo"
                                type="radio"
                                value="F"
                                id="op2"
                                ref={register}
                              />
                              <label className="line" htmlFor="op2">
                                F
                              </label>
                            </GridItem>
                          </GridContainer>
                        </FormControl>
                      </td>
                      <td>
                        <b>Data nasc.:</b>{" "}
                        {FormatDateBR(props.data.sec_data_nasc)}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Estado civil:</b> {props.data.sec_estado_civil}
                      </td>
                      <td>
                        <b>Data casamento:</b>{" "}
                        {FormatDateBR(props.data.sec_data_casamento)}
                      </td>
                      <td>
                        <b>Regime casamento:</b>{" "}
                        {props.data.sec_regime_casamento}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Rg:</b> {props.data.sec_rg}
                      </td>
                      <td>
                        <b>Data expedição:</b>{" "}
                        {FormatDateBR(props.data.sec_data_expedicao)}
                      </td>
                      <td>
                        <b>Orgão expeditor:</b> {props.data.sec_orgao_expeditor}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>CPF:</b> {props.data.sec_cpf}
                      </td>
                      <td>
                        <b>Nacionalidade:</b> {props.data.sec_nascionalidade}
                      </td>
                      <td>
                        <b>Naturalidade:</b> {props.data.sec_naturalidade}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <b>Endereço:</b> {props.data.sec_endereco}
                      </td>
                      <td>
                        <b>CEP:</b> {props.data.sec_cep}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Bairro:</b> {props.data.sec_bairro}
                      </td>
                      <td>
                        <b>Cidade:</b> {props.data.sec_cidade}
                      </td>
                      <td>
                        <b>Estado:</b> {props.data.sec_estado}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <b>Tempo de domicílio (anos):</b>{" "}
                        {props.data.sec_tempo_de_domicilio}
                      </td>
                      <td>
                        <b>Email:</b> {props.data.sec_email}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Fone residencial:</b>{" "}
                        {props.data.sec_fone_residencial}
                      </td>
                      <td>
                        <b>Celular:</b> {props.data.sec_celular}
                      </td>
                      <td>
                        <b>Outros:</b> {props.data.sec_outros}
                      </td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td>
                        <b>Nome da mãe:</b> {props.data.sec_nome_da_mae}
                      </td>
                      <td>
                        <b>Nome do pai:</b> {props.data.sec_nome_do_pai}
                      </td>
                    </tr>
                  </table>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <h3>Dados profissionais / comercial</h3>
                  <table>
                    <tr>
                      <td colSpan="2">
                        <b>Empresa:</b> {props.data.sec_empresa}
                      </td>
                      <td>
                        <b>CNPJ:</b> {props.data.sec_cnpj}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <b>Endereço:</b> {props.data.sec_endereco_emp}
                      </td>
                      <td>
                        <b>CEP:</b> {props.data.sec_cep_emp}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Bairro:</b> {props.data.sec_bairro_emp}
                      </td>
                      <td>
                        <b>Cidade:</b> {props.data.sec_cidade_emp}
                      </td>
                      <td>
                        <b>Estado:</b> {props.data.sec_estado_emp}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Fone comercial:</b> {props.data.sec_fone_comercial}
                      </td>
                      <td>
                        <b>Cargo atual:</b> {props.data.sec_cargo_atual}
                      </td>
                      <td>
                        <b>Profissão:</b> {props.data.sec_profissao}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <b>Tempo de empresa:</b>{" "}
                        {props.data.sec_tempo_de_empresa}
                      </td>
                      <td>
                        <b>E-mail emp.:</b> {props.data.sec_email_emp}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Salário bruto:</b> {props.data.sec_salario_bruto}
                      </td>
                      <td>
                        <b>Salário liquido:</b> {props.data.sec_salario_liquido}
                      </td>
                      <td>
                        <b>FGTS:</b> {props.data.sec_fgts}
                      </td>
                    </tr>
                  </table>
                  <GridItem xs={12} sm={12} md={12}>
                    <h3>Outros Rendimentos</h3>
                  </GridItem>
                  <table>
                    <tr>
                      <td>
                        <b>Renda informal:</b> {props.data.sec_renda_informal}
                      </td>
                      <td>
                        <b>Aluguel:</b> {props.data.sec_aluguel}
                      </td>
                      <td>
                        <b>Aposentadoria:</b> {props.data.sec_aposentadoria}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Decore:</b> {props.data.sec_decore}
                      </td>
                      <td>
                        <b>Faturamento:</b> {props.data.sec_faturamento}
                      </td>
                      <td>
                        <b>Quantidade sócios:</b>{" "}
                        {props.data.sec_quantidade_socios}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3">
                        <b>Outras rendas:</b> {props.data.sec_outras_rendas}
                      </td>
                    </tr>
                  </table>
                </GridItem>
              </GridContainer>
            </CardBody>
          </form>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

FormImp.propTypes = {
  data: PropTypes.object.isRequired,
  sua_residencia_items: PropTypes.object.isRequired
}

export default FormImp
