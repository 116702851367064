import axios from "axios"
import { API_URL, AUTH_TOKEN, LOGIN } from "../../../setup/store"
import { history } from "../../../index"

// select
// import { s } from "../..//api/actions"

export const SERVICES_FETCH = "SERVICES_FETCH"
export const SERVICES_FETCHING = "SERVICES_FETCHING"

export const NEW_SERVICE = "NEW_SERVICE"
export const SERVICE_FETCH = "SERVICE_FETCH"
export const SERVICE_CLEAR = "SERVICE_CLEAR"

export const SERVICE_SUBMITTING = "SERVICE_SUBMITTING"
export const SERVICE_SUBMIT = "SERVICE_SUBMIT"
export const SERVICE_SUBMIT_ERROR = "SERVICE_SUBMIT_ERROR"

const URL_REDIRECT = "/admin/services"
const API_TYPE = "services"

export const Services = () => {
  return async dispatch => {
    dispatch({
      type: SERVICES_FETCHING
    })
    try {
      const res = await axios.get(`${API_URL}${API_TYPE}`, {
        headers: {
          Authorization: AUTH_TOKEN
        }
      })

      dispatch({
        type: SERVICES_FETCH,
        data: res.data
      })
    } catch (error) {
      window.location.href = LOGIN
      console.error(error)
    }
  }
}

export const Service = id => {
  return async dispatch => {
    // select
    //dispatch(())

    if (id) {
      try {
        const res = await axios.get(`${API_URL}${API_TYPE}/${id}`, {
          headers: {
            Authorization: AUTH_TOKEN
          }
        })
        dispatch({
          type: SERVICE_FETCH,
          data: res.data
        })
      } catch (error) {
        window.location.href = LOGIN
        console.log(error.message)
      }
    } else {
      dispatch({
        type: NEW_SERVICE
      })
    }
  }
}

export const ServicesCleanSubmit = () => {
  return async dispatch => {
    dispatch({
      type: SERVICE_SUBMITTING
    })
  }
}

export const ServicesCleanForm = () => {
  return async dispatch => {
    dispatch({
      type: SERVICE_CLEAR
    })
  }
}

export const ServicesDelete = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: SERVICE_SUBMITTING
    })
    const ID = getState().Service.data.id
    try {
      const res = await axios.delete(`${API_URL}${API_TYPE}/${ID}`, {
        headers: {
          Authorization: AUTH_TOKEN
        }
      })
      dispatch({
        type: SERVICE_SUBMIT,
        data: res.data
      })
      history.push(URL_REDIRECT)
    } catch (error) {
      console.log(error.message)
    }
  }
}

export const ServicesSubmit = services => {
  return async dispatch => {
    dispatch({
      type: SERVICE_SUBMITTING
    })
    try {
      let res

      if (services.id) {
        res = await axios.put(
          `${API_URL}${API_TYPE}/${services.id}`,
          services,
          {
            headers: {
              Authorization: AUTH_TOKEN
            }
          }
        )
      } else {
        res = await axios.post(`${API_URL}${API_TYPE}/`, services, {
          headers: {
            Authorization: AUTH_TOKEN
          }
        })
      }

      if (services.imgFile) {
        const form_data = new FormData()
        const API_TIME = Date.now()
        form_data.append("time", API_TIME)
        form_data.append("imgFile", services.imgFile)
        form_data.append("id", services.id ? services.id : res.data.reg.id)
        await axios.post(`${API_URL}${API_TYPE}/files/${API_TIME}`, form_data, {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: AUTH_TOKEN
          }
        })
      }

      dispatch({
        type: SERVICE_SUBMIT,
        data: res.data
      })
      history.push(URL_REDIRECT)
    } catch (error) {
      dispatch({
        type: SERVICE_SUBMIT_ERROR,
        error: "Este nome já foi cadastrado, Por favor escolha um diferente..."
      })
    }
  }
}
