import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"
import { StatusCleanSubmit, StatusCleanForm, Statu } from "./api/actions"

import Form from "./Form"

const StatusForm = props => {
  const dispatch = useDispatch()
  const StatusSt = useSelector(state => state.Statu)

  useEffect(() => {
    dispatch(StatusCleanSubmit())
    dispatch(Statu(props.match.params.id))

    return () => dispatch(StatusCleanForm())
  }, [dispatch, props.match.params.id])

  return (
    <React.Fragment>
      {StatusSt.data.length === 0 ? "" : <Form data={StatusSt.data} />}
    </React.Fragment>
  )
}

StatusForm.propTypes = {
  match: PropTypes.object
}

export default StatusForm
