import {
  REAL_ESTATE_FETCH,
  REAL_ESTATE_CLEAR,
  NEW_REAL_ESTATE,
  REAL_ESTATES_FETCH,
  REAL_ESTATES_FETCHING,
  REAL_ESTATE_SUBMITTING,
  REAL_ESTATE_SUBMIT,
  REAL_ESTATE_SUBMIT_ERROR,
  ATT_REAL_ESTATE_FETCH,
  SERVICE_REAL_ESTATE_FETCH
} from "./actions"

const initialState = {
  data: [],
  loading: false
}
export function Real_estate(state = initialState, action = {}) {
  switch (action.type) {
    case REAL_ESTATE_FETCH:
      return Object.assign({}, state, {
        data: action.data
      })
    case NEW_REAL_ESTATE:
      return Object.assign({}, state, {
        data: {
          id: null
        }
      })
    case REAL_ESTATE_CLEAR:
      return Object.assign({}, state, initialState)
    default:
      return state
  }
}

export function Real_estates(state = initialState, action = {}) {
  switch (action.type) {
    case REAL_ESTATES_FETCH:
      return Object.assign({}, state, {
        data: action.data,
        loading: false
      })
    case REAL_ESTATES_FETCHING:
      return Object.assign({}, state, {
        data: [],
        loading: true
      })
    default:
      return state
  }
}

export function Att_Real_estates(state = initialState, action = {}) {
  switch (action.type) {
    case ATT_REAL_ESTATE_FETCH:
      return Object.assign({}, state, {
        data: action.data,
        loading: false
      })
    default:
      return state
  }
}

export function Service_Real_estates(state = initialState, action = {}) {
  switch (action.type) {
    case SERVICE_REAL_ESTATE_FETCH:
      return Object.assign({}, state, {
        data: action.data,
        loading: false
      })
    default:
      return state
  }
}


const initialStateReg = {
  data: [],
  error: []
}
export function Real_estatesReg(state = initialStateReg, action = {}) {
  switch (action.type) {
    case REAL_ESTATE_SUBMITTING:
      return Object.assign({}, state, initialStateReg)
    case REAL_ESTATE_SUBMIT:
      return Object.assign({}, state, {
        data: action.data,
        error: []
      })
    case REAL_ESTATE_SUBMIT_ERROR:
      return Object.assign({}, state, {
        data: [],
        error: action.error
      })
    default:
      return state
  }
}
