import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"
import { BrokersCleanSubmit, BrokersCleanForm, Broker } from "./api/actions"

import Form from "./Form"

const BrokersForm = props => {
  const dispatch = useDispatch()
  const BrokersSt = useSelector(state => state.Broker)

  useEffect(() => {
    dispatch(BrokersCleanSubmit())
    dispatch(Broker(props.match.params.id))

    return () => dispatch(BrokersCleanForm())
  }, [dispatch, props.match.params.id])

  return (
    <React.Fragment>
      {BrokersSt.data.length === 0 ? "" : <Form data={BrokersSt.data} />}
    </React.Fragment>
  )
}

BrokersForm.propTypes = {
  match: PropTypes.object
}

export default BrokersForm
