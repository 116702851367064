export const options = {
  exportButton: true,
  filtering: true,
  search: true,
  pageSizeOptions: [5, 20, 50, 150]
}
export const lang = {
  pagination: {
    labelDisplayedRows: "{from}-{to} de {count}",
    labelRowsSelect: "linhas",
    nextTooltip: "Próx. Pag.",
    previousTooltip: "Pag. Anterior",
    firstTooltip: "Início",
    lastTooltip: "Final"
  },
  toolbar: {
    nRowsSelected: "{0} linhas(s) Selecionadas",
    searchPlaceholder: "Busca Geral",
    searchTooltip: "Busca",
    exportTitle: "Exportar",
    exportName: "Exportar CSV"
  },
  header: {
    actions: "Ações"
  },
  body: {
    emptyDataSourceMessage: "Nenhum registro encontrado...",
    filterRow: {
      filterTooltip: "Filtro"
    }
  }
}

export const imgTable = {
  maxWidth: "200px",
  maxHeight: "200px"
};