import axios from "axios"
import { API_URL, AUTH_TOKEN, LOGIN } from "../../../setup/store"
import { history } from "../../../index"

// select
// import { s } from "../..//api/actions"

export const ATTRIBUTES_FETCH = "ATTRIBUTES_FETCH"
export const ATTRIBUTES_FETCHING = "ATTRIBUTES_FETCHING"

export const NEW_ATTRIBUTE = "NEW_ATTRIBUTE"
export const ATTRIBUTE_FETCH = "ATTRIBUTE_FETCH"
export const ATTRIBUTE_CLEAR = "ATTRIBUTE_CLEAR"

export const ATTRIBUTE_SUBMITTING = "ATTRIBUTE_SUBMITTING"
export const ATTRIBUTE_SUBMIT = "ATTRIBUTE_SUBMIT"
export const ATTRIBUTE_SUBMIT_ERROR = "ATTRIBUTE_SUBMIT_ERROR"

const URL_REDIRECT = "/admin/attributes"
const API_TYPE = "attributes"

export const Attributes = () => {
  return async dispatch => {
    dispatch({
      type: ATTRIBUTES_FETCHING
    })
    try {
      const res = await axios.get(`${API_URL}${API_TYPE}`, {
        headers: {
          Authorization: AUTH_TOKEN
        }
      })

      dispatch({
        type: ATTRIBUTES_FETCH,
        data: res.data
      })
    } catch (error) {
      window.location.href = LOGIN
      console.error(error)
    }
  }
}

export const Attribute = id => {
  return async dispatch => {
    // select
    //dispatch(())

    if (id) {
      try {
        const res = await axios.get(`${API_URL}${API_TYPE}/${id}`, {
          headers: {
            Authorization: AUTH_TOKEN
          }
        })
        dispatch({
          type: ATTRIBUTE_FETCH,
          data: res.data
        })
      } catch (error) {
        window.location.href = LOGIN
        console.log(error.message)
      }
    } else {
      dispatch({
        type: NEW_ATTRIBUTE
      })
    }
  }
}

export const AttributesCleanSubmit = () => {
  return async dispatch => {
    dispatch({
      type: ATTRIBUTE_SUBMITTING
    })
  }
}

export const AttributesCleanForm = () => {
  return async dispatch => {
    dispatch({
      type: ATTRIBUTE_CLEAR
    })
  }
}

export const AttributesDelete = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: ATTRIBUTE_SUBMITTING
    })
    const ID = getState().Attribute.data.id
    try {
      const res = await axios.delete(`${API_URL}${API_TYPE}/${ID}`, {
        headers: {
          Authorization: AUTH_TOKEN
        }
      })
      dispatch({
        type: ATTRIBUTE_SUBMIT,
        data: res.data
      })
      history.push(URL_REDIRECT)
    } catch (error) {
      console.log(error.message)
    }
  }
}

export const AttributesSubmit = attributes => {
  return async dispatch => {
    dispatch({
      type: ATTRIBUTE_SUBMITTING
    })
    try {
      let res

      if (attributes.id) {
        res = await axios.put(
          `${API_URL}${API_TYPE}/${attributes.id}`,
          attributes,
          {
            headers: {
              Authorization: AUTH_TOKEN
            }
          }
        )
      } else {
        res = await axios.post(`${API_URL}${API_TYPE}/`, attributes, {
          headers: {
            Authorization: AUTH_TOKEN
          }
        })
      }

      if (attributes.imgFile) {
        const form_data = new FormData()
        const API_TIME = Date.now()
        form_data.append("time", API_TIME)
        form_data.append("imgFile", attributes.imgFile)
        form_data.append("id", attributes.id ? attributes.id : res.data.reg.id)
        await axios.post(`${API_URL}${API_TYPE}/files/${API_TIME}`, form_data, {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: AUTH_TOKEN
          }
        })
      }

      dispatch({
        type: ATTRIBUTE_SUBMIT,
        data: res.data
      })
      history.push(URL_REDIRECT)
    } catch (error) {
      dispatch({
        type: ATTRIBUTE_SUBMIT_ERROR,
        error: "Este nome já foi cadastrado, Por favor escolha um diferente..."
      })
    }
  }
}
