import {
  LEAD_FETCH,
  LEAD_CLEAR,
  NEW_LEAD,
  LEADS_FETCH,
  LEADS_FETCHING,
  LEAD_SUBMITTING,
  LEAD_SUBMIT,
  LEAD_SUBMIT_ERROR
} from "./actions"

const initialState = {
  data: [],
  loading: false
}
export function Lead(state = initialState, action = {}) {
  switch (action.type) {
    case LEAD_FETCH:
      return Object.assign({}, state, {
        data: action.data
      })
    case NEW_LEAD:
      return Object.assign({}, state, {
        data: {
          id: null
        }
      })
    case LEAD_CLEAR:
      return Object.assign({}, state, initialState)
    default:
      return state
  }
}

export function Leads(state = initialState, action = {}) {
  switch (action.type) {
    case LEADS_FETCH:
      return Object.assign({}, state, {
        data: action.data,
        loading: false
      })
    case LEADS_FETCHING:
      return Object.assign({}, state, {
        data: [],
        loading: true
      })
    default:
      return state
  }
}

const initialStateReg = {
  data: [],
  error: []
}
export function LeadsReg(state = initialStateReg, action = {}) {
  switch (action.type) {
    case LEAD_SUBMITTING:
      return Object.assign({}, state, initialStateReg)
    case LEAD_SUBMIT:
      return Object.assign({}, state, {
        data: action.data,
        error: []
      })
    case LEAD_SUBMIT_ERROR:
      return Object.assign({}, state, {
        data: [],
        error: action.error
      })
    default:
      return state
  }
}
