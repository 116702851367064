import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"
import { userCleanSubmit, userCleanForm, user } from "./api/actions"

import Form from "./Form"

const User = props => {
  const dispatch = useDispatch()
  const userSt = useSelector(state => state.user)

  useEffect(() => {
    dispatch(userCleanSubmit())
    dispatch(user(props.match.params.id))

    return () => dispatch(userCleanForm())
  }, [dispatch, props.match.params.id])

  return (
    <React.Fragment>
      {userSt.data.length === 0 ? "" : <Form data={userSt.data} />}
    </React.Fragment>
  )
}

User.propTypes = {
  match: PropTypes.object
}

export default User
