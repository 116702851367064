import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import PropTypes from "prop-types"

import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"

import { useSelector, useDispatch } from "react-redux"
// eslint-disable-next-line no-unused-vars
import { Attributes_real_estateSubmit } from "./api/actions"

import CardHeader from "components/Card/CardHeader"
import CardBody from "components/Card/CardBody"
import CardFooter from "components/Card/CardFooter"
import Button from "components/CustomButtons/Button"
import GridItem from "components/Grid/GridItem"
import GridContainer from "components/Grid/GridContainer"
import Card from "components/Card/Card"
import SnackbarContent from "components/Snackbar/SnackbarContent"

const FormAttr = props => {
  const { handleSubmit } = useForm({})
  const dispatch = useDispatch()
  const AttributesSt = useSelector(state => state.Attributes)
  const Real_estatesReg = useSelector(state => state.Real_estatesReg)
  const Att_Real_estatesSt = useSelector(state => state.Att_Real_estates)

  const onSubmit = async values => {
    values.attr = attr
    values.real_estate_id = props.id
    dispatch(Attributes_real_estateSubmit(values))
  }

  const [attr, setAttr] = React.useState({})
  const handleChangeChecksetAttr = name => event => {
    setAttr({ ...attr, [name]: event.target.checked })
  }

  useEffect(() => {
    let jsonAttr = {}
    Att_Real_estatesSt.data.map(item => {
      jsonAttr[item.attribute_id] = true
    })
    setAttr(jsonAttr)
  }, [Att_Real_estatesSt])

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <form onSubmit={handleSubmit(onSubmit)}>
            <CardHeader color="primary">
              <h3>Atributos</h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {Real_estatesReg.data.res ? (
                  <GridItem xs={12} sm={12} md={12}>
                    <SnackbarContent
                      message={Real_estatesReg.data.res}
                      color="success"
                    />
                  </GridItem>
                ) : (
                  ""
                )}
                <GridItem xs={12} sm={12} md={12}>
                  {AttributesSt.data.map((item, i) => {
                    return (
                      <FormControlLabel
                        key={i}
                        control={
                          <Checkbox
                            checked={attr[item.id] ? true : false}
                            onChange={handleChangeChecksetAttr(item.id)}
                            color="primary"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                          />
                        }
                        label={item.name}
                      />
                    )
                  })}
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button type="submit" color="primary">
                Salvar
              </Button>
            </CardFooter>
          </form>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

FormAttr.propTypes = {
  id: PropTypes.string
}

// FormAttr.propTypes = {
//   data: PropTypes.object.isRequired
// }

export default FormAttr
