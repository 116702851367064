import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import InputLabel from "@material-ui/core/InputLabel"
import FormControl from "@material-ui/core/FormControl"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"

import { useSelector, useDispatch } from "react-redux"
// eslint-disable-next-line no-unused-vars
import {
  Related_real_estateSubmit,
  Related_real_estateDelete
} from "./api/actions"

import CardHeader from "components/Card/CardHeader"
import CardBody from "components/Card/CardBody"
import CardFooter from "components/Card/CardFooter"
import SnackbarContent from "components/Snackbar/SnackbarContent"
import Button from "components/CustomButtons/Button"
import GridItem from "components/Grid/GridItem"
import GridContainer from "components/Grid/GridContainer"
import Card from "components/Card/Card"

const styles = {
  formControl: {
    marginTop: "15px",
    minWidth: "100%"
  }
}
const useStyles = makeStyles(styles)

const Form = props => {
  const classes = useStyles()
  const Related_real_estateReg = useSelector(
    state => state.Related_real_estateReg
  )
  const { register, handleSubmit, setValue } = useForm({
    defaultValues: props.data
  })
  const dispatch = useDispatch()
  const Real_estatesSt = useSelector(state => state.Real_estates)
  const Real_estateSt = useSelector(state => state.Real_estate)

  const onSubmit = async values => {
    dispatch(Related_real_estateSubmit(values))
  }

  const onDelete = () => {
    if (window.confirm("Confirma a exclusão do registro?"))
      dispatch(Related_real_estateDelete())
  }

  // select real_estate
  const [real_estateVal, setReal_estateVal] = React.useState(
    props.data.id ? props.data.related : ""
  )
  const handleChangeReal_estate = e => {
    setValue("related", e.target.value)
    setReal_estateVal(e.target.value)
  }
  useEffect(() => {
    register({ name: "related", required: true })
  }, [register])

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <form onSubmit={handleSubmit(onSubmit)}>
            <CardHeader color="primary">
              <h3>
                {Real_estateSt.data.name} {" > "}
                {props.data.id ? "Editando Registro" : "Novo registro"}
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {Related_real_estateReg.error.length !== 0 ? (
                  <GridItem xs={12} sm={12} md={12}>
                    <SnackbarContent
                      message={Related_real_estateReg.error}
                      color="danger"
                    />
                  </GridItem>
                ) : (
                  ""
                )}
                {props.data.id ? (
                  <input
                    type="hidden"
                    name="id"
                    ref={register}
                    value={props.data.id}
                  />
                ) : (
                  ""
                )}
                <input
                  type="hidden"
                  name="real_estate_id"
                  ref={register}
                  value={Real_estateSt.data.id}
                />
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel>Imóvel relacionado</InputLabel>
                    {Real_estatesSt.data.length === 0 ? (
                      ""
                    ) : (
                      <Select
                        autoWidth={true}
                        fullWidth={true}
                        labelWidth={150}
                        value={real_estateVal}
                        onChange={handleChangeReal_estate}
                      >
                        {Real_estatesSt.data.map((item, i) => {
                          if (item.id !== Real_estateSt.data.id) {
                            return (
                              <MenuItem value={item.id} key={i}>
                                {item.cod} - {item.name}
                              </MenuItem>
                            )
                          }
                        })}
                      </Select>
                    )}
                  </FormControl>
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button type="submit" color="primary">
                Salvar
              </Button>
              {props.data.id ? (
                <Button color="danger" onClick={() => onDelete()}>
                  Deletar
                </Button>
              ) : (
                ""
              )}
            </CardFooter>
          </form>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

Form.propTypes = {
  data: PropTypes.object.isRequired
}

export default Form
