import React, { useEffect, useRef } from "react"
import { Switch, Route, Redirect } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import { withRouter } from "react-router-dom"

import Navbar from "components/Navbars/Navbar"
import Footer from "components/Footer/Footer"
import Sidebar from "components/Sidebar/Sidebar"
import { useSelector, useDispatch } from "react-redux"

import { user } from "../views/Login/api/actions"
import routes from "routes"
import routes_type from "routes_type"
import styles from "assets/jss/material-dashboard-react/layouts/adminStyle"

import bgImage from "assets/img/sidebar-2.jpg"
import logo from "assets/img/logo.png"

const switchRoutesType = (
  <Switch>
    {routes_type.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            key={key}
            path={prop.layout + prop.path}
            component={prop.component}
          />
        )
      }
      return null
    })}
    {routes_type.map(item => {
      if (item.layout === "/admin") {
        return item.children.map((sub, k) => (
          <Route
            key={k}
            path={sub.layout + sub.path}
            component={sub.component}
          />
        ))
      }
      return null
    })}
    <Redirect from="/admin" to="/admin/dashboard" />
  </Switch>
)

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            key={key}
            path={prop.layout + prop.path}
            component={prop.component}
          />
        )
      }
      return null
    })}
    {routes.map(item => {
      if (item.layout === "/admin") {
        return item.children.map((sub, k) => (
          <Route
            key={k}
            path={sub.layout + sub.path}
            component={sub.component}
          />
        ))
      }
      return null
    })}
    <Redirect from="/admin" to="/admin/dashboard" />
  </Switch>
)

const useStyles = makeStyles(styles)

const Admin = ({ ...rest }) => {
  const classes = useStyles()
  const mainPanel = React.createRef()
  const dispatch = useDispatch()
  const [image] = React.useState(bgImage)
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const token = localStorage.getItem("token")
  const page = useRef(null)
  const userSt = useSelector(state => state.user_logged)

  const handleDrawerToggle = () => {
    if (window.innerWidth < 960) {
      setMobileOpen(!mobileOpen)
    }
  }

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false)
    }
  }

  useEffect(() => {
    dispatch(user())
  }, [dispatch])

  useEffect(() => {
    page.current.offsetParent.scrollTop = 0
  })

  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      document.body.style.overflow = "hidden"
    }
    window.addEventListener("resize", resizeFunction)

    return function cleanup() {
      window.removeEventListener("resize", resizeFunction)
    }
  }, [mainPanel])

  console.log(userSt);
  

  return (
    <React.Fragment>
      {!token ? (
        ""
      ) : (
        <div className={classes.wrapper}>
          <Sidebar
            routes={userSt.data.type_user_id === "1" ? routes : routes_type}
            logoText={"Ayache"}
            logo={logo}
            image={image}
            handleDrawerToggle={handleDrawerToggle}
            open={mobileOpen}
            {...rest}
          />
          <div className={classes.mainPanel} ref={mainPanel}>
            <Navbar
              routes={userSt.data.type_user_id ===  "1"  ? routes : routes_type}
              handleDrawerToggle={handleDrawerToggle}
              {...rest}
            />
            <div className={classes.content}>
              <div className={classes.container} ref={page}>
                {userSt.data.type_user_id === "1" 
                  ? switchRoutes
                  : switchRoutesType}
              </div>
            </div>
            <Footer />
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default withRouter(Admin)
