import {
  AUTHENTICATED_USER,
  UNAUTHENTICATED_USER,
  AUTHENTICATED_USER_DETAILS
} from "./actions"

const initialState = {
  authenticated: false,
  error: false
}

export function auth(state = initialState, action = {}) {
  switch (action.type) {
    case AUTHENTICATED_USER:
      return Object.assign({}, state, {
        authenticated: true,
        error: false
      })
    case UNAUTHENTICATED_USER:
      return Object.assign({}, state, {
        authenticated: false,
        error: true
      })
    default:
      return state
  }
}

const initialStateLog = {
  data: [],
  loading: false
}
export function user_logged(state = initialStateLog, action = {}) {
  switch (action.type) {
    case AUTHENTICATED_USER_DETAILS:
      return Object.assign({}, state, {
        data: action.data
      })
    default:
      return state
  }
}
