/* eslint-disable react/display-name */
import React, { useEffect } from "react"
import { NavLink } from "react-router-dom"
import MaterialTable from "material-table"
import { useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom"

import { Real_estates, Real_estatesCleanSubmit } from "./api/actions"
import { options, lang } from "../../setup/materialTable"
import Card from "components/Card/Card"
import CardHeader from "components/Card/CardHeader"
import CardBody from "components/Card/CardBody"
import SnackbarContent from "components/Snackbar/SnackbarContent"
import GridItem from "components/Grid/GridItem"
import GridContainer from "components/Grid/GridContainer"
import Button from "components/CustomButtons/Button"

export default function Real_estatesList() {
  const Real_estatesSt = useSelector(state => state.Real_estates)
  const dispatch = useDispatch()
  const Real_estatesReg = useSelector(state => state.Real_estatesReg)

  useEffect(() => {
    dispatch(Real_estates())
    const timer = setTimeout(() => {
      dispatch(Real_estatesCleanSubmit())
    }, 7000)
    return () => clearInterval(timer)
  }, [dispatch])

  const columns = [
    {
      field: "cod",
      title: "Cód.",
      export: false,
      render: rowData => (
        <Link to={`/admin/real_estates-register/${rowData.id}`}>
          {rowData.cod}
        </Link>
      )
    },
    {
      field: "name",
      title: "Nome"
    },
    {
      field: "status.name",
      title: "Status"
    },
    {
      field: "type.name",
      title: "Tipo"
    },
    {
      field: "detach",
      title: "Destaque"
    },
    {
      field: "city.name",
      title: "Cid."
    },
    {
      field: "district.name",
      title: "Bairro"
    },
    {
      field: "broker.name",
      title: "Corretor"
    },
    {
      field: "area",
      title: "Área"
    },
    {
      field: "id",
      title: "",
      filtering: false,
      export: false,
      render: rowData => (
        <Button
          component={NavLink}
          color="info"
          size="sm"
          style={{ float: "right" }}
          to={`/admin/real_estates-register/${rowData.id}`}
        >
          EDITAR
        </Button>
      )
    }
  ]

  return (
    <div>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary">
              <GridContainer>
                <GridItem xs={12} sm={12} md={9}>
                  <h3>Imóveis</h3>
                </GridItem>
                <GridItem xs={12} sm={12} md={3} align="right">
                  <Button
                    component={NavLink}
                    color="info"
                    to="/admin/real_estates-register/"
                  >
                    NOVO REGISTRO
                  </Button>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {Real_estatesReg.data.res ? (
                  <GridItem xs={12} sm={12} md={12}>
                    <SnackbarContent
                      message={Real_estatesReg.data.res}
                      color="success"
                    />
                  </GridItem>
                ) : (
                  ""
                )}
                <GridItem xs={12} sm={12} md={12}>
                  {Real_estatesSt.data ? (
                    <MaterialTable
                      title=""
                      data={Real_estatesSt.data}
                      columns={columns}
                      options={options}
                      localization={lang}
                    />
                  ) : (
                    ""
                  )}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  )
}
