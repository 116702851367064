import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import TextField from "@material-ui/core/TextField"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import InputLabel from "@material-ui/core/InputLabel"
import FormControl from "@material-ui/core/FormControl"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"

import { useSelector, useDispatch } from "react-redux"
// eslint-disable-next-line no-unused-vars
import { Img_real_estateSubmit, Img_real_estateDelete } from "./api/actions"
import { API_BASE_URL } from "../../setup/store"

import CardHeader from "components/Card/CardHeader"
import CardBody from "components/Card/CardBody"
import CardFooter from "components/Card/CardFooter"
import SnackbarContent from "components/Snackbar/SnackbarContent"
import Button from "components/CustomButtons/Button"
import GridItem from "components/Grid/GridItem"
import GridContainer from "components/Grid/GridContainer"
import Card from "components/Card/Card"

const styles = {
  formControl: {
    marginTop: "15px",
    minWidth: "100%",
    "& label": {
      display: "contents",
      position: "relative"
    }
  }
}
const useStyles = makeStyles(styles)

const Form = props => {
  const classes = useStyles()
  const Img_real_estateReg = useSelector(state => state.Img_real_estateReg)
  const { register, handleSubmit, setValue } = useForm({
    defaultValues: props.data
  })
  const dispatch = useDispatch()
  const Real_estate = useSelector(state => state.Real_estate)

  const onSubmit = async values => {
    dispatch(Img_real_estateSubmit(values))
  }

  const onDelete = () => {
    if (window.confirm("Confirma a exclusão do registro?"))
      dispatch(Img_real_estateDelete())
  }

  const handleChangeImg = e => {
    setValue("imgFile", e.target.files)
  }

  // select type
  const Image_typesSt = useSelector(state => state.Image_types)
  const [image_typeVal, setImage_typeVal] = React.useState(
    props.data.id ? props.data.image_type_id : ""
  )
  const handleChangeImage_type = e => {
    setValue("image_type_id", e.target.value)
    setImage_typeVal(e.target.value)
  }

  useEffect(() => {
    register({ name: "imgFile" })
    register({ name: "image_type_id" })
  }, [register])

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <form onSubmit={handleSubmit(onSubmit)}>
            <CardHeader color="primary">
              <h3>
                {Real_estate.data.name} {" > "}
                {props.data.id ? "Editando Registro" : "Novo registro"}
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {Img_real_estateReg.error.length !== 0 ? (
                  <GridItem xs={12} sm={12} md={12}>
                    <SnackbarContent
                      message={Img_real_estateReg.error}
                      color="danger"
                    />
                  </GridItem>
                ) : (
                  ""
                )}
                {props.data.id ? (
                  <input
                    type="hidden"
                    name="id"
                    ref={register}
                    value={props.data.id}
                  />
                ) : (
                  ""
                )}
                <input
                  type="hidden"
                  name="real_estate_id"
                  ref={register}
                  value={Real_estate.data.id}
                />
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    fullWidth={true}
                    label="Nome"
                    name="name"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl className={classes.formControl}>
                    <InputLabel>Enviar Imgs</InputLabel>
                    {props.data.id ? (
                      <input
                        accept="image/*"
                        onChange={handleChangeImg}
                        name="imgFile"
                        type="file"
                      />
                    ) : (
                      <input
                        accept="image/*"
                        onChange={handleChangeImg}
                        name="imgFile"
                        multiple
                        type="file"
                      />
                    )}
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel>Categoria</InputLabel>
                    {Image_typesSt.data.length === 0 ? (
                      ""
                    ) : (
                      <Select
                        name="image_type"
                        autoWidth={true}
                        fullWidth={true}
                        labelWidth={50}
                        value={image_typeVal}
                        onChange={handleChangeImage_type}
                      >
                        {Image_typesSt.data.map((item, i) => (
                          <MenuItem value={item.id} key={i}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </FormControl>
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button type="submit" color="primary">
                Salvar
              </Button>
              {props.data.id ? (
                <Button color="danger" onClick={() => onDelete()}>
                  Deletar
                </Button>
              ) : (
                ""
              )}
            </CardFooter>
            <CardBody>
              <GridContainer>
                {props.data.name_file ? (
                  <React.Fragment>
                    <GridItem xs={12} sm={12} md={12}>
                      <h3>Imagem:</h3>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <img
                        src={`${API_BASE_URL}uploads/img_real_estate/${props.data.name_file}`}
                        style={{ maxWidth: "100%" }}
                      />
                    </GridItem>
                  </React.Fragment>
                ) : (
                  ""
                )}
              </GridContainer>
            </CardBody>
          </form>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

Form.propTypes = {
  data: PropTypes.object.isRequired
}

export default Form
