import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"
import {
  Real_estatesCleanSubmit,
  Real_estatesCleanForm,
  Real_estate
} from "./api/actions"

import AppBar from "@material-ui/core/AppBar"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"

import Img_real_estateList from "../Img_real_estate/Img_real_estateList"
import Related_real_estateList from "../Related_real_estate/Related_real_estateList"
import Videos_real_estateList from "../Videos_real_estate/Videos_real_estateList"
import FormAttr from "./FormAttr"
import FormServ from "./FormServ"

import Form from "./Form"

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper
  }
}))

const Real_estatesForm = props => {
  const dispatch = useDispatch()
  const Real_estatesSt = useSelector(state => state.Real_estate)
  const classes = useStyles()
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  useEffect(() => {
    dispatch(Real_estatesCleanSubmit())
    dispatch(Real_estate(props.match.params.id))

    return () => dispatch(Real_estatesCleanForm())
  }, [dispatch, props.match.params.id])

  return (
    <React.Fragment>
      {Real_estatesSt.data.length === 0 || !Real_estatesSt.data.id ? (
        ""
      ) : (
        <React.Fragment>
          <div className={classes.root}>
            <AppBar position="static" color="default">
              <Tabs value={value} onChange={handleChange} aria-label="Imóveis">
                <Tab label="Atributos" {...a11yProps(0)} />
                <Tab label="Serviços" {...a11yProps(1)} />
                <Tab label="Fotos" {...a11yProps(3)} />
                <Tab label="Vídeos" {...a11yProps(2)} />
                <Tab label="Imóveis relacionados" {...a11yProps(4)} />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              <FormAttr id={props.match.params.id} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <FormServ id={props.match.params.id} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Img_real_estateList />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Videos_real_estateList />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <Related_real_estateList />
            </TabPanel>
          </div>
        </React.Fragment>
      )}

      {Real_estatesSt.data.length === 0 ? (
        ""
      ) : (
        <Form data={Real_estatesSt.data} />
      )}
    </React.Fragment>
  )
}

Real_estatesForm.propTypes = {
  match: PropTypes.object
}

export default Real_estatesForm
