/* eslint-disable react/display-name */
import React, { useEffect } from "react"
import { NavLink } from "react-router-dom"
import MaterialTable from "material-table"
import { useSelector, useDispatch } from "react-redux"

import { Image_types, Image_typesCleanSubmit } from "./api/actions"
import { options, lang } from "../../setup/materialTable"
import Card from "components/Card/Card"
import CardHeader from "components/Card/CardHeader"
import CardBody from "components/Card/CardBody"
import SnackbarContent from "components/Snackbar/SnackbarContent"
import GridItem from "components/Grid/GridItem"
import GridContainer from "components/Grid/GridContainer"
import Button from "components/CustomButtons/Button"

export default function Image_typesList() {
  const Image_typesSt = useSelector(state => state.Image_types)
  const dispatch = useDispatch()
  const Image_typesReg = useSelector(state => state.Image_typesReg)

  useEffect(() => {
    dispatch(Image_types())
    const timer = setTimeout(() => {
      dispatch(Image_typesCleanSubmit())
    }, 7000)
    return () => clearInterval(timer)
  }, [dispatch])

  const columns = [
    {
      field: "name",
      title: "Nome"
    },
    {
      field: "order_site",
      title: "Ordem"
    },
    {
      field: "id",
      title: "",
      filtering: false,
      export: false,
      render: rowData => (
        <Button
          component={NavLink}
          color="info"
          size="sm"
          style={{ float: "right" }}
          to={`/admin/image_types-register/${rowData.id}`}
        >
          EDITAR
        </Button>
      )
    }
  ]

  return (
    <div>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary">
              <GridContainer>
                <GridItem xs={12} sm={12} md={9}>
                  <h3>Listagem</h3>
                </GridItem>
                <GridItem xs={12} sm={12} md={3} align="right">
                  <Button
                    component={NavLink}
                    color="info"
                    to="/admin/image_types-register/"
                  >
                    NOVO REGISTRO
                  </Button>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {Image_typesReg.data.res ? (
                  <GridItem xs={12} sm={12} md={12}>
                    <SnackbarContent
                      message={Image_typesReg.data.res}
                      color="success"
                    />
                  </GridItem>
                ) : (
                  ""
                )}

                <GridItem xs={12} sm={12} md={12}>
                  {Image_typesSt.data ? (
                    <MaterialTable
                      title=""
                      data={Image_typesSt.data}
                      columns={columns}
                      options={options}
                      localization={lang}
                    />
                  ) : (
                    ""
                  )}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  )
}
