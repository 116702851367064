import React from "react"
import { Switch, Route } from "react-router-dom"

import { makeStyles } from "@material-ui/core/styles"
import routes from "routes"
import pagesStyle from "assets/jss/material-dashboard-react/layouts/authStyle.js"
import login from "assets/img/login.jpeg"

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        )
      }
      return null
    })}
  </Switch>
)

const useStyles = makeStyles(pagesStyle)

const Pages = () => {
  const classes = useStyles()

  return (
    <div>
      <div className={classes.wrapper}>
        <div
          className={classes.fullPage}
          style={{ backgroundImage: "url(" + login + ")" }}
        >
          {switchRoutes}
        </div>
      </div>
    </div>
  )
}

export default Pages
