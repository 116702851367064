import {
  STATU_FETCH,
  STATU_CLEAR,
  NEW_STATU,
  STATUS_FETCH,
  STATUS_FETCHING,
  STATU_SUBMITTING,
  STATU_SUBMIT,
  STATU_SUBMIT_ERROR
} from "./actions"

const initialState = {
  data: [],
  loading: false
}
export function Statu(state = initialState, action = {}) {
  switch (action.type) {
    case STATU_FETCH:
      return Object.assign({}, state, {
        data: action.data
      })
    case NEW_STATU:
      return Object.assign({}, state, {
        data: {
          id: null
        }
      })
    case STATU_CLEAR:
      return Object.assign({}, state, initialState)
    default:
      return state
  }
}

export function Status(state = initialState, action = {}) {
  switch (action.type) {
    case STATUS_FETCH:
      return Object.assign({}, state, {
        data: action.data,
        loading: false
      })
    case STATUS_FETCHING:
      return Object.assign({}, state, {
        data: [],
        loading: true
      })
    default:
      return state
  }
}

const initialStateReg = {
  data: [],
  error: []
}
export function StatusReg(state = initialStateReg, action = {}) {
  switch (action.type) {
    case STATU_SUBMITTING:
      return Object.assign({}, state, initialStateReg)
    case STATU_SUBMIT:
      return Object.assign({}, state, {
        data: action.data,
        error: []
      })
    case STATU_SUBMIT_ERROR:
      return Object.assign({}, state, {
        data: [],
        error: action.error
      })
    default:
      return state
  }
}
