import axios from "axios"
import { API_URL, AUTH_TOKEN, LOGIN } from "../../../setup/store"
import { history } from "../../../index"

// select
// import { s } from "../..//api/actions"

export const CITYS_FETCH = "CITYS_FETCH"
export const CITYS_FETCHING = "CITYS_FETCHING"

export const NEW_CITY = "NEW_CITY"
export const CITY_FETCH = "CITY_FETCH"
export const CITY_CLEAR = "CITY_CLEAR"

export const CITY_SUBMITTING = "CITY_SUBMITTING"
export const CITY_SUBMIT = "CITY_SUBMIT"
export const CITY_SUBMIT_ERROR = "CITY_SUBMIT_ERROR"

export const ESTATES_FETCH = "ESTATES_FETCH"

const URL_REDIRECT = "/admin/citys"
const API_TYPE = "citys"

export const Citys = () => {
  return async dispatch => {
    dispatch({
      type: CITYS_FETCHING
    })
    try {
      const res = await axios.get(`${API_URL}${API_TYPE}`, {
        headers: {
          Authorization: AUTH_TOKEN
        }
      })

      dispatch({
        type: CITYS_FETCH,
        data: res.data
      })
    } catch (error) {
      window.location.href = LOGIN
      console.error(error)
    }
  }
}

export const Estates = () => {
  return async dispatch => {
    try {
      const res = await axios.get(`${API_URL}estates/`, {
        headers: {
          Authorization: AUTH_TOKEN
        }
      })

      dispatch({
        type: ESTATES_FETCH,
        data: res.data
      })
    } catch (error) {
      window.location.href = LOGIN
      console.error(error)
    }
  }
}

export const City = id => {
  return async dispatch => {
    // select
    dispatch(Estates())
    if (id) {
      try {
        const res = await axios.get(`${API_URL}${API_TYPE}/${id}`, {
          headers: {
            Authorization: AUTH_TOKEN
          }
        })
        dispatch({
          type: CITY_FETCH,
          data: res.data
        })
      } catch (error) {
        window.location.href = LOGIN
        console.log(error.message)
      }
    } else {
      dispatch({
        type: NEW_CITY
      })
    }
  }
}

export const CitysCleanSubmit = () => {
  return async dispatch => {
    dispatch({
      type: CITY_SUBMITTING
    })
  }
}

export const CitysCleanForm = () => {
  return async dispatch => {
    dispatch({
      type: CITY_CLEAR
    })
  }
}

export const CitysDelete = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: CITY_SUBMITTING
    })
    const ID = getState().City.data.id
    try {
      const res = await axios.delete(`${API_URL}${API_TYPE}/${ID}`, {
        headers: {
          Authorization: AUTH_TOKEN
        }
      })
      dispatch({
        type: CITY_SUBMIT,
        data: res.data
      })
      history.push(URL_REDIRECT)
    } catch (error) {
      console.log(error.message)
    }
  }
}

export const CitysSubmit = citys => {
  return async dispatch => {
    dispatch({
      type: CITY_SUBMITTING
    })
    try {
      let res

      if (citys.id) {
        res = await axios.put(`${API_URL}${API_TYPE}/${citys.id}`, citys, {
          headers: {
            Authorization: AUTH_TOKEN
          }
        })
      } else {
        res = await axios.post(`${API_URL}${API_TYPE}/`, citys, {
          headers: {
            Authorization: AUTH_TOKEN
          }
        })
      }

      dispatch({
        type: CITY_SUBMIT,
        data: res.data
      })
      history.push(URL_REDIRECT)
    } catch (error) {
      dispatch({
        type: CITY_SUBMIT_ERROR,
        error: "Este nome já foi cadastrado, Por favor escolha um diferente..."
      })
    }
  }
}
