import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import TextField from "@material-ui/core/TextField"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import InputLabel from "@material-ui/core/InputLabel"
import FormControl from "@material-ui/core/FormControl"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"

import { useSelector, useDispatch } from "react-redux"
// eslint-disable-next-line no-unused-vars
import { LeadsSubmit, LeadsDelete } from "./api/actions"

import CardHeader from "components/Card/CardHeader"
import CardBody from "components/Card/CardBody"
import CardFooter from "components/Card/CardFooter"
import SnackbarContent from "components/Snackbar/SnackbarContent"
import Button from "components/CustomButtons/Button"
import GridItem from "components/Grid/GridItem"
import GridContainer from "components/Grid/GridContainer"
import Card from "components/Card/Card"

// date
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers"
import LuxonUtils from "@date-io/luxon"
import { FormatDate } from "../../setup/util"

const styles = {
  formControl: {
    marginTop: "15px",
    minWidth: "100%"
  }
}
const useStyles = makeStyles(styles)

const Form = props => {
  const classes = useStyles()
  const LeadsReg = useSelector(state => state.LeadsReg)
  const { register, handleSubmit, setValue } = useForm({
    defaultValues: props.data
  })
  const dispatch = useDispatch()

  const onSubmit = async values => {
    values.date_cad = FormatDate(values.date_cad)

    dispatch(LeadsSubmit(values))
  }

  const onDelete = () => {
    if (window.confirm("Confirma a exclusão do registro?"))
      dispatch(LeadsDelete())
  }

  // select status_lead
  const status_leadsSt = useSelector(state => state.Status_Leads)
  const [status_leadVal, setStatus_leadVal] = React.useState(
    props.data.id ? props.data.status_lead_id : ""
  )
  const handleChangeStatus_lead = e => {
    setValue("status_lead_id", e.target.value)
    setStatus_leadVal(e.target.value)
  }

  // select real_estate_id
  const real_estatesSt = useSelector(state => state.Real_estates)
  const [real_estateVal, setReal_estateVal] = React.useState(
    props.data.id ? props.data.real_estate_id : ""
  )
  const handleChangeReal_estate = e => {
    setValue("real_estate_id", e.target.value)
    setReal_estateVal(e.target.value)
  }

  // select broker
  const brokersSt = useSelector(state => state.Brokers)
  const [brokerVal, setBrokerVal] = React.useState(
    props.data.id ? props.data.broker_id : ""
  )
  const handleChangeBroker = e => {
    setValue("broker_id", e.target.value)
    setBrokerVal(e.target.value)
  }

  // date
  const [selectedDate_cad, setSelectedDate_cad] = React.useState(
    props.data.date_cad ? props.data.date_cad + " 00:00" : new Date()
  )

  useEffect(() => {
    register({ name: "date_cad", required: true })
    register({ name: "status_lead_id", required: true })
    register({ name: "real_estate_id", required: true })
    register({ name: "broker_id", required: true })
  }, [register])

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <form onSubmit={handleSubmit(onSubmit)}>
            <CardHeader color="primary">
              <h3>{props.data.id ? "Editando Registro" : "Novo registro"}</h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {LeadsReg.error.length !== 0 ? (
                  <GridItem xs={12} sm={12} md={12}>
                    <SnackbarContent message={LeadsReg.error} color="danger" />
                  </GridItem>
                ) : (
                  ""
                )}
                {props.data.id ? (
                  <input
                    type="hidden"
                    name="id"
                    ref={register}
                    value={props.data.id}
                  />
                ) : (
                  ""
                )}
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Nome"
                    name="name"
                    required
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Email"
                    name="email"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Fone"
                    name="phone"
                    required
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                    fullWidth={true}
                    label="Mensagem"
                    name="msn"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                    multiline={true}
                    rows={6}
                    rowsMax={12}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Assunto"
                    name="type"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <MuiPickersUtilsProvider utils={LuxonUtils}>
                    <DatePicker
                      fullWidth={true}
                      name="date_cad"
                      margin="normal"
                      label="Data cad."
                      format="dd/MM/yyyy"
                      inputVariant="outlined"
                      value={selectedDate_cad}
                      inputRef={register}
                      onChange={setSelectedDate_cad}
                    />
                  </MuiPickersUtilsProvider>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel>Status</InputLabel>
                    {status_leadsSt.data.length === 0 ? (
                      ""
                    ) : (
                      <Select
                        name="status_lead"
                        autoWidth={true}
                        fullWidth={true}
                        labelWidth={50}
                        value={status_leadVal}
                        onChange={handleChangeStatus_lead}
                      >
                        {status_leadsSt.data.map((item, i) => (
                          <MenuItem value={item.id} key={i}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel>Imóvel</InputLabel>
                    {real_estatesSt.data.length === 0 ? (
                      ""
                    ) : (
                      <Select
                        name="real_estate_id"
                        autoWidth={true}
                        fullWidth={true}
                        labelWidth={50}
                        value={real_estateVal}
                        onChange={handleChangeReal_estate}
                      >
                        {real_estatesSt.data.map((item, i) => (
                          <MenuItem value={item.id} key={i}>
                            {item.cod} - {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel>Corretor</InputLabel>
                    {brokersSt.data.length === 0 ? (
                      ""
                    ) : (
                      <Select
                        name="broker"
                        autoWidth={true}
                        fullWidth={true}
                        labelWidth={50}
                        value={brokerVal}
                        onChange={handleChangeBroker}
                      >
                        {brokersSt.data.map((item, i) => (
                          <MenuItem value={item.id} key={i}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                    fullWidth={true}
                    label="Obs"
                    name="obs"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                    multiline={true}
                    rows={6}
                    rowsMax={12}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button type="submit" color="primary">
                Salvar
              </Button>
              {props.data.id ? (
                <Button color="danger" onClick={() => onDelete()}>
                  Deletar
                </Button>
              ) : (
                ""
              )}
            </CardFooter>
          </form>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

Form.propTypes = {
  data: PropTypes.object.isRequired
}

export default Form
