import axios from "axios"
import { API_URL, AUTH_TOKEN, LOGIN } from "../../../setup/store"
import { history } from "../../../index"

// select
// import { s } from "../..//api/actions"

export const BROKERS_FETCH = "BROKERS_FETCH"
export const BROKERS_FETCHING = "BROKERS_FETCHING"

export const NEW_BROKER = "NEW_BROKER"
export const BROKER_FETCH = "BROKER_FETCH"
export const BROKER_CLEAR = "BROKER_CLEAR"

export const BROKER_SUBMITTING = "BROKER_SUBMITTING"
export const BROKER_SUBMIT = "BROKER_SUBMIT"
export const BROKER_SUBMIT_ERROR = "BROKER_SUBMIT_ERROR"

const URL_REDIRECT = "/admin/brokers"
const API_TYPE = "brokers"

export const Brokers = () => {
  return async dispatch => {
    dispatch({
      type: BROKERS_FETCHING
    })
    try {
      const res = await axios.get(`${API_URL}${API_TYPE}`, {
        headers: {
          Authorization: AUTH_TOKEN
        }
      })

      dispatch({
        type: BROKERS_FETCH,
        data: res.data
      })
    } catch (error) {
      window.location.href = LOGIN
      console.error(error)
    }
  }
}

export const Broker = id => {
  return async dispatch => {
    // select
    //dispatch(())

    if (id) {
      try {
        const res = await axios.get(`${API_URL}${API_TYPE}/${id}`, {
          headers: {
            Authorization: AUTH_TOKEN
          }
        })
        dispatch({
          type: BROKER_FETCH,
          data: res.data
        })
      } catch (error) {
        window.location.href = LOGIN
        console.log(error.message)
      }
    } else {
      dispatch({
        type: NEW_BROKER
      })
    }
  }
}

export const BrokersCleanSubmit = () => {
  return async dispatch => {
    dispatch({
      type: BROKER_SUBMITTING
    })
  }
}

export const BrokersCleanForm = () => {
  return async dispatch => {
    dispatch({
      type: BROKER_CLEAR
    })
  }
}

export const BrokersDelete = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: BROKER_SUBMITTING
    })
    const ID = getState().Broker.data.id
    try {
      const res = await axios.delete(`${API_URL}${API_TYPE}/${ID}`, {
        headers: {
          Authorization: AUTH_TOKEN
        }
      })
      dispatch({
        type: BROKER_SUBMIT,
        data: res.data
      })
      history.push(URL_REDIRECT)
    } catch (error) {
      console.log(error.message)
    }
  }
}

export const BrokersSubmit = brokers => {
  return async dispatch => {
    dispatch({
      type: BROKER_SUBMITTING
    })
    try {
      let res

      if (brokers.id) {
        res = await axios.put(`${API_URL}${API_TYPE}/${brokers.id}`, brokers, {
          headers: {
            Authorization: AUTH_TOKEN
          }
        })
      } else {
        res = await axios.post(`${API_URL}${API_TYPE}/`, brokers, {
          headers: {
            Authorization: AUTH_TOKEN
          }
        })
      }

      dispatch({
        type: BROKER_SUBMIT,
        data: res.data
      })
      history.push(URL_REDIRECT)
    } catch (error) {
      dispatch({
        type: BROKER_SUBMIT_ERROR,
        error: "Este nome já foi cadastrado, Por favor escolha um diferente..."
      })
    }
  }
}
