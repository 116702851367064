import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"
import {
  Type_userCleanSubmit,
  Type_userCleanForm,
  Type_use
} from "./api/actions"

import Form from "./Form"

const Type_userForm = props => {
  const dispatch = useDispatch()
  const Type_userSt = useSelector(state => state.Type_use)

  useEffect(() => {
    dispatch(Type_userCleanSubmit())
    dispatch(Type_use(props.match.params.id))

    return () => dispatch(Type_userCleanForm())
  }, [dispatch, props.match.params.id])

  return (
    <React.Fragment>
      {Type_userSt.data.length === 0 ? "" : <Form data={Type_userSt.data} />}
    </React.Fragment>
  )
}

Type_userForm.propTypes = {
  match: PropTypes.object
}

export default Type_userForm
