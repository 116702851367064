import {
  IMAGE_TYPE_FETCH,
  IMAGE_TYPE_CLEAR,
  NEW_IMAGE_TYPE,
  IMAGE_TYPES_FETCH,
  IMAGE_TYPES_FETCHING,
  IMAGE_TYPE_SUBMITTING,
  IMAGE_TYPE_SUBMIT,
  IMAGE_TYPE_SUBMIT_ERROR
} from "./actions"

const initialState = {
  data: [],
  loading: false
}
export function Image_type(state = initialState, action = {}) {
  switch (action.type) {
    case IMAGE_TYPE_FETCH:
      return Object.assign({}, state, {
        data: action.data
      })
    case NEW_IMAGE_TYPE:
      return Object.assign({}, state, {
        data: {
          id: null
        }
      })
    case IMAGE_TYPE_CLEAR:
      return Object.assign({}, state, initialState)
    default:
      return state
  }
}

export function Image_types(state = initialState, action = {}) {
  switch (action.type) {
    case IMAGE_TYPES_FETCH:
      return Object.assign({}, state, {
        data: action.data,
        loading: false
      })
    case IMAGE_TYPES_FETCHING:
      return Object.assign({}, state, {
        data: [],
        loading: true
      })
    default:
      return state
  }
}

const initialStateReg = {
  data: [],
  error: []
}
export function Image_typesReg(state = initialStateReg, action = {}) {
  switch (action.type) {
    case IMAGE_TYPE_SUBMITTING:
      return Object.assign({}, state, initialStateReg)
    case IMAGE_TYPE_SUBMIT:
      return Object.assign({}, state, {
        data: action.data,
        error: []
      })
    case IMAGE_TYPE_SUBMIT_ERROR:
      return Object.assign({}, state, {
        data: [],
        error: action.error
      })
    default:
      return state
  }
}
