import { compose, combineReducers } from "redux"
import { createStore, applyMiddleware } from "redux"
import thunk from "redux-thunk"

import * as auth from "../views/Login/api/state"
import * as user from "../views/User/api/state"
import * as dashboard from "../views/Dashboard/api/state"
import * as Real_estates from "../views/Real_estates/api/state"
import * as Brokers from "../views/Brokers/api/state"
import * as Image_types from "../views/Image_types/api/state"
import * as Services from "../views/Services/api/state"
import * as Attributes from "../views/Attributes/api/state"
import * as Status from "../views/Status/api/state"
import * as Citys from "../views/Citys/api/state"
import * as Districts from "../views/Districts/api/state"
import * as Types from "../views/Types/api/state"
import * as Videos_real_estate from "../views/Videos_real_estate/api/state"
import * as Related_real_estate from "../views/Related_real_estate/api/state"
import * as Img_real_estate from "../views/Img_real_estate/api/state"
import * as Clientes from "../views/Clientes/api/state"
import * as Type_user from "../views/Type_user/api/state"
import * as Leads from "../views/Leads/api/state"
import * as Status_Leads from "../views/Status_Leads/api/state"
import * as Operacao from "../views/Operacao/api/state"

const appReducer = combineReducers({
  ...auth,
  ...user,
  ...dashboard,
  ...Types,
  ...Districts,
  ...Citys,
  ...Status,
  ...Attributes,
  ...Services,
  ...Image_types,
  ...Brokers,
  ...Real_estates,
  ...Img_real_estate,
  ...Related_real_estate,
  ...Videos_real_estate,
  ...Type_user,
  ...Status_Leads,
  ...Leads,
  ...Clientes,
  ...Operacao,
})

const rootReducer = (state, action) => {
  if (action.type === "RESET") {
    state = undefined
  }
  return appReducer(state, action)
}

const initialState = {}

export const store = createStore(
  rootReducer,
  initialState,
  compose(applyMiddleware(thunk))
)

export const API_BASE_URL = "https://www.ayacheincorporadora.com.br/sistema_imovel/public/"
//export const API_BASE_URL = "http://localhost:8000/"

export const API_URL = API_BASE_URL + "api/"
export const LOGIN = "/auth/login"
const USER_TOKEN = localStorage.getItem("token")
export const AUTH_TOKEN = `Bearer ${USER_TOKEN}`
