import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { dashboard } from "./api/actions"

import Card from "components/Card/Card.js"
import CardHeader from "components/Card/CardHeader.js"
import CardIcon from "components/Card/CardIcon.js"
import Icon from "@material-ui/core/Icon"
import GridItem from "components/Grid/GridItem.js"
import GridContainer from "components/Grid/GridContainer.js"
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js"
import { NavLink } from "react-router-dom"
import ClientesList from "../Clientes/ClientesList"
import LeadsList from "../Leads/LeadsList"
import Real_estatesList from "../Real_estates/Real_estatesList"

import { makeStyles } from "@material-ui/core/styles"
const useStyles = makeStyles(styles)

const Dashboard = () => {
  const dashboardSt = useSelector(state => state.dashboard)
  const userSt = useSelector(state => state.user_logged)
  const classes = useStyles()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(dashboard())
  }, [dispatch])

  return (
    <React.Fragment>
      <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <Icon>assessment</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>
                <NavLink to="/admin/real_estates">Imóveis</NavLink>
              </p>
              <h3 className={classes.cardTitle}>
                <small>
                  <NavLink to="/admin/real_estates">
                    {dashboardSt.data.real_estates}
                  </NavLink>
                </small>
              </h3>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <Icon>person</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>
                <NavLink to="/admin/clientes">Clientes</NavLink>
              </p>
              <h3 className={classes.cardTitle}>
                <small>
                  <NavLink to="/admin/clientes">
                    {dashboardSt.data.clients}
                  </NavLink>
                </small>
              </h3>
            </CardHeader>
          </Card>
        </GridItem>
        {userSt.data.type_user_id ===  "1"  ? (
          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning">
                  <Icon>assessment</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>
                  <NavLink to="/admin/leads">Contatos</NavLink>
                </p>
                <h3 className={classes.cardTitle}>
                  <small>
                    <NavLink to="/admin/leads">
                      {dashboardSt.data.leads}
                    </NavLink>
                  </small>
                </h3>
              </CardHeader>
            </Card>
          </GridItem>
        ) : (
          ""
        )}

        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <Icon>person</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>
                <NavLink to="/admin/brokers">Corretores</NavLink>
              </p>
              <h3 className={classes.cardTitle}>
                <small>
                  <NavLink to="/admin/brokers">{dashboardSt.data.func}</NavLink>
                </small>
              </h3>
            </CardHeader>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          {userSt.data.type_user_id ===  "1"  ? <Real_estatesList /> : ""}
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          {userSt.data.type_user_id ===  "1"  ? <LeadsList /> : ""}
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <ClientesList />
        </GridItem>
      </GridContainer>
    </React.Fragment>
  )
}
export default Dashboard
