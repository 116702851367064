import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"
import { ServicesCleanSubmit, ServicesCleanForm, Service } from "./api/actions"

import Form from "./Form"

const ServicesForm = props => {
  const dispatch = useDispatch()
  const ServicesSt = useSelector(state => state.Service)

  useEffect(() => {
    dispatch(ServicesCleanSubmit())
    dispatch(Service(props.match.params.id))

    return () => dispatch(ServicesCleanForm())
  }, [dispatch, props.match.params.id])

  return (
    <React.Fragment>
      {ServicesSt.data.length === 0 ? "" : <Form data={ServicesSt.data} />}
    </React.Fragment>
  )
}

ServicesForm.propTypes = {
  match: PropTypes.object
}

export default ServicesForm
