import axios from "axios"
import { API_URL, AUTH_TOKEN, LOGIN } from "../../../setup/store"
import { history } from "../../../index"
import { Type_user } from "../../Type_user/api/actions"

export const USERS_FETCH = "USERS_FETCH"
export const USERS_FETCHING = "USERS_FETCHING"

export const NEW_USER = "NEW_USER"
export const USER_FETCH = "USER_FETCH"
export const USER_CLEAR = "USER_CLEAR"

export const USER_SUBMITTING = "USER_SUBMITTING"
export const USER_SUBMIT = "USER_SUBMIT"
export const USER_SUBMIT_ERROR = "USER_SUBMIT_ERROR"

const URL_REDIRECT = "/admin/user-list"

export const users = () => {
  return async dispatch => {
    dispatch({
      type: USERS_FETCHING
    })
    try {
      const res = await axios.get(`${API_URL}users`, {
        headers: {
          Authorization: AUTH_TOKEN
        }
      })

      dispatch({
        type: USERS_FETCH,
        data: res.data
      })
    } catch (error) {
      window.location.href = LOGIN
      console.error(error)
    }
  }
}

export const usersType = () => {
  return async dispatch => {
    dispatch({
      type: USERS_FETCHING
    })
    try {
      const res = await axios.get(`${API_URL}users_type/`, {
        headers: {
          Authorization: AUTH_TOKEN
        }
      })

      dispatch({
        type: USERS_FETCH,
        data: res.data
      })
    } catch (error) {
      window.location.href = LOGIN
      console.error(error)
    }
  }
}

export const user = id => {
  return async dispatch => {
    dispatch(Type_user())
    if (id) {
      try {
        const res = await axios.get(`${API_URL}users/${id}`, {
          headers: {
            Authorization: AUTH_TOKEN
          }
        })
        dispatch({
          type: USER_FETCH,
          data: res.data
        })
      } catch (error) {
        window.location.href = LOGIN
        console.log(error.message)
      }
    } else {
      dispatch({
        type: NEW_USER
      })
    }
  }
}

export const userCleanSubmit = () => {
  return async dispatch => {
    dispatch({
      type: USER_SUBMITTING
    })
  }
}

export const userCleanForm = () => {
  return async dispatch => {
    dispatch({
      type: USER_CLEAR
    })
  }
}

export const userDelete = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: USER_SUBMITTING
    })
    let state = getState()
    try {
      const res = await axios.delete(`${API_URL}users/${state.user.data.id}`, {
        headers: {
          Authorization: AUTH_TOKEN
        }
      })
      dispatch({
        type: USER_SUBMIT,
        data: res.data
      })
      history.push(URL_REDIRECT)
    } catch (error) {
      console.log(error.message)
    }
  }
}

export const userSubmit = user => {
  return async dispatch => {
    dispatch({
      type: USER_SUBMITTING
    })
    try {
      let res

      if (user.id) {
        res = await axios.put(`${API_URL}users/${user.id}`, user, {
          headers: {
            Authorization: AUTH_TOKEN
          }
        })
      } else {
        res = await axios.post(`${API_URL}users/`, user, {
          headers: {
            Authorization: AUTH_TOKEN
          }
        })
      }

      if (!res.data.error) {
        dispatch({
          type: USER_SUBMIT,
          data: res.data
        })
        history.push(URL_REDIRECT)
      } else {
        dispatch({
          type: USER_SUBMIT_ERROR,
          error: res.data
        })
      }
    } catch (error) {
      console.log(error.message)
    }
  }
}
