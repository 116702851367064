import {
  ATTRIBUTE_FETCH,
  ATTRIBUTE_CLEAR,
  NEW_ATTRIBUTE,
  ATTRIBUTES_FETCH,
  ATTRIBUTES_FETCHING,
  ATTRIBUTE_SUBMITTING,
  ATTRIBUTE_SUBMIT,
  ATTRIBUTE_SUBMIT_ERROR
} from "./actions"

const initialState = {
  data: [],
  loading: false
}
export function Attribute(state = initialState, action = {}) {
  switch (action.type) {
    case ATTRIBUTE_FETCH:
      return Object.assign({}, state, {
        data: action.data
      })
    case NEW_ATTRIBUTE:
      return Object.assign({}, state, {
        data: {
          id: null
        }
      })
    case ATTRIBUTE_CLEAR:
      return Object.assign({}, state, initialState)
    default:
      return state
  }
}

export function Attributes(state = initialState, action = {}) {
  switch (action.type) {
    case ATTRIBUTES_FETCH:
      return Object.assign({}, state, {
        data: action.data,
        loading: false
      })
    case ATTRIBUTES_FETCHING:
      return Object.assign({}, state, {
        data: [],
        loading: true
      })
    default:
      return state
  }
}

const initialStateReg = {
  data: [],
  error: []
}
export function AttributesReg(state = initialStateReg, action = {}) {
  switch (action.type) {
    case ATTRIBUTE_SUBMITTING:
      return Object.assign({}, state, initialStateReg)
    case ATTRIBUTE_SUBMIT:
      return Object.assign({}, state, {
        data: action.data,
        error: []
      })
    case ATTRIBUTE_SUBMIT_ERROR:
      return Object.assign({}, state, {
        data: [],
        error: action.error
      })
    default:
      return state
  }
}
