import axios from "axios"
import { API_URL, AUTH_TOKEN, LOGIN } from "../../../setup/store"
import { history } from "../../../index"

// select
import { Image_types } from "../../Image_types/api/actions"

export const IMG_REAL_ESTATE_FETCH = "IMG_REAL_ESTATE_FETCH"
export const IMG_REAL_ESTATE_FETCHING = "IMG_REAL_ESTATE_FETCHING"

export const NEW_IMG_REAL_ESTAT = "NEW_IMG_REAL_ESTAT"
export const IMG_REAL_ESTAT_FETCH = "IMG_REAL_ESTAT_FETCH"
export const IMG_REAL_ESTAT_CLEAR = "IMG_REAL_ESTAT_CLEAR"

export const IMG_REAL_ESTAT_SUBMITTING = "IMG_REAL_ESTAT_SUBMITTING"
export const IMG_REAL_ESTAT_SUBMIT = "IMG_REAL_ESTAT_SUBMIT"
export const IMG_REAL_ESTAT_SUBMIT_ERROR = "IMG_REAL_ESTAT_SUBMIT_ERROR"

const URL_REDIRECT = "/admin/real_estates-register/"
const API_TYPE = "img_real_estate"

export const Img_real_estate = id => {
  return async dispatch => {
    dispatch({
      type: IMG_REAL_ESTATE_FETCHING
    })
    try {
      const res = await axios.get(`${API_URL}${API_TYPE}_sub/${id}`, {
        headers: {
          Authorization: AUTH_TOKEN
        }
      })

      dispatch({
        type: IMG_REAL_ESTATE_FETCH,
        data: res.data
      })
    } catch (error) {
      window.location.href = LOGIN
      console.error(error)
    }
  }
}

export const Img_real_estat = id => {
  return async dispatch => {
    dispatch(Image_types())
    if (id) {
      try {
        const res = await axios.get(`${API_URL}${API_TYPE}/${id}`, {
          headers: {
            Authorization: AUTH_TOKEN
          }
        })
        dispatch({
          type: IMG_REAL_ESTAT_FETCH,
          data: res.data
        })
      } catch (error) {
        window.location.href = LOGIN
        console.log(error.message)
      }
    } else {
      dispatch({
        type: NEW_IMG_REAL_ESTAT
      })
    }
  }
}

export const Img_real_estateCleanSubmit = () => {
  return async dispatch => {
    dispatch({
      type: IMG_REAL_ESTAT_SUBMITTING
    })
  }
}

export const Img_real_estateCleanForm = () => {
  return async dispatch => {
    dispatch({
      type: IMG_REAL_ESTAT_CLEAR
    })
  }
}

export const Img_real_estateDelete = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: IMG_REAL_ESTAT_SUBMITTING
    })
    const reg = getState().Img_real_estat.data
    try {
      const res = await axios.delete(`${API_URL}${API_TYPE}/${reg.id}`, {
        headers: {
          Authorization: AUTH_TOKEN
        }
      })
      dispatch({
        type: IMG_REAL_ESTAT_SUBMIT,
        data: res.data
      })
      history.push(URL_REDIRECT + reg.real_estate_id)
    } catch (error) {
      console.log(error.message)
    }
  }
}

export const Img_real_estateSubmit = img_real_estate => {
  return async dispatch => {
    dispatch({
      type: IMG_REAL_ESTAT_SUBMITTING
    })
    try {
      let total = 1
      let res
      let form_data
      let API_TIME
      if (img_real_estate.imgFile) {
        total = img_real_estate.imgFile.length
      }
      for (let i = 0; i < total; i++) {
        form_data = new FormData()
        API_TIME = Date.now() + i
        if (img_real_estate.imgFile && img_real_estate.imgFile[i]) {
          form_data.append("file", img_real_estate.imgFile[i])
        }
        form_data.append("name", img_real_estate.name)
        form_data.append("time", API_TIME)
        form_data.append("real_estate_id", img_real_estate.real_estate_id)
        form_data.append("image_type_id", img_real_estate.image_type_id)
        if (img_real_estate.id) {
          form_data.append("id", img_real_estate.id)
        }
        res = await axios.post(
          `${API_URL}${API_TYPE}/files/${API_TIME}`,
          form_data,
          {
            headers: {
              "content-type": "multipart/form-data",
              Authorization: AUTH_TOKEN
            }
          }
        )
      }
      dispatch({
        type: IMG_REAL_ESTAT_SUBMIT,
        data: res.data
      })
      history.push(URL_REDIRECT + img_real_estate.real_estate_id)
    } catch (error) {
      console.log(error)

      // dispatch({
      //   type: IMG_REAL_ESTAT_SUBMIT_ERROR,
      //   error:
      //     "Erro na image enviada, Por favor escolha uma com tamanho menor que 6MB e do formato jpeg,png,jpg,gif..."
      // })
    }
  }
}
