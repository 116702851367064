import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"
import { LeadsCleanSubmit, LeadsCleanForm, Lead } from "./api/actions"

import Form from "./Form"

const LeadsForm = props => {
  const dispatch = useDispatch()
  const LeadsSt = useSelector(state => state.Lead)

  useEffect(() => {
    dispatch(LeadsCleanSubmit())
    dispatch(Lead(props.match.params.id))

    return () => dispatch(LeadsCleanForm())
  }, [dispatch, props.match.params.id])

  return (
    <React.Fragment>
      {LeadsSt.data.length === 0 ? "" : <Form data={LeadsSt.data} />}
    </React.Fragment>
  )
}

LeadsForm.propTypes = {
  match: PropTypes.object
}

export default LeadsForm
