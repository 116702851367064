import {
  CLIENTE_FETCH,
  CLIENTE_CLEAR,
  NEW_CLIENTE,
  CLIENTES_FETCH,
  CLIENTES_FETCHING,
  CLIENTE_SUBMITTING,
  CLIENTE_SUBMIT,
  CLIENTE_SUBMIT_ERROR
} from "./actions"

const initialState = {
  data: [],
  loading: false
}
export function Cliente(state = initialState, action = {}) {
  switch (action.type) {
    case CLIENTE_FETCH:
      return Object.assign({}, state, {
        data: action.data
      })
    case NEW_CLIENTE:
      return Object.assign({}, state, {
        data: {
          id: null
        }
      })
    case CLIENTE_CLEAR:
      return Object.assign({}, state, initialState)
    default:
      return state
  }
}

export function Clientes(state = initialState, action = {}) {
  switch (action.type) {
    case CLIENTES_FETCH:
      return Object.assign({}, state, {
        data: action.data,
        loading: false
      })
    case CLIENTES_FETCHING:
      return Object.assign({}, state, {
        data: [],
        loading: true
      })
    default:
      return state
  }
}

const initialStateReg = {
  data: [],
  error: []
}
export function ClientesReg(state = initialStateReg, action = {}) {
  switch (action.type) {
    case CLIENTE_SUBMITTING:
      return Object.assign({}, state, initialStateReg)
    case CLIENTE_SUBMIT:
      return Object.assign({}, state, {
        data: action.data,
        error: []
      })
    case CLIENTE_SUBMIT_ERROR:
      return Object.assign({}, state, {
        data: [],
        error: action.error
      })
    default:
      return state
  }
}
