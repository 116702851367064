import React from "react"
import { useForm } from "react-hook-form"
import TextField from "@material-ui/core/TextField"
import PropTypes from "prop-types"

import { useSelector, useDispatch } from "react-redux"
// eslint-disable-next-line no-unused-vars
import { StatusSubmit, StatusDelete } from "./api/actions"

import CardHeader from "components/Card/CardHeader"
import CardBody from "components/Card/CardBody"
import CardFooter from "components/Card/CardFooter"
import SnackbarContent from "components/Snackbar/SnackbarContent"
import Button from "components/CustomButtons/Button"
import GridItem from "components/Grid/GridItem"
import GridContainer from "components/Grid/GridContainer"
import Card from "components/Card/Card"

const Form = props => {
  const StatusReg = useSelector(state => state.StatusReg)
  const { register, handleSubmit } = useForm({
    defaultValues: props.data
  })
  const dispatch = useDispatch()

  const onSubmit = async values => {
    values.slug = values.name
    dispatch(StatusSubmit(values))
  }

  const onDelete = () => {
    if (window.confirm("Confirma a exclusão do registro?"))
      dispatch(StatusDelete())
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <form onSubmit={handleSubmit(onSubmit)}>
            <CardHeader color="primary">
              <h3>{props.data.id ? "Editando Registro" : "Novo registro"}</h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {StatusReg.error.length !== 0 ? (
                  <GridItem xs={12} sm={12} md={12}>
                    <SnackbarContent message={StatusReg.error} color="danger" />
                  </GridItem>
                ) : (
                  ""
                )}
                {props.data.id ? (
                  <input
                    type="hidden"
                    name="id"
                    ref={register}
                    value={props.data.id}
                  />
                ) : (
                  ""
                )}
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    fullWidth={true}
                    label="Nome"
                    name="name"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button type="submit" color="primary">
                Salvar
              </Button>

              {props.data.id ? (
                <Button color="danger" onClick={() => onDelete()}>
                  Deletar
                </Button>
              ) : (
                ""
              )}
            </CardFooter>
          </form>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

Form.propTypes = {
  data: PropTypes.object.isRequired
}

export default Form
