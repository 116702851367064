export const FormatDate = data => {
  const dia = data.split("/")[0]
  const mes = data.split("/")[1]
  const ano = data.split("/")[2]

  return ano + "-" + ("0" + mes).slice(-2) + "-" + ("0" + dia).slice(-2)
}

export const FormatDateBR = inputFormat => {
  return inputFormat
    .split("-")
    .reverse()
    .join("/")
}

export const FormatMoney = money => {
  return money
    .replace("R$ ", "")
    .replace(".", "")
    .replace(",", ".")
}
