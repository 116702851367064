import React, { useEffect } from "react"
  import PropTypes from "prop-types"
  import { useSelector, useDispatch } from "react-redux"
  import { Videos_real_estateCleanSubmit, Videos_real_estateCleanForm, Videos_real_estat } from "./api/actions"
  import { Real_estate } from "../Real_estates/api/actions"

  import Form from "./Form"

  const Videos_real_estateForm = props => {
    const dispatch = useDispatch()
    const Videos_real_estateSt = useSelector(state => state.Videos_real_estat)

    useEffect(() => {
      dispatch(Videos_real_estateCleanSubmit())
      dispatch(Videos_real_estat(props.match.params.id))
      dispatch(Real_estate(props.match.params.top))

      return () => dispatch(Videos_real_estateCleanForm())
    }, [dispatch, props.match.params.id])

    return (
      <React.Fragment>
        {Videos_real_estateSt.data.length === 0 ? "" : <Form data={Videos_real_estateSt.data} />}
      </React.Fragment>
    )
  }

  Videos_real_estateForm.propTypes = {
    match: PropTypes.object
  }

  export default Videos_real_estateForm
  
