import React from "react"
import ReactDOM from "react-dom"
import { createBrowserHistory } from "history"
import { Router, Route, Switch, Redirect } from "react-router-dom"
import { Provider } from "react-redux"
import { store } from "setup/store"

import Admin from "layouts/Admin.js"
import Auth from "layouts/Auth.js"
import "assets/css/material-dashboard-react.css?v=1.8.0"

export const history = createBrowserHistory()

ReactDOM.render(
  <Provider store={store} key="provider">
    <Router history={history}>
      <Switch>
        <Route path="/admin" component={Admin} />
        <Route path="/auth" component={Auth} />
        <Redirect from="/" to="/auth/login" />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById("root")
)
