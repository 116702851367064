import {
  CITY_FETCH,
  CITY_CLEAR,
  NEW_CITY,
  CITYS_FETCH,
  CITYS_FETCHING,
  CITY_SUBMITTING,
  CITY_SUBMIT,
  CITY_SUBMIT_ERROR,
  ESTATES_FETCH
} from "./actions"

const initialState = {
  data: [],
  loading: false
}
export function City(state = initialState, action = {}) {
  switch (action.type) {
    case CITY_FETCH:
      return Object.assign({}, state, {
        data: action.data
      })
    case NEW_CITY:
      return Object.assign({}, state, {
        data: {
          id: null
        }
      })
    case CITY_CLEAR:
      return Object.assign({}, state, initialState)
    default:
      return state
  }
}

export function Citys(state = initialState, action = {}) {
  switch (action.type) {
    case CITYS_FETCH:
      return Object.assign({}, state, {
        data: action.data,
        loading: false
      })
    case CITYS_FETCHING:
      return Object.assign({}, state, {
        data: [],
        loading: true
      })
    default:
      return state
  }
}
export function Estates(state = initialState, action = {}) {
  switch (action.type) {
    case ESTATES_FETCH:
      return Object.assign({}, state, {
        data: action.data,
        loading: false
      })
    default:
      return state
  }
}

const initialStateReg = {
  data: [],
  error: []
}
export function CitysReg(state = initialStateReg, action = {}) {
  switch (action.type) {
    case CITY_SUBMITTING:
      return Object.assign({}, state, initialStateReg)
    case CITY_SUBMIT:
      return Object.assign({}, state, {
        data: action.data,
        error: []
      })
    case CITY_SUBMIT_ERROR:
      return Object.assign({}, state, {
        data: [],
        error: action.error
      })
    default:
      return state
  }
}
