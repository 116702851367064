import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import TextField from "@material-ui/core/TextField"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import InputLabel from "@material-ui/core/InputLabel"
import FormControl from "@material-ui/core/FormControl"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import CKEditor from "ckeditor4-react"

import { useSelector, useDispatch } from "react-redux"
// eslint-disable-next-line no-unused-vars
import { Real_estatesSubmit, Real_estatesDelete } from "./api/actions"

import CardHeader from "components/Card/CardHeader"
import CardBody from "components/Card/CardBody"
import CardFooter from "components/Card/CardFooter"
import SnackbarContent from "components/Snackbar/SnackbarContent"
import Button from "components/CustomButtons/Button"
import GridItem from "components/Grid/GridItem"
import GridContainer from "components/Grid/GridContainer"
import Card from "components/Card/Card"

import { API_BASE_URL } from "../../setup/store"

// money
import NumberFormatInput from "components/NumberFormat"
import { FormatDate, FormatMoney } from "../../setup/util"
// date
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers"
import LuxonUtils from "@date-io/luxon"

const styles = {
  formControl: {
    marginTop: "15px",
    minWidth: "100%"
  }
}
const useStyles = makeStyles(styles)

const Form = props => {
  const classes = useStyles()
  const Real_estatesReg = useSelector(state => state.Real_estatesReg)
  const { register, handleSubmit, setValue } = useForm({
    defaultValues: props.data
  })
  const dispatch = useDispatch()

  const onSubmit = async values => {
    values.date_cad = FormatDate(values.date_cad)
    values.slug = values.name

    values.value = FormatMoney(values.value)
    values.iptu = FormatMoney(values.iptu)
    values.condominium = FormatMoney(values.condominium)

    dispatch(Real_estatesSubmit(values))
  }

  const onDelete = () => {
    if (window.confirm("Confirma a exclusão do registro?"))
      dispatch(Real_estatesDelete())
  }

  // select type
  const typesSt = useSelector(state => state.Types)
  const [typeVal, setTypeVal] = React.useState(
    props.data.id ? props.data.type_id : ""
  )
  const handleChangeType = e => {
    setValue("type_id", e.target.value)
    setTypeVal(e.target.value)
  }

    // select operacao
    const operacaoSt = useSelector(state => state.Operacao)
    const [operacaoVal, setOperacaoVal] = React.useState(
      props.data.id ? props.data.operacao_id : ""
    )
    const handleChangeOperacao = e => {
      setValue("operacao_id", e.target.value)
      setOperacaoVal(e.target.value)
    }

  // select district
  const districtsSt = useSelector(state => state.Districts)
  const [districtVal, setDistrictVal] = React.useState(
    props.data.id ? props.data.district_id : ""
  )
  const handleChangeDistrict = e => {
    setValue("district_id", e.target.value)
    setDistrictVal(e.target.value)
  }

  // select city
  const citysSt = useSelector(state => state.Citys)
  const [cityVal, setCityVal] = React.useState(
    props.data.id ? props.data.city_id : ""
  )
  const handleChangeCity = e => {
    setValue("city_id", e.target.value)
    setCityVal(e.target.value)
  }

  // select status
  const statussSt = useSelector(state => state.Status)
  const [statusVal, setStatusVal] = React.useState(
    props.data.id ? props.data.status_id : ""
  )
  const handleChangeStatus = e => {
    setValue("status_id", e.target.value)
    setStatusVal(e.target.value)
  }

  // select broker
  const brokersSt = useSelector(state => state.Brokers)
  const [brokerVal, setBrokerVal] = React.useState(
    props.data.id ? props.data.broker_id : ""
  )
  const handleChangeBroker = e => {
    setValue("broker_id", e.target.value)
    setBrokerVal(e.target.value)
  }

  // currency
  const [values, setValues] = React.useState({
    value: props.data.id ? props.data.value : null,
    iptu: props.data.id ? props.data.iptu : null,
    condominium: props.data.id ? props.data.condominium : null
  })
  const handleChangeMoney = name => event => {
    setValues({
      ...values,
      [name]: event.target.value
    })
  }

  // date
  const [selectedDate_cad, setSelectedDate_cad] = React.useState(
    props.data.date_cad ? props.data.date_cad + " 00:00" : new Date()
  )

  const handleChangeImg = e => {
    setValue("img", e.target.files[0])
  }
  const handleChangeImg2 = e => {
    setValue("img2", e.target.files[0])
  }
  const handleChangeImgCapa = e => {
    setValue("img_capa", e.target.files[0])
  }

  //text

  const [descriptionVal, setDescVal] = React.useState(
    props.data.description ? props.data.description : ""
  )

  const handleChangeDescription = e => {
    setDescVal(e.editor.getData())
    setValue("description", e.editor.getData())
  }

  const table =
    "<table><tr><td>Entrada Fixa</td><td>R$</td></tr><tr><td>Parcelas de 30 dias do contrato fixa</td><td>R$</td></tr><tr><td>Financiamento em 15/03/2020 fixo</td><td>R$</td></tr><tr><td>Total</td><td>R$</td></tr></table>"

  const [financingVal, setFinancingVal] = React.useState(
    props.data.financing ? props.data.financing : table
  )

  const handleChangeFinancing = e => {
    setFinancingVal(e.editor.getData())
    setValue("financing", e.editor.getData())
  }

  useEffect(() => {
    register({ name: "img" })
    register({ name: "img2" })
    register({ name: "img_capa" })
    register({ name: "date_cad", required: true })
    register({ name: "type_id", required: true })
    register({ name: "district_id", required: true })
    register({ name: "city_id", required: true })
    register({ name: "status_id", required: true })
    register({ name: "operacao_id", required: true })
    register({ name: "broker_id" })
    register({ name: "description" })
    register({ name: "financing" })
  }, [register])

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <form onSubmit={handleSubmit(onSubmit)}>
            <CardHeader color="primary">
              <h3>{props.data.id ? "Editando Registro" : "Novo registro"}</h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {Real_estatesReg.error.length !== 0 ? (
                  <GridItem xs={12} sm={12} md={12}>
                    <SnackbarContent
                      message={Real_estatesReg.error}
                      color="danger"
                    />
                  </GridItem>
                ) : (
                  ""
                )}
                {props.data.id ? (
                  <input
                    type="hidden"
                    name="id"
                    ref={register}
                    value={props.data.id}
                  />
                ) : (
                  ""
                )}
                <GridItem xs={12} sm={12} md={8}>
                  <TextField
                    fullWidth={true}
                    label="Nome"
                    name="name"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <TextField
                    fullWidth={true}
                    label="Cód."
                    name="cod"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <TextField
                    fullWidth={true}
                    label="Destaque"
                    name="detach"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={8}>
                  <TextField
                    fullWidth={true}
                    label="Endereço"
                    name="address"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Complemento"
                    name="address2"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <TextField
                    fullWidth={true}
                    label="CEP"
                    name="cep"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel>Status</InputLabel>
                    {statussSt.data.length === 0 ? (
                      ""
                    ) : (
                      <Select
                        name="status"
                        autoWidth={true}
                        fullWidth={true}
                        labelWidth={50}
                        value={statusVal}
                        onChange={handleChangeStatus}
                      >
                        {statussSt.data.map((item, i) => (
                          <MenuItem value={item.id} key={i}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </FormControl>
                </GridItem>

                <GridItem xs={12} sm={12} md={1}>
                  <TextField
                    fullWidth={true}
                    label="Quartos"
                    name="bedrooms"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={1}>
                  <TextField
                    fullWidth={true}
                    label="Suítes"
                    name="suites"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <TextField
                    fullWidth={true}
                    label="Banheiros"
                    name="toilets"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <TextField
                    fullWidth={true}
                    label="Vagas"
                    name="vacancies"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <TextField
                    fullWidth={true}
                    label="Área m2"
                    name="area"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel>Categoria</InputLabel>
                    {typesSt.data.length === 0 ? (
                      ""
                    ) : (
                      <Select
                        name="type"
                        autoWidth={true}
                        fullWidth={true}
                        labelWidth={70}
                        value={typeVal}
                        onChange={handleChangeType}
                      >
                        {typesSt.data.map((item, i) => (
                          <MenuItem value={item.id} key={i}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel>Bairro</InputLabel>
                    {districtsSt.data.length === 0 ? (
                      ""
                    ) : (
                      <Select
                        name="district"
                        autoWidth={true}
                        fullWidth={true}
                        labelWidth={45}
                        value={districtVal}
                        onChange={handleChangeDistrict}
                      >
                        {districtsSt.data.map((item, i) => (
                          <MenuItem value={item.id} key={i}>
                            {item.name} - {item.city.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel>Finalidade</InputLabel>
                    {operacaoSt.data.length === 0 ? (
                      ""
                    ) : (
                      <Select
                        name="operacao"
                        autoWidth={true}
                        fullWidth={true}
                        labelWidth={85}
                        value={operacaoVal}
                        onChange={handleChangeOperacao}
                      >
                        {operacaoSt.data.map((item, i) => (
                          <MenuItem value={item.id} key={i}>
                            {item.name} 
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel>Cidade</InputLabel>
                    {citysSt.data.length === 0 ? (
                      ""
                    ) : (
                      <Select
                        name="city"
                        autoWidth={true}
                        fullWidth={true}
                        labelWidth={50}
                        value={cityVal}
                        onChange={handleChangeCity}
                      >
                        {citysSt.data.map((item, i) => (
                          <MenuItem value={item.id} key={i}>
                            {item.name} - {item.estate.Uf}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </FormControl>
                </GridItem>

                <GridItem xs={12} sm={12} md={5}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel>Corretor</InputLabel>
                    {brokersSt.data.length === 0 ? (
                      ""
                    ) : (
                      <Select
                        name="broker"
                        autoWidth={true}
                        fullWidth={true}
                        labelWidth={58}
                        value={brokerVal}
                        onChange={handleChangeBroker}
                      >
                        {brokersSt.data.map((item, i) => (
                          <MenuItem value={item.id} key={i}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </FormControl>
                </GridItem>

                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Valor"
                    name="value"
                    margin="normal"
                    variant="outlined"
                    inputRef={register}
                    className={classes.formControl}
                    value={values.value}
                    onChange={handleChangeMoney("value")}
                    InputProps={{
                      inputComponent: NumberFormatInput
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Iptu"
                    name="iptu"
                    margin="normal"
                    variant="outlined"
                    inputRef={register}
                    className={classes.formControl}
                    value={values.iptu}
                    onChange={handleChangeMoney("iptu")}
                    InputProps={{
                      inputComponent: NumberFormatInput
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    fullWidth={true}
                    label="Condomínio"
                    name="condominium"
                    margin="normal"
                    variant="outlined"
                    inputRef={register}
                    className={classes.formControl}
                    value={values.condominium}
                    onChange={handleChangeMoney("condominium")}
                    InputProps={{
                      inputComponent: NumberFormatInput
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={4}>
                  <MuiPickersUtilsProvider utils={LuxonUtils}>
                    <DatePicker
                      fullWidth={true}
                      name="date_cad"
                      margin="normal"
                      label="Data cad."
                      format="dd/MM/yyyy"
                      inputVariant="outlined"
                      value={selectedDate_cad}
                      inputRef={register}
                      onChange={setSelectedDate_cad}
                    />
                  </MuiPickersUtilsProvider>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                    fullWidth={true}
                    label="Código Google Maps"
                    name="map"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={12}>
                  <InputLabel style={{ margin: "10px 0" }}>
                    Descrição
                  </InputLabel>
                  <CKEditor
                    onBeforeLoad={CKEDITOR =>
                      (CKEDITOR.disableAutoInline = true)
                    }
                    data={descriptionVal}
                    onChange={handleChangeDescription}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <br />
                  <InputLabel style={{ margin: "10px 0" }}>
                    Financiamento
                  </InputLabel>
                  <CKEditor
                    onBeforeLoad={CKEDITOR =>
                      (CKEDITOR.disableAutoInline = true)
                    }
                    data={financingVal}
                    onChange={handleChangeFinancing}
                  />
                </GridItem>
              </GridContainer>
              {props.data.id ? (
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <GridItem xs={12} sm={12} md={12}>
                      <h3>Img. Listagem:</h3>
                      <FormControl className={classes.formControl}>
                        <label className="img">Enviar Img.</label>
                        <input
                          accept="image/*"
                          onChange={handleChangeImg}
                          type="file"
                        />
                        <br />
                        {props.data.img ? (
                          <img
                            src={`${API_BASE_URL}uploads/real_estate/${props.data.img}`}
                            style={{ maxWidth: "100%" }}
                          />
                        ) : (
                          ""
                        )}
                      </FormControl>
                    </GridItem>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <GridItem xs={12} sm={12} md={12}>
                      <h3>2 Img. Listagem:</h3>
                      <FormControl className={classes.formControl}>
                        <label className="img">Enviar Img.</label>
                        <input
                          accept="image/*"
                          onChange={handleChangeImg2}
                          type="file"
                        />
                        <br />
                        {props.data.img2 ? (
                          <img
                            src={`${API_BASE_URL}uploads/real_estate/${props.data.img2}`}
                            style={{ maxWidth: "100%" }}
                          />
                        ) : (
                          ""
                        )}
                      </FormControl>
                    </GridItem>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <h3>Img. Capa(Topo):</h3>
                    <FormControl className={classes.formControl}>
                      <label className="img">Enviar Img.</label>
                      <input
                        accept="image/*"
                        onChange={handleChangeImgCapa}
                        type="file"
                      />
                      <br />
                      {props.data.img_capa ? (
                        <img
                          src={`${API_BASE_URL}uploads/real_estate/${props.data.img_capa}`}
                          style={{ maxWidth: "100%" }}
                        />
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </GridItem>
                </GridContainer>
              ) : (
                ""
              )}
            </CardBody>
            <CardFooter>
              <Button type="submit" color="primary">
                Salvar
              </Button>
              {props.data.id ? (
                <Button color="danger" onClick={() => onDelete()}>
                  Deletar
                </Button>
              ) : (
                ""
              )}
            </CardFooter>
          </form>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

Form.propTypes = {
  data: PropTypes.object.isRequired
}

export default Form
