import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import { useDispatch } from "react-redux"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import MenuItem from "@material-ui/core/MenuItem"
import MenuList from "@material-ui/core/MenuList"
import Grow from "@material-ui/core/Grow"
import Paper from "@material-ui/core/Paper"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import Hidden from "@material-ui/core/Hidden"
import Poppers from "@material-ui/core/Popper"
import Divider from "@material-ui/core/Divider"

import { history } from "../../index"

import Button from "components/CustomButtons/Button"
import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle"
import { logout } from "../../views/Login/api/actions"
const useStyles = makeStyles(styles)

export default function AdminNavbarLinks(props) {
  const classes = useStyles()
  const [openProfile, setOpenProfile] = React.useState(null)
  const dispatch = useDispatch()

  const handleClickProfile = event => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null)
    } else {
      setOpenProfile(event.currentTarget)
    }
  }
  const handleCloseProfile = () => {
    history.push("/admin/user-list")
    setOpenProfile(null)
    props.handleDrawerToggle()
  }

  const handleLogout = () => {
    dispatch(logout())
    setOpenProfile(null)
    props.handleDrawerToggle()
  }
  return (
    <div>
      <div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openProfile ? "profile-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={classes.buttonLink}
        >
          <svg
            fill="#797979"
            xmlns="http://www.w3.org/2000/svg"
            width="35"
            height="35"
            viewBox="0 0 18 18"
          >
            <path d="M9 10c-2.33 0-7 1.17-7 3.5V16h14v-2.5c0-2.33-4.67-3.5-7-3.5zm5.29 4.29H3.71v-.79c.1-.49 2.59-1.79 5.29-1.79s5.19 1.3 5.29 1.79v.79zM9 9c2.22 0 4-1.79 4-4s-1.78-4-4-4C6.79 1 5.01 2.79 5.01 5S6.79 9 9 9zm0-6.29c1.26 0 2.28 1.03 2.28 2.29S10.26 7.29 9 7.29C7.75 7.29 6.72 6.26 6.72 5S7.75 2.71 9 2.71z" />
          </svg>
          <Hidden mdUp implementation="css">
            <p className={classes.linkText}>Profile</p>
          </Hidden>
        </Button>
        <Poppers
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openProfile }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom"
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    <MenuItem
                      onClick={handleCloseProfile}
                      className={classes.dropdownItem}
                    >
                      Meus dados
                    </MenuItem>
                    <Divider light />
                    <MenuItem
                      onClick={handleLogout}
                      className={classes.dropdownItem}
                    >
                      Logout
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
    </div>
  )
}

AdminNavbarLinks.propTypes = {
  handleDrawerToggle: PropTypes.func
}
