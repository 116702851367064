import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { makeStyles } from "@material-ui/core/styles"
import { useForm } from "react-hook-form"

import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Button from "components/CustomButtons/Button"
import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody"
import CardHeader from "components/Card/CardHeader"
import CardFooter from "components/Card/CardFooter"
import TextField from "@material-ui/core/TextField"

import { login } from "./api/actions"
import logo from "assets/img/logo.png"

import loginPageStyle from "assets/jss/material-dashboard-react/views/loginPageStyle"
const useStyles = makeStyles(loginPageStyle)

const LoginPage = () => {
  const auth = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const classes = useStyles()
  const { register, handleSubmit } = useForm()

  const onSubmit = async values => {
    dispatch(login(values.username, values.password))
  }

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={8}>
          <img src={logo} alt="logo" className={classes.imgLogo} />
        </GridItem>
      </GridContainer>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Card>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="primary"
              >
                <h4 className={classes.cardTitle}>Log in</h4>
              </CardHeader>
              <CardBody>
                {auth.error ? <h4>E-mail ou senha inválida</h4> : ""}
                <TextField
                  fullWidth={true}
                  required={true}
                  autoFocus={true}
                  label="E-mail"
                  name="username"
                  margin="normal"
                  autoComplete="username"
                  variant="outlined"
                  inputRef={register}
                  error={auth.error}
                />
                <TextField
                  fullWidth={true}
                  required={true}
                  label="Senha"
                  name="password"
                  autoComplete="current-password"
                  type="password"
                  margin="normal"
                  inputRef={register}
                  variant="outlined"
                  error={auth.error}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button type="submit" color="primary" size="lg" block>
                  Enviar
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  )
}

export default LoginPage
