import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"
import { TypesCleanSubmit, TypesCleanForm, Type } from "./api/actions"

import Form from "./Form"

const TypesForm = props => {
  const dispatch = useDispatch()
  const TypesSt = useSelector(state => state.Type)

  useEffect(() => {
    dispatch(TypesCleanSubmit())
    dispatch(Type(props.match.params.id))

    return () => dispatch(TypesCleanForm())
  }, [dispatch, props.match.params.id])

  return (
    <React.Fragment>
      {TypesSt.data.length === 0 ? "" : <Form data={TypesSt.data} />}
    </React.Fragment>
  )
}

TypesForm.propTypes = {
  match: PropTypes.object
}

export default TypesForm
