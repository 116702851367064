import {
  STATUS_LEAD_FETCH,
  STATUS_LEAD_CLEAR,
  NEW_STATUS_LEAD,
  STATUS_LEADS_FETCH,
  STATUS_LEADS_FETCHING,
  STATUS_LEAD_SUBMITTING,
  STATUS_LEAD_SUBMIT,
  STATUS_LEAD_SUBMIT_ERROR
} from "./actions"

const initialState = {
  data: [],
  loading: false
}
export function Status_Lead(state = initialState, action = {}) {
  switch (action.type) {
    case STATUS_LEAD_FETCH:
      return Object.assign({}, state, {
        data: action.data
      })
    case NEW_STATUS_LEAD:
      return Object.assign({}, state, {
        data: {
          id: null
        }
      })
    case STATUS_LEAD_CLEAR:
      return Object.assign({}, state, initialState)
    default:
      return state
  }
}

export function Status_Leads(state = initialState, action = {}) {
  switch (action.type) {
    case STATUS_LEADS_FETCH:
      return Object.assign({}, state, {
        data: action.data,
        loading: false
      })
    case STATUS_LEADS_FETCHING:
      return Object.assign({}, state, {
        data: [],
        loading: true
      })
    default:
      return state
  }
}

const initialStateReg = {
  data: [],
  error: []
}
export function Status_LeadsReg(state = initialStateReg, action = {}) {
  switch (action.type) {
    case STATUS_LEAD_SUBMITTING:
      return Object.assign({}, state, initialStateReg)
    case STATUS_LEAD_SUBMIT:
      return Object.assign({}, state, {
        data: action.data,
        error: []
      })
    case STATUS_LEAD_SUBMIT_ERROR:
      return Object.assign({}, state, {
        data: [],
        error: action.error
      })
    default:
      return state
  }
}
