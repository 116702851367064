import {
  OPERACA_FETCH,
  OPERACA_CLEAR,
  NEW_OPERACA,
  OPERACAO_FETCH,
  OPERACAO_FETCHING,
  OPERACA_SUBMITTING,
  OPERACA_SUBMIT,
  OPERACA_SUBMIT_ERROR,
} from "./actions"

const initialState = {
  data: [],
  loading: false,
}
export function Operaca(state = initialState, action = {}) {
  switch (action.type) {
    case OPERACA_FETCH:
      return Object.assign({}, state, {
        data: action.data,
      })
    case NEW_OPERACA:
      return Object.assign({}, state, {
        data: {
          id: null,
        },
      })
    case OPERACA_CLEAR:
      return Object.assign({}, state, initialState)
    default:
      return state
  }
}

export function Operacao(state = initialState, action = {}) {
  switch (action.type) {
    case OPERACAO_FETCH:
      return Object.assign({}, state, {
        data: action.data,
        loading: false,
      })
    case OPERACAO_FETCHING:
      return Object.assign({}, state, {
        data: [],
        loading: true,
      })
    default:
      return state
  }
}

const initialStateReg = {
  data: [],
  error: [],
}
export function OperacaoReg(state = initialStateReg, action = {}) {
  switch (action.type) {
    case OPERACA_SUBMITTING:
      return Object.assign({}, state, initialStateReg)
    case OPERACA_SUBMIT:
      return Object.assign({}, state, {
        data: action.data,
        error: [],
      })
    case OPERACA_SUBMIT_ERROR:
      return Object.assign({}, state, {
        data: [],
        error: action.error,
      })
    default:
      return state
  }
}
