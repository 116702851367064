import axios from "axios"
import { API_URL, AUTH_TOKEN, LOGIN } from "../../../setup/store"
import { history } from "../../../index"

// select
// import { s } from "../..//api/actions"

export const STATUS_FETCH = "STATUS_FETCH"
export const STATUS_FETCHING = "STATUS_FETCHING"

export const NEW_STATU = "NEW_STATU"
export const STATU_FETCH = "STATU_FETCH"
export const STATU_CLEAR = "STATU_CLEAR"

export const STATU_SUBMITTING = "STATU_SUBMITTING"
export const STATU_SUBMIT = "STATU_SUBMIT"
export const STATU_SUBMIT_ERROR = "STATU_SUBMIT_ERROR"

const URL_REDIRECT = "/admin/status"
const API_TYPE = "status"

export const Status = () => {
  return async dispatch => {
    dispatch({
      type: STATUS_FETCHING
    })
    try {
      const res = await axios.get(`${API_URL}${API_TYPE}`, {
        headers: {
          Authorization: AUTH_TOKEN
        }
      })

      dispatch({
        type: STATUS_FETCH,
        data: res.data
      })
    } catch (error) {
      window.location.href = LOGIN
      console.error(error)
    }
  }
}

export const Statu = id => {
  return async dispatch => {
    // select
    //dispatch(())

    if (id) {
      try {
        const res = await axios.get(`${API_URL}${API_TYPE}/${id}`, {
          headers: {
            Authorization: AUTH_TOKEN
          }
        })
        dispatch({
          type: STATU_FETCH,
          data: res.data
        })
      } catch (error) {
        window.location.href = LOGIN
        console.log(error.message)
      }
    } else {
      dispatch({
        type: NEW_STATU
      })
    }
  }
}

export const StatusCleanSubmit = () => {
  return async dispatch => {
    dispatch({
      type: STATU_SUBMITTING
    })
  }
}

export const StatusCleanForm = () => {
  return async dispatch => {
    dispatch({
      type: STATU_CLEAR
    })
  }
}

export const StatusDelete = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: STATU_SUBMITTING
    })
    const ID = getState().Statu.data.id
    try {
      const res = await axios.delete(`${API_URL}${API_TYPE}/${ID}`, {
        headers: {
          Authorization: AUTH_TOKEN
        }
      })
      dispatch({
        type: STATU_SUBMIT,
        data: res.data
      })
      history.push(URL_REDIRECT)
    } catch (error) {
      console.log(error.message)
    }
  }
}

export const StatusSubmit = status => {
  return async dispatch => {
    dispatch({
      type: STATU_SUBMITTING
    })
    try {
      let res

      if (status.id) {
        res = await axios.put(`${API_URL}${API_TYPE}/${status.id}`, status, {
          headers: {
            Authorization: AUTH_TOKEN
          }
        })
      } else {
        res = await axios.post(`${API_URL}${API_TYPE}/`, status, {
          headers: {
            Authorization: AUTH_TOKEN
          }
        })
      }

      dispatch({
        type: STATU_SUBMIT,
        data: res.data
      })
      history.push(URL_REDIRECT)
    } catch (error) {
      dispatch({
        type: STATU_SUBMIT_ERROR,
        error: "Este nome já foi cadastrado, Por favor escolha um diferente..."
      })
    }
  }
}
