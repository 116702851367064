import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"
import {
  Img_real_estateCleanSubmit,
  Img_real_estateCleanForm,
  Img_real_estat
} from "./api/actions"
import { Real_estate } from "../Real_estates/api/actions"

import Form from "./Form"

const Img_real_estateForm = props => {
  const dispatch = useDispatch()
  const Img_real_estateSt = useSelector(state => state.Img_real_estat)

  useEffect(() => {
    dispatch(Img_real_estateCleanSubmit())
    dispatch(Img_real_estat(props.match.params.id))
    dispatch(Real_estate(props.match.params.top))

    return () => dispatch(Img_real_estateCleanForm())
  }, [dispatch, props.match.params.id])

  return (
    <React.Fragment>
      {Img_real_estateSt.data.length === 0 ? (
        ""
      ) : (
        <Form data={Img_real_estateSt.data} />
      )}
    </React.Fragment>
  )
}

Img_real_estateForm.propTypes = {
  match: PropTypes.object
}

export default Img_real_estateForm
