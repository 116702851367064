import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"
import { OperacaoCleanSubmit, OperacaoCleanForm, Operaca } from "./api/actions"

import Form from "./Form"

const OperacaoForm = (props) => {
  const dispatch = useDispatch()
  const OperacaoSt = useSelector((state) => state.Operaca)

  useEffect(() => {
    dispatch(OperacaoCleanSubmit())
    
    dispatch(Operaca(props.match.params.id))

    return () => dispatch(OperacaoCleanForm())
  }, [dispatch, props.match.params.id])

  return (
    <React.Fragment>
      {OperacaoSt.data.length === 0 ? "" : <Form data={OperacaoSt.data} />}
    </React.Fragment>
  )
}

OperacaoForm.propTypes = {
  match: PropTypes.object,
}

export default OperacaoForm
