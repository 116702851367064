import { API_URL, AUTH_TOKEN, LOGIN } from "../../../setup/store"
import axios from "axios"

export const DASHBOARD_FETCH = "DASHBOARD_FETCH"
export const DASHBOARD_FETCHING = "DASHBOARD_FETCHING"

export const dashboard = () => {
  return async dispatch => {
    dispatch({
      type: DASHBOARD_FETCHING
    })
    try {
      const res = await axios.get(`${API_URL}dashboard`, {
        headers: {
          Authorization: AUTH_TOKEN
        }
      })
      dispatch({
        type: DASHBOARD_FETCH,
        data: res.data
      })
    } catch (error) {
      window.location.href = LOGIN
      console.error(error)
    }
  }
}
