import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"
import {
  AttributesCleanSubmit,
  AttributesCleanForm,
  Attribute
} from "./api/actions"

import Form from "./Form"

const AttributesForm = props => {
  const dispatch = useDispatch()
  const AttributesSt = useSelector(state => state.Attribute)

  useEffect(() => {
    dispatch(AttributesCleanSubmit())
    dispatch(Attribute(props.match.params.id))

    return () => dispatch(AttributesCleanForm())
  }, [dispatch, props.match.params.id])

  return (
    <React.Fragment>
      {AttributesSt.data.length === 0 ? "" : <Form data={AttributesSt.data} />}
    </React.Fragment>
  )
}

AttributesForm.propTypes = {
  match: PropTypes.object
}

export default AttributesForm
