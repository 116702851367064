import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import TextField from "@material-ui/core/TextField"
import PropTypes from "prop-types"

import { useSelector, useDispatch } from "react-redux"
// eslint-disable-next-line no-unused-vars
import { CitysSubmit, CitysDelete } from "./api/actions"

import CardHeader from "components/Card/CardHeader"
import CardBody from "components/Card/CardBody"
import CardFooter from "components/Card/CardFooter"
import SnackbarContent from "components/Snackbar/SnackbarContent"
import Button from "components/CustomButtons/Button"
import GridItem from "components/Grid/GridItem"
import GridContainer from "components/Grid/GridContainer"
import Card from "components/Card/Card"

import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import InputLabel from "@material-ui/core/InputLabel"
import FormControl from "@material-ui/core/FormControl"
import { makeStyles } from "@material-ui/core/styles"

const styles = {
  formControl: {
    marginTop: "15px",
    minWidth: "100%"
  }
}
const useStyles = makeStyles(styles)

const Form = props => {
  const classes = useStyles()
  const CitysReg = useSelector(state => state.CitysReg)
  const { register, handleSubmit, setValue } = useForm({
    defaultValues: props.data
  })
  const dispatch = useDispatch()

  const onSubmit = async values => {
    values.slug = values.name
    dispatch(CitysSubmit(values))
  }

  const onDelete = () => {
    if (window.confirm("Confirma a exclusão do registro?"))
      dispatch(CitysDelete())
  }

  // select
  const estatesSt = useSelector(state => state.Estates)
  const [estateVal, setEstateVal] = React.useState(
    props.data.id ? props.data.estate_id : ""
  )
  const handleChangeStatus = e => {
    setValue("estate_id", e.target.value)
    setEstateVal(e.target.value)
  }

  useEffect(() => {
    register({ name: "estate_id" })
  }, [register])

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <form onSubmit={handleSubmit(onSubmit)}>
            <CardHeader color="primary">
              <h3>{props.data.id ? "Editando Registro" : "Novo registro"}</h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {CitysReg.error.length !== 0 ? (
                  <GridItem xs={12} sm={12} md={12}>
                    <SnackbarContent message={CitysReg.error} color="danger" />
                  </GridItem>
                ) : (
                  ""
                )}
                {props.data.id ? (
                  <input
                    type="hidden"
                    name="id"
                    ref={register}
                    value={props.data.id}
                  />
                ) : (
                  ""
                )}
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    fullWidth={true}
                    label="Nome"
                    name="name"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel>Estado</InputLabel>
                    {estatesSt.data.length === 0 ? (
                      ""
                    ) : (
                      <Select
                        autoWidth={true}
                        fullWidth={true}
                        labelWidth={50}
                        value={estateVal}
                        onChange={handleChangeStatus}
                      >
                        {estatesSt.data.map((item, i) => (
                          <MenuItem value={item.id} key={i}>
                            {item.Nome}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                    fullWidth={true}
                    label="text"
                    name="text"
                    inputRef={register}
                    margin="normal"
                    variant="outlined"
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button type="submit" color="primary">
                Salvar
              </Button>

              {props.data.id ? (
                <Button color="danger" onClick={() => onDelete()}>
                  Deletar
                </Button>
              ) : (
                ""
              )}
            </CardFooter>
          </form>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

Form.propTypes = {
  data: PropTypes.object.isRequired
}

export default Form
