/*eslint-disable*/
import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import { NavLink } from "react-router-dom"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import Drawer from "@material-ui/core/Drawer"
import Hidden from "@material-ui/core/Hidden"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Collapse from "@material-ui/core/Collapse"
import Icon from "@material-ui/core/Icon"
// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.js"

import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js"

const useStyles = makeStyles(styles)

const NestedList = (props) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  const handleOpen = () => {
    setOpen(!open)
  }

  const handleDrawerToggle = () => {
    props.handleDrawerToggle()
  }

  const activeRoute = (routeName) => {    
    return window.location.href.indexOf(routeName) > -1 ? true : false
  }

  return (
    <React.Fragment>
      <NavLink
        to={props.to}
        activeClassName="active"
        className={" " + classes.item}
        onClick={props.children.length > 0 ? handleOpen : handleDrawerToggle}
      >
        <ListItem button className={classes.itemLink + classNames({[" " + classes["blue"]]: activeRoute(props.to)})}>
          <Icon className={classes.itemIcon}>
            {props.icon}
          </Icon>
          <ListItemText
            primary={props.name}
            className={classNames(classes.itemText)}
            disableTypography={true}
          />
        </ListItem>
      </NavLink>
      {
        props.children.length > 0 ?
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List className={classes.list}>
            {
              props.children.map((item, key) => {
                if (!item.register) {
                  return(<NavLink
                    to={item.layout + item.path}
                    activeClassName="active"
                    className={" " + classes.item}
                    onClick={handleDrawerToggle}
                    key={key}
                  >
                    <ListItem button className={classes.itemLink + " " + classes.itemSubLink + classNames({[" " + classes["blue"]]: activeRoute(item.layout + item.path)}) }>
                      <Icon className={classes.itemIcon}>
                        {item.icon}
                      </Icon>
                      <ListItemText
                        primary={item.name}
                        className={classNames(classes.itemText)}
                        disableTypography={true}
                      />
                    </ListItem>
                  </NavLink>)
                } else {
                  return ('')
                }
              })
            }
        </List>
      </Collapse>
      : null
      }
    </React.Fragment>
  )
}

export default function Sidebar(props) {
  const classes = useStyles()
  const { logo, image, logoText, routes } = props

  const links = (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        if (prop.layout === "/auth") {
          return ""
        } else {
          return (
            <NestedList
               to={prop.layout + prop.path}
               className={" " + classes.item}
               handleDrawerToggle={props.handleDrawerToggle}
               name={prop.name}
               icon={prop.icon}
               children={prop.children}
               key={key}
             />
         )
        }
      })}
    </List>
  )
  var brand = (
    <div className={classes.logo}>
      <a
        href="/admin/dashboard"
        className={classNames(classes.logoLink)}
      >
        <div className={classes.logoImage}>
          <img src={logo} alt="logo" className={classes.img} />
        </div>
      </a>
    </div>
  )
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={"right"}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper)
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true 
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            <AdminNavbarLinks handleDrawerToggle={props.handleDrawerToggle} />
            {links}
          </div>
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor={"left"}
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper)
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  )
}

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool
}
