import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import Icon from "@material-ui/core/Icon"
import Hidden from "@material-ui/core/Hidden"
// @material-ui/icons
// core components
import AdminNavbarLinks from "./AdminNavbarLinks.js"

import styles from "assets/jss/material-dashboard-react/components/headerStyle.js"

const useStyles = makeStyles(styles)

export default function Header(props) {
  const classes = useStyles()
  function makeLabel(routes) {
    let name

    routes.forEach(e => {
      if (props.location.pathname === e.layout + e.path) {
        name = e.name
      }

      e.children.forEach(c => {
        if (
          (c.layout + c.path).split("/")[2] ===
          props.location.pathname.split("/")[2]
        ) {
          name = c.name
        }
      })
    })
    return name
  }
  const { color } = props
  const appBarClasses = classNames({
    [" " + classes[color]]: color
  })

  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <div className={classes.flex}>
          <h3 className={classes.title}>{makeLabel(props.routes)}</h3>
        </div>
        <Hidden smDown implementation="css">
          <AdminNavbarLinks handleDrawerToggle={props.handleDrawerToggle} />
        </Hidden>
        <Hidden mdUp implementation="css">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Icon>menu</Icon>
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  )
}

Header.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  location: PropTypes.object.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object)
}
