import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"
import {
  Image_typesCleanSubmit,
  Image_typesCleanForm,
  Image_type
} from "./api/actions"

import Form from "./Form"

const Image_typesForm = props => {
  const dispatch = useDispatch()
  const Image_typesSt = useSelector(state => state.Image_type)

  useEffect(() => {
    dispatch(Image_typesCleanSubmit())
    dispatch(Image_type(props.match.params.id))

    return () => dispatch(Image_typesCleanForm())
  }, [dispatch, props.match.params.id])

  return (
    <React.Fragment>
      {Image_typesSt.data.length === 0 ? (
        ""
      ) : (
        <Form data={Image_typesSt.data} />
      )}
    </React.Fragment>
  )
}

Image_typesForm.propTypes = {
  match: PropTypes.object
}

export default Image_typesForm
