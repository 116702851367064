import axios from "axios"
import { API_URL, AUTH_TOKEN, LOGIN } from "../../../setup/store"

export const AUTHENTICATED_USER = "AUTHENTICATED_USER"
export const UNAUTHENTICATED_USER = "UNAUTHENTICATED_USER"
export const CHECK_USER = "CHECK_USER"
export const AUTHENTICATED_USER_DETAILS = "AUTHENTICATED_USER_DETAILS"

export function login(email, password) {
  return async dispatch => {
    try {
      const res = await axios.post(`${API_URL}login`, {
        email,
        password
      })
      dispatch({
        type: AUTHENTICATED_USER
      })
      localStorage.setItem("token", "")
      localStorage.setItem("token", res.data.token)
      localStorage.setItem("user", res.data.username)
      localStorage.setItem("type", res.data.type)
      window.location.href = "/admin/dashboard"
    } catch (error) {
      dispatch({
        type: UNAUTHENTICATED_USER
      })
      localStorage.setItem("token", "")
      localStorage.setItem("user", "")
      localStorage.setItem("user", "")
    }
  }
}

export function logout() {
  return async dispatch => {
    dispatch({
      type: UNAUTHENTICATED_USER
    })
    try {
      await axios.post(
        `${API_URL}logout/`,
        {},
        {
          headers: {
            Authorization: AUTH_TOKEN
          }
        }
      )
    } catch (error) {
      console.log(error)
    }

    localStorage.setItem("token", "")
    localStorage.setItem("user", "")
    localStorage.setItem("user", "")

    window.location.href = "/auth/login"
  }
}

export function user() {
  return async dispatch => {
    try {
      const res = await axios.post(
        `${API_URL}user`,
        {},
        {
          headers: {
            Authorization: AUTH_TOKEN
          }
        }
      )
      dispatch({
        type: AUTHENTICATED_USER_DETAILS,
        data: res.data
      })
    } catch (error) {
      localStorage.setItem("token", "")
      localStorage.setItem("user", "")
      localStorage.setItem("type", "")
      window.location.href = LOGIN
    }
  }
}
