import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import Snack from "@material-ui/core/SnackbarContent"
import IconButton from "@material-ui/core/IconButton"
// core components
import Icon from "@material-ui/core/Icon"
import styles from "assets/jss/material-dashboard-react/components/snackbarContentStyle.js"

const useStyles = makeStyles(styles)

export default function SnackbarContent(props) {
  const classes = useStyles()
  const { message, color, close, icon, rtlActive } = props
  var action = []
  const messageClasses = classNames({
    [classes.iconMessage]: icon !== undefined
  })
  if (close !== undefined) {
    action = [
      <IconButton
        className={classes.iconButton}
        key="close"
        aria-label="Close"
        color="inherit"
      >
        <Icon>close</Icon>
      </IconButton>
    ]
  }
  return (
    <Snack
      message={
        <div>
          {icon !== undefined ? <props.icon className={classes.icon} /> : null}
          <span className={messageClasses}>{message}</span>
        </div>
      }
      classes={{
        root: classes.root + " " + classes[color],
        message: classes.message,
        action: classNames({ [classes.actionRTL]: rtlActive })
      }}
      action={action}
    />
  )
}

SnackbarContent.propTypes = {
  message: PropTypes.node.isRequired,
  color: PropTypes.oneOf(["info", "success", "warning", "danger", "primary"]),
  close: PropTypes.bool,
  icon: PropTypes.object,
  rtlActive: PropTypes.bool
}
