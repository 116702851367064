import axios from "axios"
import { API_URL, AUTH_TOKEN, LOGIN } from "../../../setup/store"
import { history } from "../../../index"

// select
import { Real_estates } from "../../Real_estates/api/actions"
import { Brokers } from "../../Brokers/api/actions"
import { Status_Leads } from "../../Status_Leads/api/actions"

export const LEADS_FETCH = "LEADS_FETCH"
export const LEADS_FETCHING = "LEADS_FETCHING"

export const NEW_LEAD = "NEW_LEAD"
export const LEAD_FETCH = "LEAD_FETCH"
export const LEAD_CLEAR = "LEAD_CLEAR"

export const LEAD_SUBMITTING = "LEAD_SUBMITTING"
export const LEAD_SUBMIT = "LEAD_SUBMIT"
export const LEAD_SUBMIT_ERROR = "LEAD_SUBMIT_ERROR"

const URL_REDIRECT = "/admin/leads"
const API_TYPE = "leads"

export const Leads = () => {
  return async dispatch => {
    dispatch({
      type: LEADS_FETCHING
    })
    try {
      const res = await axios.get(`${API_URL}${API_TYPE}`, {
        headers: {
          Authorization: AUTH_TOKEN
        }
      })

      dispatch({
        type: LEADS_FETCH,
        data: res.data
      })
    } catch (error) {
      window.location.href = LOGIN
      console.error(error)
    }
  }
}

export const Lead = id => {
  return async dispatch => {
    // select
    dispatch(Real_estates())
    dispatch(Brokers())
    dispatch(Status_Leads())

    if (id) {
      try {
        const res = await axios.get(`${API_URL}${API_TYPE}/${id}`, {
          headers: {
            Authorization: AUTH_TOKEN
          }
        })
        dispatch({
          type: LEAD_FETCH,
          data: res.data
        })
      } catch (error) {
        window.location.href = LOGIN
        console.log(error.message)
      }
    } else {
      dispatch({
        type: NEW_LEAD
      })
    }
  }
}

export const LeadsCleanSubmit = () => {
  return async dispatch => {
    dispatch({
      type: LEAD_SUBMITTING
    })
  }
}

export const LeadsCleanForm = () => {
  return async dispatch => {
    dispatch({
      type: LEAD_CLEAR
    })
  }
}

export const LeadsDelete = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: LEAD_SUBMITTING
    })
    const ID = getState().Lead.data.id
    try {
      const res = await axios.delete(`${API_URL}${API_TYPE}/${ID}`, {
        headers: {
          Authorization: AUTH_TOKEN
        }
      })
      dispatch({
        type: LEAD_SUBMIT,
        data: res.data
      })
      history.push(URL_REDIRECT)
    } catch (error) {
      console.log(error.message)
    }
  }
}

export const LeadsSubmit = leads => {
  return async dispatch => {
    dispatch({
      type: LEAD_SUBMITTING
    })
    try {
      let res

      if (leads.id) {
        res = await axios.put(`${API_URL}${API_TYPE}/${leads.id}`, leads, {
          headers: {
            Authorization: AUTH_TOKEN
          }
        })
      } else {
        res = await axios.post(`${API_URL}${API_TYPE}/`, leads, {
          headers: {
            Authorization: AUTH_TOKEN
          }
        })
      }

      dispatch({
        type: LEAD_SUBMIT,
        data: res.data
      })
      history.push(URL_REDIRECT)
    } catch (error) {
      dispatch({
        type: LEAD_SUBMIT_ERROR,
        error: "Este nome já foi cadastrado, Por favor escolha um diferente..."
      })
    }
  }
}
