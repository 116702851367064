/* eslint-disable react/display-name */
import React, { useEffect } from "react"
import { NavLink } from "react-router-dom"
import MaterialTable from "material-table"
import { useSelector, useDispatch } from "react-redux"
import { API_BASE_URL } from "../../setup/store"

import { Img_real_estate, Img_real_estateCleanSubmit } from "./api/actions"
import { options, lang, imgTable } from "../../setup/materialTable"
import Card from "components/Card/Card"
import CardHeader from "components/Card/CardHeader"
import CardBody from "components/Card/CardBody"
import SnackbarContent from "components/Snackbar/SnackbarContent"
import GridItem from "components/Grid/GridItem"
import GridContainer from "components/Grid/GridContainer"
import Button from "components/CustomButtons/Button"

export default function Img_real_estateList() {
  const Img_real_estateSt = useSelector(state => state.Img_real_estate)
  const Real_estateSt = useSelector(state => state.Real_estate)
  const dispatch = useDispatch()
  const Img_real_estateReg = useSelector(state => state.Img_real_estateReg)

  useEffect(() => {
    dispatch(Img_real_estate(Real_estateSt.data.id))
    const timer = setTimeout(() => {
      dispatch(Img_real_estateCleanSubmit())
    }, 7000)
    return () => clearInterval(timer)
  }, [dispatch])

  const columns = [
    {
      field: "name_file",
      title: "Img.",
      filtering: false,
      export: false,
      render: rowData => (
        <React.Fragment>
          {rowData.name_file ? (
            <img
              style={imgTable}
              src={`${API_BASE_URL}uploads/img_real_estate/${rowData.name_file}`}
            />
          ) : (
            ""
          )}
        </React.Fragment>
      )
    },
    {
      field: "name",
      title: "Nome"
    },
    {
      field: "image_type.name",
      title: "Tipo"
    },
    {
      field: "id",
      title: "",
      filtering: false,
      export: false,
      render: rowData => (
        <Button
          component={NavLink}
          color="info"
          size="sm"
          style={{ float: "right" }}
          to={`/admin/img_real_estate-register/${Real_estateSt.data.id}/${rowData.id}`}
        >
          EDITAR
        </Button>
      )
    }
  ]

  return (
    <div>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary">
              <GridContainer>
                <GridItem xs={12} sm={12} md={9}>
                  <h3>Fotos</h3>
                </GridItem>
                <GridItem xs={12} sm={12} md={3} align="right">
                  <Button
                    component={NavLink}
                    color="info"
                    to={`/admin/img_real_estate-register/${Real_estateSt.data.id}`}
                  >
                    NOVO REGISTRO
                  </Button>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {Img_real_estateReg.data.res ? (
                  <GridItem xs={12} sm={12} md={12}>
                    <SnackbarContent
                      message={Img_real_estateReg.data.res}
                      color="success"
                    />
                  </GridItem>
                ) : (
                  ""
                )}

                <GridItem xs={12} sm={12} md={12}>
                  {Img_real_estateSt.data ? (
                    <MaterialTable
                      title=""
                      data={Img_real_estateSt.data}
                      columns={columns}
                      options={options}
                      localization={lang}
                    />
                  ) : (
                    ""
                  )}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  )
}
